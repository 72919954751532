import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import "./assets/css/bootstrap.min.css";
import "./assets/css/microsite.css";
import "./assets/css/slick-css.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import "./assets/fontawesome/css/all.css";
import store from "./store";
import Vuex from "vuex";
import Paginate from "vuejs-paginate-next";
import VueSweetalert2 from 'vue-sweetalert2';
import { setupInterceptors } from "./config/httpInterceptors";
import { BootstrapVue3 } from "bootstrap-vue-3";
import VueFbCustomerChat from './vue-fb-customer-chat'
import { createMetaManager } from 'vue-meta'
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { firebaseConfig} from "@/config/utils";
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: '/favicon.ico'
    };

    if (!("Notification" in window)) {
        console.log("This browser does not support system notifications");
    }
    else if (Notification.permission === "granted") {
        new Notification(notificationTitle, notificationOptions);
    }
});
const app = createApp(App)

const fbChatOptions = {
  page_id: 500734590359140, // change 'null' to your Facebook Page ID
  theme_color: '#2a97ef', // theme color in HEX
  locale: 'en_US', // default 'en_US'
};

app.use(VueFbCustomerChat, fbChatOptions);
app.use(VueSweetalert2);
app.use(router)
app.use(VueAxios,axios)
const axiosInstance = axios.create({
    withCredentials: true,
  })
app.config.globalProperties.$axios = { ...axiosInstance }
app.use(Vuex)
app.use(store)
app.use(BootstrapVue3)
app.use(Paginate)
app.use(VueSweetalert2)
app.use(createMetaManager())
window.Swal =  app.config.globalProperties.$swal;
app.use(setupInterceptors(store))
app.mount('#app')

 