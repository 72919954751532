import HomePage from "@/views/HomePage.vue";
import {createWebHistory, createRouter} from "vue-router";
import CategoriesPage from "../views/categories/CategoriesPage.vue"
import SubCategoriesPage from "../views/categories/subcategories/SubCategoriesPage.vue"
import ChaptersPage from "../views/categories/subcategories/chapters/ChaptersPage.vue"
import PagesPage from "../views/categories/subcategories/chapters/pages/PagesPage.vue"
import ExamPage from "../views/exam/ExamPage.vue"
import ResultPage from "../views/results/ResultPage.vue"
import LoginPage from "../views/LoginPage.vue"
// import AllCourseTypes from "../views/coursetypes/AllCourseTypes.vue"
import CourseExamList from "../views/courseexamlist/CourseExamList.vue"
import PackageDetailsPage from "../views/package/PackageDetailsPage.vue"
import PackagePage from "../views/package/PackagePage.vue"
import RankListPage from "../views/ranklist/RankListPage.vue"
import PurchaseHistoryPage from "../views/purchasehistory/PurchaseHistoryPage.vue"
import OopsPage from "../views/OopsPage.vue"
// import SearchPage from "../views/search/SearchPage.vue"
import ProfilePage from "../views/profile/ProfilePage.vue"
import FaqPage from "../views/faq/FaqPage.vue"
import store from '@/store/index.js';
import {StaticTags, URLs} from "@/static";
import QuestionBank from "@/components/QuestionBank/QuestionBank.vue";
import QuestionBankDetails from "@/components/QuestionBank/QuestionBankDetails.vue";
import CourseCategoriesPage from "@/components/CourseCategoriesPage.vue";
import AboutUs from "@/components/AboutUs.vue";
import LiveExam from "@/components/LiveExam.vue";
import LectureNotes from "@/components/LectureNotes/LectureNotes.vue";
import LectureNoteDetails from "@/components/LectureNotes/LectureNoteDetails.vue";
import SearchResultPage from "@/views/search/SearchResultPage.vue";
import WelcomePage from "@/views/WelcomePage.vue";
import BlogsPage from "@/components/WelcomePages/Blogs/BlogsPage.vue";
import BlogDetails from "@/components/WelcomePages/Blogs/BlogDetails.vue";
import CourseDetailsPage from "@/components/WelcomePages/CourseDetailsPage.vue";
import CourseListPage from "@/components/WelcomePages/CourseList.vue";


const routes = [
    {
        path: URLs.ROUTE_HOME_URL,
        name: URLs.ROUTE_HOME_NAME,
        component: HomePage,
        props: true,
    },
    {
        path: URLs.ROUTE_WELCOME_URL,
        name: URLs.ROUTE_WELCOME_NAME,
        component: WelcomePage,
        props: true,
    },

    {
        path: URLs.ROUTE_LOGIN_URL,
        name: URLs.ROUTE_LOGIN_NAME,
        component: LoginPage,
    },

    // {
    //   path: StaticTags.ROUTE_SIGNUP_URL,
    //   name: StaticTags.ROUTE_SIGNUP_NAME,
    //   component: SignUpPage,
    // },

    {
        path: URLs.ROUTE_PACKAGE_DETAILS_URL,
        name: URLs.ROUTE_PACKAGE_DETAILS_NAME,
        component: PackageDetailsPage,
        props: true,
    },

    {
        path: URLs.ROUTE_PACKAGES_URL,
        name: URLs.ROUTE_PACKAGES_NAME,
        component: PackagePage,
        props: true,
    },

    {
        path: URLs.ROUTE_QUESTION_BANK_URL,
        name: URLs.ROUTE_QUESTION_BANK_NAME,
        component: QuestionBank,
        props: true,
    },

    {
        path: URLs.ROUTE_LECTURE_NOTES_URL,
        name: URLs.ROUTE_LECTURE_NOTES_NAME,
        component: LectureNotes,
        props: true,
    },

    {
        path: URLs.ROUTE_LECTURE_NOTE_URL,
        name: URLs.ROUTE_LECTURE_NOTE_NAME,
        component: LectureNoteDetails,
        props: true,
    },

    {
        path: URLs.ROUTE_QUESTION_BANK_DETAILS_URL,
        name: URLs.ROUTE_QUESTION_BANK_DETAILS_NAME,
        component: QuestionBankDetails,
        props: true,
    },

    {
        path: URLs.ROUTE_CATEGORIES_URL,
        name: URLs.ROUTE_CATEGORIES_NAME,
        component: CategoriesPage,
        props: true
    },

    {
        path: URLs.ROUTE_SUB_CATEGORIES_URL,
        name: URLs.ROUTE_SUB_CATEGORIES_NAME,
        component: SubCategoriesPage,
        props: true
    },

    {
        path: URLs.ROUTE_CHAPTER_PAGE_URL,
        name: URLs.ROUTE_CHAPTER_PAGE_NAME,
        component: ChaptersPage,
        props: true
    },

    {
        path: URLs.ROUTE_PAGE_URL,
        name: URLs.ROUTE_PAGE_NAME,
        component: PagesPage,
        props: true
    },


    // route for exercise category flow
    {
        path: URLs.ROUTE_EXERCISE_CATEGORIES_URL,
        name: URLs.ROUTE_EXERCISE_CATEGORIES_NAME,
        component: CategoriesPage,
        props: true
    },

    {
        path: URLs.ROUTE_EXERCISE_SUB_CATEGORIES_URL,
        name: URLs.ROUTE_EXERCISE_SUB_CATEGORIES_NAME,
        component: SubCategoriesPage,
        props: true
    },

    {
        path: URLs.ROUTE_EXERCISE_CHAPTER_PAGE_URL,
        name: URLs.ROUTE_EXERCISE_CHAPTER_PAGE_NAME,
        component: ChaptersPage,
        props: true
    },

    {
        path: URLs.ROUTE_EXERCISE_PAGE_URL,
        name: URLs.ROUTE_EXERCISE_PAGE_NAME,
        component: PagesPage,
        props: true
    },


    {
        path: URLs.ROUTE_EXAM_URL,
        name: URLs.ROUTE_EXAM_NAME,
        component: ExamPage,
        props: true
    },


    {
        path: URLs.ROUTE_LIVE_EXAM_URL,
        name: URLs.ROUTE_LIVE_EXAM_NAME,
        component: LiveExam,
        props: true
    },

    {
        path: URLs.ROUTE_RESULT_URL,
        name: URLs.ROUTE_RESULT_NAME,
        component: ResultPage,
        props: true
    },

    {
        path: URLs.ROUTE_EXAM_RANK_LIST_URL,
        name: URLs.ROUTE_EXAM_RANK_LIST_NAME,
        component: RankListPage,
        props: true
    },

    {
        path: URLs.ROUTE_RESULT_QUESTION_URL,
        name: URLs.ROUTE_RESULT_QUESTION_NAME,
        component: ExamPage,
    },
    {
        path: URLs.ROUTE_EXAM_APP_LINK_URL,
        name: URLs.ROUTE_EXAM_APP_LINK_NAME,
        component: HomePage,
        beforeEnter() {
            window.location.href = "https://play.google.com/store/apps/details?id=com.bcsprostuti.tanim.bcsprostuti";
        }
    },

    {
        path: URLs.ROUTE_FAVOURITE_QUESTION_URL,
        name: URLs.ROUTE_FAVOURITE_QUESTION_NAME,
        component: ExamPage,
    },

    {
        path: URLs.ROUTE_COURSE_TYPES_ALL_URL,
        name: URLs.ROUTE_COURSE_TYPES_ALL_NAME,
        component: CourseCategoriesPage,
        props: true
    },

    {
        path: URLs.ROUTE_COURSE_EXAM_LIST_URL,
        name: URLs.ROUTE_COURSE_EXAM_LIST_NAME,
        component: CourseExamList,
        props: true
    },

    {
        path: URLs.ROUTE_ABOUT_US_URL,
        name: URLs.ROUTE_ABOUT_US_NAME,
        component: AboutUs,
        props: true
    },

    // {
    //     path: URLs.ROUTE_LIVE_COURSE_TYPES_ALL_URL,
    //     name: URLs.ROUTE_LIVE_COURSE_TYPES_ALL_NAME,
    //     component: AllCourseTypes,
    //     props: true
    // },

    {
        path: URLs.ROUTE_LIVE_COURSE_EXAM_LIST_URL,
        name: URLs.ROUTE_LIVE_COURSE_EXAM_LIST_NAME,
        component: CourseExamList,

    },

    {
        path: URLs.ROUTE_PURCHASE_HISTORY_URL,
        name: URLs.ROUTE_PURCHASE_HISTORY_NAME,
        component: PurchaseHistoryPage,
        props: true
    },

    {
        path: URLs.ROUTE_PROFILE_PAGE_URL,
        name: URLs.ROUTE_PROFILE_PAGE_NAME,
        component: ProfilePage,
        props: true
    },

    {
        path: URLs.ROUTE_SEARCH_PAGE_URL,
        name: URLs.ROUTE_SEARCH_PAGE_NAME,
        component: SearchResultPage,
        props: true
    },

    {
        path: URLs.ROUTE_BLOGS_URL,
        name: URLs.ROUTE_BLOGS_NAME,
        component: BlogsPage,
        props: true
    },

    {
        path: URLs.ROUTE_COURSE_DETAILS_URL,
        name: URLs.ROUTE_COURSE_DETAILS_NAME,
        component: CourseDetailsPage,
        props: true
    },

    {
        path: URLs.ROUTE_COURSE_LIST_URL,
        name: URLs.ROUTE_COURSE_LIST_NAME,
        component: CourseListPage,
        props: true
    },

    {
        path: URLs.ROUTE_BLOG_DETAILS_URL,
        name: URLs.ROUTE_BLOG_DETAILS_NAME,
        component: BlogDetails,
        props: true
    },

    {
        path: URLs.ROUTE_FAQ_PAGE_URL,
        name: URLs.ROUTE_FAQ_PAGE_NAME,
        component: FaqPage,
        props: true
    },

    {
        path: URLs.ROUTE_OOPS_PAGE_URL,
        name: URLs.ROUTE_OOPS_PAGE_NAME,
        component: OopsPage,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {

    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    // set flow based on url
    if (to.path.includes('exercise')) {
        store.dispatch("setFlowType", StaticTags.TYPE_EXERCISE)
    } else if (to.path.includes('exam')) {
        store.dispatch("setFlowType", StaticTags.TYPE_EXAM)
    } else if (to.path.includes('favourite')) {
        store.dispatch("setFlowType", StaticTags.TYPE_FAVOURITE)
    } else if (to.path.includes('quiz')) {
        store.dispatch("setFlowType", StaticTags.TYPE_QUIZ)
    }

    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = [ URLs.ROUTE_COURSE_DETAILS_URL, URLs.ROUTE_LOGIN_URL, URLs.ROUTE_EXAM_APP_LINK_URL, URLs.ROUTE_WELCOME_URL, URLs.ROUTE_ABOUT_US_URL, URLs.ROUTE_BLOG_DETAILS_URL, URLs.ROUTE_BLOGS_URL];
    // var authRequired = !publicPages.includes(to.path);
    const publicPageNames = [URLs.ROUTE_COURSE_LIST_NAME, URLs.ROUTE_ABOUT_US_NAME, URLs.ROUTE_FAQ_PAGE_NAME, URLs.ROUTE_COURSE_DETAILS_NAME, URLs.ROUTE_LOGIN_NAME, URLs.ROUTE_EXAM_APP_LINK_NAME, URLs.ROUTE_WELCOME_NAME, URLs.ROUTE_ABOUT_US_NAME, URLs.ROUTE_BLOG_DETAILS_NAME, URLs.ROUTE_BLOGS_NAME];
    var authRequired = !publicPageNames.includes(to.name);
    if (to.path.includes('login')) {
        authRequired = false
    }
    if (authRequired & ((!access_token) || access_token == "null")) {
        store.commit("setBeforeLoginPath", to.path);
        next({name: "Login"});
    } else {
        store.commit('clearBeforeLoginPath');
        return next();
    }


});


export default router;