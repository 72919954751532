<template>
<!--  <section class="faq-section section">-->
    <div class="mt-3 faq-section-container">
      <div class="container-fluid">

        <div class="faq-welcome-content">
          <div class="faq">
            <div class="section-title">
              <h2 class="wow fadeInUp" data-wow-delay=".4s">সচরাচর জিজ্ঞাসাসমূহ
              </h2>
            </div>
          </div>
          <div class='d-display'>
            <div class='d-details'>
              <details class='tab-control'>
                <summary>বিসিএস প্রস্তুতি অ্যাপটি কি? কাদের জন্য এই অ্যাপ? </summary>
                <p>বিসিএস সহ যে কোন ধরণের সরকারী চাকুরীর প্রস্তুতির জন্যই এই অ্যাপ 💯। আপনার মোবাইলে যে কোন জায়গা থেকে
                  লক্ষাধিক পরীক্ষার্থীদের সাথে বিভিন্ন রকম লাইভ পরীক্ষা, টপিক কিংবা বিষয়ভিত্তিক পরীক্ষা দিয়ে নিজেকে
                  প্রতিনিয়ত যাচাই করার জন্যই একটি এন্ড্রয়েড অ্যাপ বেইজড অনলাইন প্লাটফর্ম:<b> বিসিএস প্রস্তুতি।</b> </p>
              </details>
              <details class='tab-control'>
                <summary>আমি এখনও ছাত্র, আমি কিভাবে বিসিএস প্রস্তুতি শুরু করব?</summary>
                <p>বিসিএস এখন অনেক অনেক বেশী প্রতিযোগীতাপূর্ণ একটা পরীক্ষা। তাই আগে থেকেই বিসিএস এর জন্য নিজেকে প্রস্তুত করা
                  খুবই বিচক্ষণ একটি সিদ্ধান্ত। ✅ যদি আপনি এখনো একজন ছাত্র হন, তাহলে এখন থেকে ম্যাথ আর ইংলিশ এর ওপর জোর দিন।
                  রেগুলার বাংলা ও ইংরেজি পত্রিকা পড়ুন। বেশী বেশী ইংরেজি ও বাংলা বই পড়ুন। মুক্তিযুদ্ধ নিয়ে যত উপন্যাস আছে
                  সেগুলা পড়ুন। রবীন্দ্রনাথ, সমরেশ মজুমদার, শীর্ষেন্দু, শরৎচন্দ্র এদের বই পড়ুন। ইংলিশ বই হ্যারি পটার দিয়ে
                  শুরু করুন। বেশী বেশী করে ইংলিশ মুভি আর ইউটিউবে ইংলিশ টেড টক শুনুন। এতে ইংলিশ বলা এবং লিখা দুটোতেই কাজে
                  দেবে। আর নিয়মিত গণিত চর্চা টা চালু রাখুন। আপাতত অনার্স শেষ হবার আগ পর্যন্ত এগুলোই করুন। পরে আপনার দুর্বলতা
                  কই, সেটা ঠিক করতে কি করতে হবে নিজেই বুঝে যাবেন। ✊</p>
              </details>
              <details class='tab-control'>
                <summary>বিসিএস প্রস্তুতি অ্যাপ কিভাবে ইউজ করলে আমি সবচেয়ে ভাল করতে পারব?</summary>
                <p>
                  আপনি যেই টপিক কিংবা চ্যাপ্টার পড়ছেন, সেটা পড়ার পর অ্যাপে ঢুকে টপিক-ভিত্তিক পরীক্ষা যত বেশি দিবেন তত বেশি
                  উপকার পাবেন। পরীক্ষাতে যা যা ভুল করছেন, সেগুলো অ্যাপেই মার্ক করে রেখে দিন। এরপর রিভিশন দিন। এছাড়াও আপনি
                  যেই টপিক পড়া আছে সেগুলো অ্যাপ এর আর্কাইভ থেকে পরীক্ষা দিয়ে প্রতিনিয়ত নিজেকে যাচাই করবেন। সাথে আপনার জন্য
                  উপযোগী একটি কোর্স খুব ভাল করে ফলো করুন 👍।
                </p>
              </details>

              <details class='tab-control'>
                <summary>আমি চাকরি করি, চাকুরীর পাশাপাশি কিভাবে পড়াশোনা করব বুঝতে পারছি না?</summary>
                <p>
                  আপনি চাকুরী করেন তার মানে আপনি চাকুরীর পাশাপাশি পড়াশোনা করবেন। সেক্ষেত্রে আপনার দিনে পড়ার জন্য সময় পাবেন
                  অন্যদের তুলনায় কিছুটা কম। তাই ঠিক আপনাদের কথা চিন্তা করেই আমরা নিয়ে এসেছি <b>‘বিসিএস প্রস্তুতি (৬ মাস)’</b>
                  কোর্সটি 📌। খুব অল্প সিলেবাসে দৈনিক ২-৩ ঘণ্টাতেই শেষ করা যায়: এমনভাবেই তৈরি করা হয়েছে সম্পূর্ণ সিলেবাস এবং
                  রুটিন। এই কোর্সটি ফলো করলেই আপনি চাকুরীর পাশাপাশি একটা ভালো প্রস্তুতি নিয়ে নিতে পারবেন। তাছাড়া, আপনি
                  আমাদের অ্যাপস এর লেকচার অ্যান্ড নোটস অপশনে গিয়ে গাইডলাইনগুলো 📚 ফলো করতে পারেন।
                </p>
              </details>

              <details class='tab-control'>
                <summary>আমি অনেকদিন ধরেই চেষ্টা করছি, কোন ভাবেই ভাল ফলাফল করতে পারছি না। কি করব?</summary>
                <p>
                  প্রচুর পরিমাণে পড়াশোনা করার কোন বিকল্প নেই। সাথে বিকল্প নেই পরীক্ষা দেয়ার। আপনি যত বেশী পড়বেন তত বেশী ভালো
                  করবেন ✌️। আর যত পরীক্ষা দেবেন, তত আপনার উত্তীর্ণ হবার সম্ভাবনা বাড়বে। কোথায় কোথায় বেশী ভুল করেন সেগুলোর
                  দিকে বেশী করে নজর দিতে হবে। ওগুলো নিয়ে কাজ করতে হবে। ✊

                </p>
              </details>
            </div>
            <div class="d-image">
              <p><a href="/faq">More FAQ</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--  </section>-->
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "WelcomePageFaq",
  setup() {
    return {};
  },
  metaInfo: {
    title: "সচরাচর জিজ্ঞাসাসমূহ",
    meta: [
      {
        name: "description",
        content: "বিসিএস প্রস্তুতি অ্যাপ সম্পর্কে সচরাচর জিজ্ঞাসাসমূহ"
      },
      {
        property: "og:title",
        content: "বিসিএস প্রস্তুতি অ্যাপ সম্পর্কে সচরাচর জিজ্ঞাসাসমূহ"
      },
      {
        property: "og:description",
        content: "বিসিএস প্রস্তুতি অ্যাপ সম্পর্কে সচরাচর জিজ্ঞাসাসমূহ"
      },
      {
        property: "og:url",
        content: "www.bcsprostuti.com/faq"
      }
    ]
  }
});
</script>
<style scoped>
.faq-section-container {
  min-height: 50vh;
  margin-top: 40px;
}

.faq-welcome-content {
  opacity: 0.9599999785423279;
  position: relative;
  padding: 25px 50px 25px 50px;
  margin: 20px;
}

.faq {
  color: #000000;
  text-align: center;
  font: 700 32px/32px "Noto Sans Bengali", sans-serif;
  position: relative;
  top: 25px;
}

.d-display {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  padding: 2rem;
}

details summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  cursor: pointer;
  color: #000000;
  text-align: left;
  font: 600 20px/20px "Noto Sans Bengali", sans-serif;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

details summary:after {
  content: "›";
  font-size: 2rem;
  transition: 0.3s;
  transform: rotate(90deg);
}

/* transitions */
details {
  margin-bottom: 1rem;
  height: 4rem;
  transition: height 0.3s ease;
}

details[open] {
  height: 6rem;
}

details p {
  height: fit-content;
  padding: 1rem;
}

details[open] summary:after {
  transform: rotate(-90deg);
}

/* responsive */
@media (max-width: 1190px) {
  body,
  .d-display {
    height: fit-content;
    width: 100%;
  }

  .d-display {
    justify-content: center;
    padding: 2rem 0;
  }
}

@media (max-width: 768px) {
  details summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    width: 100%;
    cursor: pointer;
    color: #000000;
    text-align: left;
    font: 600 14px/20px "Noto Sans Bengali", sans-serif;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
  }

  .faq-welcome-content {
    padding: 0px;
    margin: 0px;
  }
}

</style>