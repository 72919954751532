<template>
  <div class="col-lg-4 col-md-4 col-sm-4 pl-2 mb-3 test-grid">
    <div v-if="item!=undefined">
      <div class="other-result-box">
        <div  class="other-result-body">
          <p class="body-title justify-text" :title="item.title">
            {{ item.topic }}</p>
          <div class="other-result-body-content">
            <div class="other-result-content">
              <table class="table table-sm myTable">
                <tbody>
                <tr>
                  <td>মার্কসঃ {{item.mark ? item.mark : 0.0 }}</td>
                  <td> সর্বমোটঃ {{ item.total }}</td>
                  <td rowspan="2"><button class="btn question-show-button" @click="onAnswerListClicked">উত্তর পত্র</button></td>
                </tr>
                <tr>
                  <td> সঠিকঃ {{ item.correct ? item.correct : 0 }}</td>
                  <td> ভুলঃ {{ item.wrong }}</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <ResultSectionView v-if="isResultShowable && status != 'Running'" :exam="item">

      </ResultSectionView>


    </div>
  </div>
</template>

<script>
// import Swal from 'sweetalert2/dist/sweetalert2';
import {StaticTags, URLs} from "@/static";
import {useRouter} from "vue-router";
//import store from '@/store/index.js';
import {setExamInfo, setExamParams} from "@/config/utils";
import {onMounted, ref} from "vue";
import store from "@/store";
import {englishToBengaliNumber} from "@/config/utils";
import ResultSectionView from "@/components/ExamDetailsView/ResultSectionView.vue";

export default {
  components: {
    ResultSectionView,
  },
  props: [
    'item', 'status', 'isResult', 'isLive', 'hideBtn', 'isMenuDisabled', 'isSingleCourse',
    'showMenuModal', 'showSyllabusModal', 'showStartExamDialog', 'syllabusModal', 'startExamDialog', 'isHome'
  ],
  setup(props) {
    const isRunning = ref(false)
    const router = useRouter();
    const isResultShowable = ref(false)
    onMounted(() => {
      if (props.item.result != undefined || props.item.result != null) {
        isResultShowable.value = false
      }
      console.log("Items::::",props.item)

    })
    const getStatus = (exam) => {
      let isEnded = exam.ended
      let isParticipated = (exam.result != null || exam.result != undefined)
      let isPass = false
      if (isParticipated) {
        isPass = exam.result.mark >= exam.cut_mark
      }
      if (isEnded == StaticTags.ID_EXAM_RUNNING) {
        isRunning.value = true
        if (isParticipated) {
          return "Participated";
        } else {
          return "Running";
        }
      } else if (isEnded == StaticTags.ID_EXAM_FINISHED) {
        isRunning.value = false
        return "Finished";
      } else if (isEnded == StaticTags.ID_EXAM_UPCOMING) {
        return "Upcoming";
      } else {
        if (isParticipated) {
          isRunning.value = false
          if (isPass) {
            return "Passed";
          } else {
            return "Failed";
          }
        } else {
          return "Archived";
        }
      }
    }

    const onAnswerListClicked = () => {
      //store.dispatch("setExamFlowType", StaticTags.TYPE_COURSE_EXAM_RESULT)
      setExamParams({
        examId: props.item.id
      })
      setExamInfo({
        id: props.item.id,
        title: props.item.title,
        examFlowType: StaticTags.TYPE_RESULT
      })
      router.push({name: URLs.ROUTE_EXAM_NAME})
    }

    const onCourseClicked = (item) => {
      store.dispatch("setTitleBar", item.exam_type.title)
      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_RESULT)
      router.push({
        name: URLs.ROUTE_EXAM_NAME,
        params: {
          exam_type: item.exam_type.id,
        },
      })
    }

    return {
      onAnswerListClicked,
      isResultShowable,
      onCourseClicked,
      getStatus,
      englishToBengaliNumber,
      isRunning,
    }
  }
}
</script>

<style scoped>

.myTable {
  border: none !important;
}
.myTable th, .myTable td {
  border: none !important;
}

td[rowspan] {
  text-align: end;
  vertical-align: middle;
}
.other-result-content {
  padding: 12px;
}

.other-result-box {
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  background: #FFFFFF;
  text-align: start;
  padding-bottom: 5px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.other-result-body {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.body-title {
  position: relative;
  font-family: 'Noto Sans Bengali';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #071833;
}


.other-result-body-content {
  position: relative;
  font-family: 'Noto Sans';
  font-style: normal;
  font-size: 15px;
  line-height: 30px;
  color: #999999;
  border-radius: 12px;
  background: #FFFBFA !important;
}


.question-show-button {
  width: 110px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px !important;
  background: #F0F5F9;
  color: black;

}


</style>
