<template>
  <WelcomeHeader />
  <div class="blog-hero-area">

  </div>
  <div class="blog-area ">
    <div class="container-fluid">
      <div class="blog-lists">
        <div class="row">
          <div class="col-12">
            <div class="section-title">
              <h2 class="wow fadeInUp pt-5" data-wow-delay=".4s">আমাদের ব্লগ সমূহ
              </h2>
              <p class="wow fadeInUp" data-wow-delay=".6s">প্রস্তুতিকে সহজ সুন্দর করতে গুরুত্বপূর্ণ সব ইনফরমেশনস পাবেন এখানেই</p>
            </div>
          </div>
        </div>
        <div class="blog-list">
          <div v-for="(blog, index) in blogList" :key="index" class="our-course-box wow fadeInUp" data-wow-delay=".2s">
            <div class="top-section">
              <div class="top-section-content">
                <img :src="blog.image" height="190"  style="border-radius: 16px; width: 100%" />
              </div>
            </div>
            <div class="bottom-section">
              <h3>{{blog.title}}</h3>
              <p>{{blog.short_description}}</p>
              <a @click="goToBlogDetails(blog)" style="cursor: pointer; font-weight: 600; color: rgba(0, 149, 255, 1);
">বিস্তারিত পড়ুন
                <svg
                    class="archived-button-arrow"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="top: 4px; cursor: pointer"
                >
                  <path
                      d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"
                      fill="#007bff"
                  />
                </svg></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <WelcomeFooter />
</template>
<script>
import WelcomeHeader from "@/components/WelcomePages/WelcomeHeader.vue";
import WelcomeFooter from "@/components/WelcomePages/WelcomeFooter.vue";
import router from "@/router";
import {URLs} from "@/static";
import {blogList} from "@/config/utils";
export default {
  components: {
    WelcomeFooter,
    WelcomeHeader
  },
  setup() {
    const goToBlogDetails = (blog) => {
      router.push({
        name: URLs.ROUTE_BLOG_DETAILS_NAME,
        params: {
          title: blog.url_text
        }
      });
    };
    return {
      blogList,
      goToBlogDetails,
    };
  }
};
</script>

<style scoped>

.blog-list {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .blog-list {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .blog-area {
    position: relative;
    min-height: calc(100vh - 200px);
    padding: 40px;
  }
}

.underline {
  padding-bottom: 10px;
  box-shadow: 0 4px 0 #007bff;
}

.blog-hero-area {
  position: relative;
  background: #191B47;
  height: 100px;
  display: flex;
}


.blog-area {
  background-color: #F7F6F3;
  position: relative;
  padding-bottom: 30px;
}

.blog-area .section-title {
  margin-bottom: 30px;

}

.blog-area .our-course-box {
  text-align: left;
  width: 100%;
  height: max-content;
  background-color: #fff;
  border-radius: 16px;
  position: relative;
  margin-top: 30px;
  border: 1px solid #eee;
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.blog-area .our-course-box:hover {
  -webkit-box-shadow: 0px 3px 5px #00000017;
  box-shadow: 0px 3px 5px #00000017;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.blog-area .our-course-box .top-section {
  height: 230px;
  padding: 20px;
}

.blog-area .our-course-box .bottom-section {
  height: max-content;
  padding: 0 20px 20px 20px;
}


.blog-area .our-course-box .bottom-section a {

}


.blog-area .our-course-box .top-section .top-section-content {
  height: 100%;
  width: 100%;
  background-color: #FDF7E9;
  border-radius: 16px;
}

.blog-area .our-course-box h3 {
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}

.blog-area .our-course-box p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.5px;
  text-align: left;
}
</style>