<template>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" @click="changePage(-1)">Previous</a>
      </li>
      <li class="page-item" v-for="page in pages" :key="page" :class="{ active: page === currentPage }">
        <a class="page-link" v-if="page !== '...'" @click="changePage(0, page)">{{ page }}</a>
        <span v-else>...</span>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" @click="changePage(1)">Next</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['totalItems', 'itemsPerPage', 'currentPage'],
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    pages() {
      const totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      if (totalPages <= 10) {
        return Array.from({length: totalPages}, (_, i) => i + 1);
      } else {
        if (this.currentPage <= 4) {
          return [...Array.from({length: 4}, (_, i) => i + 1), '...', ...Array.from({length: 3}, (_, i) => totalPages - 2 + i)];
        } else if (this.currentPage > 4 && this.currentPage <= totalPages - 3) {
          return [ this.currentPage - 2, this.currentPage - 1, this.currentPage, this.currentPage + 1, '...', ...Array.from({length: 3}, (_, i) => totalPages - 2 + i)];
        } else {
          return [ ...Array.from({length: 7}, (_, i) => totalPages - 6 + i)];
        }
      }
    }
  },
  methods: {
    changePage(direction, page) {
      const newPage = direction ? this.currentPage + direction : page;
      this.$emit('page-changed', newPage);
    }
  }
};
</script>