<template>
  <section id="pricing" class="pricing-table section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h2 class="wow fadeInUp" data-wow-delay=".4s">সুলভ মূল্য, সব সময়</h2>
            <p class="wow fadeInUp" data-wow-delay=".6s">সুলভ মুল্যে সেরা প্রস্তুতি নিশ্চিতে সাজানো আমাদের সব প্রিমিয়াম প্যাকেজ</p>
          </div>
        </div>
      </div>
      <div class="">

        <carousel
            v-bind="settings" :breakpoints="breakpoints"
        >
          <slide
              v-for="(pack, index) in packages"
              :key="index"
          >
            <div class="single-table wow fadeInUp" data-wow-delay=".2s">
              <div class="table-head">
                <h4 class="title">{{ pack.name }}</h4>
                <p> {{ pack.short_description }} </p>
                <div class="price">
                  <span class="dam" v-if="pack.dam != pack.actual_dam">৳{{ englishToBengaliNumber(pack.dam) }}</span>
                  <span class="pt-2 price-text actual_dam">{{ "\xa0" }}{{ "\xa0" }}৳{{
                      englishToBengaliNumber(pack.actual_dam)
                    }}</span>
                </div>
              </div>
              <div class="table-content">
                <ul class="table-list">
                  <li v-for="(item, index) in getPackList(pack).slice(0,7)" :key="index">
                    {{ item.replace('✓', '').trim() }}
                  </li>
                </ul>
              </div>
              <div class="table-footer">
                <div class="table-footer-button">
                  <a @click="goToPackage(pack)" class="btn">বেছে নিন</a>
                </div>
              </div>
            </div>
          </slide>
          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>
    </div>
  </section>
</template>
<script>
import {defineComponent, onMounted, ref} from "vue";
import httpClient from "@/config/httpClient";
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import {englishToBengaliNumber} from "@/config/utils";


export default defineComponent({
  name: "CoursePrice",
  components: {
    Carousel,
    Pagination,
    Slide
  },
  setup() {
    const packages = ref([])
    onMounted(() => {
      getPackages()
    });

    const getPackages = async () => {
      await httpClient
          .get(process.env.VUE_APP_API_BASE_URL + "/packages", {
            params: {
              website: true,
            },
          })
          .then((response) => {
            packages.value = response.package;
            console.log(packages.value);
          });
    }

    const getPackList = (pack) => {
      return pack.long_description.split("\n").filter((item) => item.includes('✓'));
    }

    const goToPackage = (pack) => {
      console.log('package clicked', pack);
      window.location.href = '/packages/' + pack.id;
    }


    return {
      packages,
      englishToBengaliNumber,
      getPackList,
      goToPackage
    };
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      autoplay: 4000,
      pauseAutoplayOnHover: true,
      loop: true,
      snapAlign: 'start',
      wrapAround: true,
    },
    breakpoints: {
      768: {
        itemsToShow: 1,
      },
      769: {
        itemsToShow: 3,
      },
      1024: {
        itemsToShow: 3,
      },
    },
  }),
});
</script>
<style scoped>

.dam {
  color: #807e7d;
  text-decoration: line-through;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.actual_dam {
  color: #066cbd;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.pricing-table {
  background-color: #f9f9f9;
  position: relative;
}

.price-text {
  font-family: Noto Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
}

.pricing-table .section-title {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .pricing-table .section-title {
    margin-bottom: 30px;
  }
}

.pricing-table .single-table {
  border: 1px solid #eee;
  border-radius: 24px;
  height: 560px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
  background-color: #fff;
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.pricing-table .single-table:hover:hover {
  -webkit-box-shadow: 0px 3px 5px #00000017;
  box-shadow: 0px 3px 5px #00000017;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.pricing-table .single-table .table-head {
}

.pricing-table .single-table .table-head .title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.pricing-table .single-table .table-head p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.5px;
}

.pricing-table .single-table .table-head .price {
  padding: 10px 0;
}

.pricing-table .single-table .table-head .price .amount {
  font-family: Noto Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  display: inline-block;
}

.pricing-table .single-table .table-head .price .amount .duration {
  display: inline-block;
  color: #888;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  margin-left: 3px;
}

.pricing-table .single-table .table-footer .table-footer-button .btn {
  padding: 12px 30px;
  width: 183px;
  height: 54px;
  background-color: #0095FF;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;

}

.pricing-table .single-table .table-footer .table-footer-button .btn:hover {
  background-color: #fff;
  color: #0095FF;
  border: 1px solid #0095FF;
}

.pricing-table .single-table .table-footer {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.pricing-table .single-table .table-content {
}

.pricing-table .single-table .table-content .middle-title {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.pricing-table .single-table .table-content .table-list li {
  position: relative;
  padding-left: 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
}
ul {
  list-style: none;
}

ul li:before {
  content: '✓';
  color: #0095FF;
  padding-right: 10px;
}

.pricing-table .single-table .table-content .table-list li:last-child {
  margin: 0;
}
</style>