
<template>
  <WelcomeHeader />
  <div class="course-details-page-hero">
    <img :src="courseDetails.image" >
  </div>
  <div class="course-details-page section">
    <div class="container-fluid">
      <div class="container-fluid">
        <div class="row g-0">

          <div class=" col-md-2 col-sm-2"></div>
          <div class=" col-md-8 col-sm-8">
            <h1 class="underline" style="text-align: left">{{ courseDetails ? courseDetails.title : '' }}</h1>
            <div class="d-block text-left pt-5">
<!--              <p style="white-space: pre-line">{{ myCourse ? myCourse.long_description : '' }}</p>-->
              <p style="white-space: pre-line" v-html="courseDetails ? courseDetails.long_description : ''"></p>
            </div>
          </div>
          <div class=" col-md-2 col-sm-2"></div>
        </div>

      </div>
      <div style="padding-top: 40px"></div>

    </div>
  </div>

  <WelcomeFooter />

</template>
<script>
import WelcomeHeader from "@/components/WelcomePages/WelcomeHeader.vue";
import WelcomeFooter from "@/components/WelcomePages/WelcomeFooter.vue";
import {onMounted, ref} from 'vue';
import {getCourseDetails} from "@/config/utils";
import {useRoute} from "vue-router";

export default {
  components: {
    WelcomeFooter,
    WelcomeHeader
  },
  setup() {
    const courseDetails = ref({})
    const route = useRoute();
    onMounted(() => {
      courseDetails.value = getCourseDetails(route.params.title);
      window.scrollTo(0, 0);
    });

    return {
      courseDetails
    };
  },
  metaInfo() {
    console.log("meta:::",this.courseDetails);
    return {
      title: this.courseDetails.title,
      meta: [
        { name: 'description', content: this.courseDetails.long_description },
        { property: 'og:title', content: this.courseDetails.title },
        { property: 'og:description', content: this.courseDetails.long_description },
        { property: 'og:image', content: this.courseDetails.image },
        { property: 'og:url', content: `www.bcsprostuti.com/course-details/${this.courseDetails.url_text}` }
      ]
    }
  }
};
</script>

<style scoped>
.course-details-page-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-details-page {
  background-color: #F7F6F3;
  position: relative;
  min-height: 50vh;
}

.underline {
  padding-bottom: 10px;
  box-shadow: 0 2px 0 #007bff;
}
@media (max-width: 768px){
  .course-details-page-hero img {
    width: 100%;
  }
}

</style>