<template>

<!--  <TopHeader />-->

  <WelcomeHeader />

  <HeroPage />

  <AppOverView />


  <OneAppSolution />

  <OurCourses />

  <OurFeatures />

  <LearnFree />

<!--  <UserExperience />-->

  <CoursePrice />

  <WelcomePageFaq />

  <WelcomeFooter />

</template>

<script >
// import TopHeader from "@/components/WelcomePages/TopHeader.vue";
import WelcomeHeader from "@/components/WelcomePages/WelcomeHeader.vue";
import HeroPage from "@/components/WelcomePages/HeroPage.vue";
import AppOverView from "@/components/WelcomePages/AppOverView.vue";
import OneAppSolution from "@/components/WelcomePages/OneAppSolution.vue";
import OurCourses from "@/components/WelcomePages/OurCourses.vue";
import OurFeatures from "@/components/WelcomePages/OurFeatures.vue";
import LearnFree from "@/components/WelcomePages/LearnFree.vue";
import WelcomePageFaq from "@/components/WelcomePages/WelcomePageFaq.vue";
// import UserExperience from "@/components/WelcomePages/UserExperience.vue";
import CoursePrice from "@/components/WelcomePages/CoursePrice.vue";
import WelcomeFooter from "@/components/WelcomePages/WelcomeFooter.vue";
import store from "@/store";
import {StaticTags} from "@/static";
import {onMounted} from "vue";
import {useRouter} from "vue-router";
export default {
  components: {
    WelcomeFooter,
    WelcomePageFaq,
    CoursePrice,
    // UserExperience,
    LearnFree,
    OurFeatures,
    OurCourses,
    AppOverView,
    HeroPage,
    // TopHeader,
    WelcomeHeader,
    OneAppSolution
  },

  setup() {
    const router = useRouter();
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
    onMounted(()=>{
      if (isLoggedIn) {
        router.push('/home');
      } else {
        router.push('/');
      }

    })
    return {

    };
  },

};
</script>

<style>


.section {
  padding-top: 100px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  .section {
    padding-top: 50px;
  }
}


.section-title {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
  z-index: 5;
}

.section-title h3 {
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
  color: #ff6b81;
  text-transform: uppercase;
}

.section-title h2 {
  margin-bottom: 25px;
  text-transform: capitalize;
  position: relative;
  font-family: Noto Sans;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;

}

.section-title p {
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: center;

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 70px;
  }
  .section-title h3 {
    font-size: 14px;
  }
  .section-title h2 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .section-title p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .section-title {
    margin-bottom: 60px;
  }
  .section-title h3 {
    font-size: 14px;
  }
  .section-title h2 {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 18px;
  }
  .section-title p {
    font-size: 14px;
  }
}

.section-title.align-right {
  padding: 0;
  padding-left: 600px;
}

.section-title.align-right h2:before {
  display: none;
}

.section-title.align-right h2:after {
  position: absolute;
  right: 0;
  bottom: -1px;
  height: 2px;
  width: 50px;
  background: #ff6b81;
  content: "";
}

.section-title.align-left {
  padding: 0;
  padding-right: 600px;
}

.section-title.align-left h2:before {
  left: 0;
  margin-left: 0;
}
</style>