<template>
  <SyllabusDialog @close="closeSyllabusModal" :modalSyllabusActive="modalSyllabusActive"
                  v-model:exam="selectedExam" @startExam="onExamStartClicked" :isStartBtn="isShowStartBtn">

  </SyllabusDialog>
  <carousel
      v-bind="settings" :breakpoints="breakpoints"
  >
    <slide
        v-for="(item, index) in routineList"
        :key="index"
    >
      <CourseExamDetails
          :item="item"
          :isLive="true"
          :hideBtn="true"
          :isHome="true"
          @syllabusModal="syllabusModal"
          @startExamDialog="startExamDialog"
      >
      </CourseExamDetails>
    </slide>
    <template #addons>
      <Pagination />
    </template>
  </carousel>
</template>
  
<script>
import 'vue3-carousel/dist/carousel.css';
import httpClient from "@/config/httpClient";
import { defineComponent, ref, onMounted } from "vue";
import {StaticTags, URLs} from "@/static";
import { useRouter } from "vue-router";
import CourseExamDetails from "@/components/CourseExamDetails.vue";
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import store from '@/store/index.js';
import SyllabusDialog from "@/components/ExamList/SyllabusDialog.vue";
import {setExamInfo, setExamParams} from "@/config/utils";

export default defineComponent({

  props: ['isLiveMode'],
  components: {
    CourseExamDetails,
    SyllabusDialog,
    Carousel,
    Slide,
    Pagination
  },
  setup() {

    const routineList = ref([])
    const modalMenuActive = ref(false);
    const modalSyllabusActive = ref(false)
    const selectedExam = ref(null)
    const isShowStartBtn = ref(false)
    const isRunningExam = ref(false)
    const router = useRouter();
    const totalLabel = ref("All Routine / Archieved")

    onMounted(() => {
      getLiveExamList()

    });

    const handleAllButtonClick = () => {

      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_LIVE_COURSE)
      router.push({
        name: URLs.ROUTE_LIVE_COURSE_EXAM_LIST_NAME,
      })
    };
    const closeSyllabusModal = () => {
      modalSyllabusActive.value = false
    }

    const syllabusModal = (exam) => {
      isShowStartBtn.value = false
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam
    }

    const startExamDialog = (exam) => {
      isShowStartBtn.value = true
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam

      if (exam.ended == StaticTags.ID_EXAM_RUNNING) {
        isRunningExam.value = true
      }
    }

    const onExamStartClicked = () => {
      setExamParams({
        examId: selectedExam.value.id
      })
      setExamInfo({
        id: selectedExam.value.id,
        title: selectedExam.value.title,
        examFlowType: StaticTags.TYPE_COURSE_EXAM,
        isRunningExam: isRunningExam.value,
      })
      router.push({name: URLs.ROUTE_EXAM_NAME})
    }


    const getLiveExamList = async () => {
      const config = {
        params: {
          "running": 1,
          "upcoming": 0,
          "previous": 0,
          "own": 0
        },
      };

      const url = process.env.VUE_APP_API_BASE_URL + "/exams";

      await httpClient
        .get(url, config)
        .then((res) => {
          routineList.value = res.exams
          console.log("RoutineList:::",routineList.value)
        })
    }
    return {
      routineList,
      totalLabel,
      handleAllButtonClick,
      closeSyllabusModal,
      isShowStartBtn,
      modalSyllabusActive,
      selectedExam,
      isRunningExam,
      modalMenuActive,
      syllabusModal,
      startExamDialog,
      onExamStartClicked,
    };
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      autoPlay: true,
      pauseAutoplayOnHover: true,
      snapAlign: 'start',
    },
    breakpoints: {
      768: {
        itemsToShow: 1,
      },
      769: {
        itemsToShow: 3,
      },
      1024: {
        itemsToShow: 3,
      },
    },
  }),
});
</script>
<style >
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
.carousel__pagination-button::after {
  border-radius: 50%!important;
  width: 10px !important;
  height: 10px !important;
}

.carousel, .slide {
  width: 100%;
}


.live-exam-all {

  font-family: 'Noto Sans Bengali';
  font-size: 20px;
  font-weight: 650;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;


}

@media (max-width: 768px) {
  .all-exam-web {
    display: none;
  }
}


</style>
  