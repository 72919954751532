<template>
  <div class="question-card mx-auto mb-3">
    <ReportQuestionModal v-if="reportModalActive" @close="closeReportModal" @submit="reportSubmit"
                         :modalActive="reportModalActive" :question="question">

    </ReportQuestionModal>
    <div class="d-flex justify-content-between">

      <div class="text-left">
        <h4 class="question-title">
      <span class="serial-number">
        {{ englishToBengaliNumber(questionIndex + 1) + ". " }}
      </span>
          {{ question.question }}
        </h4>
      </div>
      <div>
        <QuestionMenu v-if="isMenuShowAble" :menuItems="showableOptions"
                      @onClickOption="onClickOption" @toggleShowMenueList='toggleShowMenueList'
                      :isExerciseMode="isExerciseMode" :isFavourite="isFavourite"
                      :showMenuList="showMenuList"/>
      </div>

    </div>
    <div v-if="question.question_img && question.question_img !=''">
      <img :src="question.question_img" alt="image" style="width: 100%">
    </div>
    <div class="question-card-body">
      <div class=" question-item justify-content-start">
        <div v-for="(option, index) in question.options" :key="index" class="col-lg-12 col-md-12 col-sm-12"
             :class="{
             'showcorrectcolor': !isExamMode && ((answerMarked && option.answer === 1 && selectedOption.answer != 1) ||
                (isResultMode && option.answer === 1)
              ),
          'correctcolor': !isExamMode && answerMarked && selectedOption.answer === 1 && option.answer === 1,
              'incorrectcolor': !isExamMode && answerMarked && (selectedOption != '' && selectedOption.choice[0].value == option.choice[0].value && option.answer != 1)
            }">
          <label class="option form-check" :class="{
              'disabled': viewOnly,
              'showcorrect': !isExamMode && ((answerMarked && option.answer === 1 && selectedOption.answer != 1) ||
                (isResultMode && option.answer === 1)
              ),
              'correct': !isExamMode && answerMarked && selectedOption.answer === 1 && option.answer === 1,
              'incorrect': !isExamMode && answerMarked && (selectedOption != '' && selectedOption.choice[0].value == option.choice[0].value && option.answer != 1)
            }">
            <input type="radio" :name="'question-' + questionIndex" :value="option" v-model="selectedOption"
                   @change="markAnswer(index, questionIndex)" :disabled="answerMarked || isDisable || viewOnly"
                   class="form-check-input">
            <span class="option-text form-check-label">{{option.choice[0].value }}</span>
          </label>
        </div>
      </div>

    </div>
    <div class="question-card-footer text-muted">
      <div v-if="(isExerciseMode != undefined && isExerciseMode && answerMarked) || (isFavourite != undefined && isFavourite && answerMarked) || isResultMode">
        <div v-if="question.explanation_img && question.explanation_img != ''">
          <img :src="question.explanation_img" alt="image" style="width: 100%">
        </div>
        <p class="question-explanation-text"><span class="question-explanation"> ব্যাখ্যা: </span>
          {{ question.explanation }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted} from 'vue'
import QuestionMenu from "@/components/QuestionMenu.vue";
import ReportQuestionModal from "@/components/ReportQuestionModal.vue";
import httpClient from "@/config/httpClient";
import {StaticTags} from '@/static/index.js'
import {englishToBengaliNumber} from "@/config/utils";
import Swal from 'sweetalert2/dist/sweetalert2';

export default defineComponent({
  components: {
    QuestionMenu, ReportQuestionModal
  },

  props:
      ["questionIndex", "isExamMode", "isExerciseMode", "question", "questionWithState", "viewOnly", "isFavourite", "isMenuShowAble", "isResultMode"]
  ,
  setup(props, context) {
    const selectedOption = ref('');
    const answerMarked = ref(false);
    const showableOptions = ref()
    const isDisable = ref(false)
    const question = ref(props.question)
    const reportModalActive = ref(false);
    const showMenuList = ref(false)
    const menuOptions = [
      {
        title: StaticTags.QUESTION_REVIEW_TITLE,
        action: StaticTags.ACTION_REVIEW
      },
      {
        title: StaticTags.QUESTION_REMOVE_TITLE,
        action: StaticTags.ACTION_REMOVE
      },
      {
        title: StaticTags.QUESTION_REPORT_TITLE,
        action: StaticTags.ACTION_REPORT
      },
      {
        title: StaticTags.QUESTION_SEARCH_TITLE,
        action: StaticTags.ACTION_SEARCH
      }
    ]

    onMounted(() => {
      if (props.isFavourite) {

        menuOptions.splice(0, 1)  // 0 = index number 1 = remove 1 item it returns the deleted item
        showableOptions.value = menuOptions
      } else {
        menuOptions.splice(1, 1)
        showableOptions.value = menuOptions
      }
      if (props.isResultMode && !props.isExerciseMode) {
        answerMarked.value = true;
        disabledOptionsWithCorrectAnswer();
      }
    });

    const toggleShowMenueList = () => {

      showMenuList.value = !showMenuList.value

    }

    const showReportModal = () => {
      reportModalActive.value = true
    }

    const closeReportModal = () => {
      reportModalActive.value = false
    };
    const reportQuestionFunction = () => {
      showReportModal()
    }

    const disabledOptionsWithCorrectAnswer = () => {
      props.question.options.forEach((option, index) => {
        if (props.question.mark - 1 == index) {
          selectedOption.value = option
        }
      })

      disableOptions()
    }

    const onClickOption = (action) => {

      switch (action) {

        case StaticTags.ACTION_REVIEW:
          markForReviewFunction(question.value.id, question.value.category_id)
          break

        case StaticTags.ACTION_REMOVE:
          removeReviewFunction(question.value.id, question.value.category_id)
          break

        case StaticTags.ACTION_REPORT:
          reportQuestionFunction(question.value)
          break

        case StaticTags.ACTION_SEARCH:
          //
          break
      }
    }


    const reportSubmit = (reportModalData) => {
      closeReportModal()

      const url = process.env.VUE_APP_API_BASE_URL + "/reports";
      const data = {
        "correct": reportModalData.selectedOption,
        "explanation": reportModalData.inputValue,
        "question": question.value.id
      }


      httpClient
          .post(url, data)
          .then((res) => {
            toggleShowMenueList()
            Swal.fire({
              text: res.message,
              title: "Successful",
              icon: StaticTags.ALERT_TYPE_SUCCESS
            })
          })

    }


    const markForReviewFunction = (id, topicId) => {

      const url = process.env.VUE_APP_API_BASE_URL + "/favourites";
      const data = {
        topic_id: topicId,
        question: id,
      }


      httpClient
          .post(url, data)
          .then((res) => {
            toggleShowMenueList()
            Swal.fire({
              text: res.message,
              title: "Successful",
              icon: StaticTags.ALERT_TYPE_SUCCESS
            })
          })

    }

    const removeReviewFunction = (id, topicId) => {

      const url = process.env.VUE_APP_API_BASE_URL + "/favourites";
      const data = {
        topic_id: topicId,
        question: id,
      }


      httpClient
          .put(url, data)
          .then((res) => {
            context.emit('updateQuestions')
            toggleShowMenueList()
            Swal.fire({
              text: res.message,
              title: "Successful",
              icon: StaticTags.ALERT_TYPE_SUCCESS
            })
          })

    }
    function markAnswer(answerIndex, questionIndex) {
      answerMarked.value = true;
      context.emit("onAnswered", props.question.id, answerIndex + 1, questionIndex) // answer list is 1 based
      disableOptions();
    }

    function disableOptions() {
      // const options = event.target.parentElement.parentElement.querySelectorAll('input[type="radio"]');
      // options.forEach(option => {
      //   option.disabled = true;
      // });
      isDisable.value = true
    }

    return {
      selectedOption,
      answerMarked,
      menuOptions,
      showMenuList,
      toggleShowMenueList,
      markAnswer,
      disableOptions,
      showableOptions,
      onClickOption,
      isDisable,
      reportSubmit,
      reportModalActive,
      closeReportModal,
      englishToBengaliNumber,

    };
  },
});

</script>

<style lang="scss">

.question-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  //border-radius: 0.25rem;
  //width: 616px !important;
  height: fit-content !important;
  flex-shrink: 0;
  fill: #FFF;
  stroke-width: 1px;
  stroke: rgba(211, 216, 220, 0.30);
  padding: 12px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.question-card-header {
  padding: 0.75rem 0.75rem 0 0;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-gap: 10px;
}

.question-card-body {
  flex: 1 1 auto;
}

.question-card-footer {
  padding: 0.75rem 1.25rem;
}

.question-explanation-text {
  color: #000;
  font-family: Noto Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
}

.question-explanation {
  color: #999;
  font-family: Noto Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.question-item {
  display: block;
  text-align: left;
  padding-left: 5px;
}

.question-item .incorrectcolor {
  background: rgb(255 0 0 / 5%);
}

.question-item .correctcolor {
  background: rgb(0 128 0 / 5%);
}

.question-item .showcorrectcolor {
  background: rgb(0 128 0 / 5%);
}

.option-text {
  color: var(--mono-black, #000) !important;
  font-family: Circular Std;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 100%; /* 20px */
}

.question-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.serial-number {
  color: #999592;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 100% */
}

.question-padding-width {
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 66.666667%
}

.question-card .option {
  padding: 0rem !important;
  margin: 0.5rem;
  border-radius: 0.25rem;
}

@media (max-width: 850px) {
  .question-padding-width-answer {
    padding: 0;
    max-width: 60% !important;
  }
}

@media (max-width: 768px) {
  .question-item {
    text-align: left;
    padding-left: 2px;
    margin: 3px
  }

  .question-card .option {
    padding: 0rem !important;
  }

  .question-padding-width {
    padding: 0;
    max-width: 70%
  }

  .question-padding-width-answer {
    padding: 0;
    max-width: 52% !important;
  }
}

@media (max-width: 668px) {
  .question-padding-width-answer {
    padding: 0;
    max-width: 48% !important;
  }
}

@media (max-width: 636px) {
  .question-padding-width-answer {
    padding: 0;
    max-width: 44% !important;
  }
}

@media (max-width: 599px) {
  .question-padding-width-answer {
    padding: 0;
    max-width: 40% !important;
  }
}

@media (max-width: 535px) {
  .question-padding-width-answer {
    padding: 0;
    max-width: 37% !important;
  }
}

@media (max-width: 499px) {
  .question-padding-width-answer {
    padding: 0;
    max-width: 33% !important;
  }
}

@media (max-width: 450px) {
  .question-padding-width-answer {
    padding: 0;
    max-width: 30% !important;
  }
}

@media (max-width: 399px) {
  .question-padding-width {
    padding: 0;
    max-width: 95%
  }
  .question-padding-width-answer {
    padding: 0;
    max-width: 27% !important;
  }
}

@media (max-width: 350px) {
  .question-padding-width {
    padding: 0;
    max-width: 80%
  }
  .question-padding-width-answer {
    padding: 0;
    max-width: 24% !important;
  }
}

@media (max-width: 300px) {
  .question-padding-width {
    padding: 0;
    max-width: 65%
  }
  .question-padding-width-answer {
    padding: 0;
    max-width: 21% !important;
  }
}

@media (max-width: 250px) {
  .question-padding-width {
    padding: 0;
    max-width: 60%
  }
  .question-padding-width-answer {
    padding: 0;
    max-width: 15% !important;
  }
}

@media (max-width: 220px) {
  .question-padding-width {
    padding: 0;
    max-width: 50%
  }
  .question-padding-width-answer {
    padding: 0;
    max-width: 13% !important;
  }
}

.question-card {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .option {
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 0.25rem;

    &.correct {

      //background-color: #c3e6cb;
      .option-text {
        color: limegreen !important;
      }
    }

    &.showcorrect {

      //background-color: #c3e6cb;
      .option-text {
        color: limegreen !important;
      }
    }

    &.selected {

      input[type='radio'] {
        color: #f5c6cb;
      }

      .option-text {
        color: limegreen !important;
      }
    }

    &.incorrect {
      //background-color: #f8d7da;
      //
      //input[type='radio'] {
      //  border-color: #f5c6cb;
      //}

      .option-text {
        color: #f6071d !important;
      }
    }

    &.disabled {
      input[type='radio'] {
        cursor: not-allowed;
      }

      .option-text {
      }
    }
  }
}
</style>