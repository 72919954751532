<template>
  <!--      Header section start -->
  <Header/>
  <!--      Header section end -->
  <SubMenu :title="title"></SubMenu>

  <div class="container-fluid">
    <div class="lecture-notes-section">
      <div class="subjective-title-2 text-center">
        <h2 class="top-title-div" >{{title}}</h2>
      </div>
      <div class="row content-center">
        <div class="package-box-list">
          <div class="p-2" v-for="item in lectureNotesList" :key="item.id">
            <div class="package-box" style="cursor: pointer" @click="goToItemDetails(item)">
              <div class="package-box-left">
                <div class="package-box-title">
                  {{ item.title }}
                </div>
                <div class="package-box-sub-title">
                  Last Updated: {{ convertToBanglaDate(item.updated_at) }}
                </div>
              </div>
              <div class="package-box-right">
                <i class='fas fa-angle-right'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer/>
    </div>
    <!--      Footer section end -->
  </div>

</template>
<script>
import {defineComponent, onMounted, ref} from "vue";
import Header from "@/components/NewHeaderView.vue";
import SubMenu from "@/components/SubMenu.vue";
import Footer from "@/components/FooterView.vue";
import store from "@/store";
import httpClient from "@/config/httpClient";
import {URLs} from "@/static";
import {useRouter} from "vue-router";

export default defineComponent({
  components: {Footer, SubMenu, Header},
  setup() {
    const title = ref(null);
    const lectureNotesList = ref([]);
    const router = useRouter();
    onMounted(() => {
      title.value = store.state.titleBar ? store.state.titleBar : "লেকচার এন্ড নোট্‌স"
      getLectureNotesList()
    });

    const getLectureNotesList = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + "/lesson-types";
      await httpClient
          .get(url)
          .then((res) => {
            lectureNotesList.value = res.lesson_types
            console.log("Response::",res);
          })
    }

     const convertToBanglaDate = (englishDate) => {
      const monthsMap = {
        '1': 'জানুয়ারি',
        '2': 'ফেব্রুয়ারি',
        '3': 'মার্চ',
        '4': 'এপ্রিল',
        '5': 'মে',
        '6': 'জুন',
        '7': 'জুলাই',
        '8': 'আগস্ট',
        '9': 'সেপ্টেম্বর',
        '10': 'অক্টোবর',
        '11': 'নভেম্বর',
        '12': 'ডিসেম্বর',
      };
      const englishToBengaliNumber = (number) => {
        const bengaliNumbers = {
          '0': '০',
          '1': '১',
          '2': '২',
          '3': '৩',
          '4': '৪',
          '5': '৫',
          '6': '৬',
          '7': '৭',
          '8': '৮',
          '9': '৯'
        };

        const englishNumber = String(number);
        const bengaliNumber = englishNumber.split('').map(digit => bengaliNumbers[digit] || digit).join('');

        return bengaliNumber;
      }
       console.log("englishDate::",englishDate);
      englishDate = new Date(englishDate)
       console.log("englishDate::",englishDate);
      const year = englishDate.getFullYear();
      const month = englishDate.getMonth() + 1;
      console.log("month::",month);
      const day = englishDate.getDate();
      const banglaDate = `${englishToBengaliNumber(day)} ${monthsMap[month]} ${englishToBengaliNumber(year)}`;
      return banglaDate;
    }

    const goToItemDetails = (item) => {
      store.dispatch("setTitleBar", item.title)
      router.push({
        name: URLs.ROUTE_LECTURE_NOTE_NAME,
        title: item.title,
        params: {
          id: item.id,
        }
      })
    }


    return {
      title,
      lectureNotesList,
      goToItemDetails,
      convertToBanglaDate
    };
  }

})
</script>
<style scoped>
.lecture-notes-section {
  min-height: 90vh;
}
.content-center {
  flex-direction: column;
}
.package-box {
  width: 100%;
  height: fit-content;
  background-color: #E3EEFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: grid;
  grid-gap: 20px;
  justify-content: left;
  align-items: center;
  padding: 20px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.package-box:hover {
  background-color: #0095FF;
  color: white !important;

  .package-box-title {
    color: white !important;
  }

  .package-box-sub-title {
    color: white !important;
  }

  .package-box-price {
    color: white !important;

    .dam {
      color: white !important;
    }

    .actual_dam {
      color: white !important;
    }
  }
}

.package-box-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.package-box-sub-title {
  color: #817f7f;;
  font-family: Noto Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.package-box-list {
  display: grid;
  padding: 20px;
}

@media (min-width: 768px) {
  .package-box {
    grid-template-columns: 1fr auto;
  }
  .package-box-list {
    display: grid;
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
  }
}

</style>