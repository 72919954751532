<template>
  <section class="app-over-view section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h2 class="wow fadeInUp" data-wow-delay=".4s">এক নজরে বিসিএস প্রস্তুতি
            </h2>
            <p class="wow fadeInUp" data-wow-delay=".6s">৫ লক্ষ+ চাকরী প্রত্যাশী বিশ্বাস রেখেছে বিসিএস প্রস্তুতির উপর</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-12">
          <div class="over-view-box wow fadeInUp" data-wow-delay=".2s">
            <h3>২০+</h3>
            <p>লাইভ কোর্স </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="over-view-box wow fadeInUp" data-wow-delay=".2s" style="background-color: #EAF9FA">
            <h3>১ লক্ষ+</h3>
            <p>ব্যাখ্যাসহ প্রশ্ন</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="over-view-box wow fadeInUp" data-wow-delay=".2s" style="background-color: #FDEEF3">
            <h3>৩০০+</h3>
            <p>টপিক</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="over-view-box wow fadeInUp" data-wow-delay=".2s" style="background-color: #F2FAEF">
            <h3>৫ লক্ষ+</h3>
            <p>ডাউনলোড</p>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>
<script>
</script>
<style scoped>
.app-over-view {
  background-color: #f9f9f9;
  position: relative;

}

.app-over-view .section-title {
  margin-bottom: 30px;

}

.app-over-view .over-view-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 35px;
  background-color: #FDF7E9;
  position: relative;
  margin-top: 30px;
  border: 1px solid #eee;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  width: 90%;
  height: 150px;
  border-radius: 24px;
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.app-over-view .over-view-box:hover {
  -webkit-box-shadow: 0px 3px 5px #00000017;
  box-shadow: 0px 3px 5px #00000017;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.app-over-view .over-view-box h3 {
  font-family: Noto Sans;
  font-size: 35px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
}

.app-over-view .over-view-box p {
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;

}
</style>