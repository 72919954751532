<template>
    <div class="course-list">

      <div v-for="(item, index) in courseCategories" :key="index">
        <div class="personal-practice-button" style="cursor: pointer"  @click="goToCourse(item)">
          <div class="button-icon">
            <img class="button-image" :src="item.thumbnail">
          </div>
          <div class="button-title">
            <h1 class="title-text">{{item.title}}</h1>
          </div>
          <div v-if="item.tag" class="rectangle-in-corner">{{ item.tag }}</div>
        </div>
      </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import store from '@/store';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {StaticTags, URLs} from "@/static";
import httpClient from "@/config/httpClient";
// import {useRouter} from "vue-router";
import Swal from 'sweetalert2/dist/sweetalert2';
import {englishToBengaliNumber} from "@/config/utils";
import {useRouter} from "vue-router";
export default defineComponent({

  setup() {
    const title = ref(null)
    const router = useRouter();
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
    const courseCategories = ref([])
    // const router = useRouter()
    onMounted(() => {
      getCourseCategories()
    });

    const getCourseCategories = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + "/course-types";
      if (isLoggedIn) {
        await httpClient
            .get(url)
            .then((res) => {
              if (res.success) {
                courseCategories.value = res.course_types;
              } else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }

            })
      }
    }

    const goToCourse = (item) => {
      console.log(item)
      store.dispatch("setTitleBar", item.title)
      router.push({
        name: URLs.ROUTE_COURSE_TYPES_ALL_NAME,
        params: {
          id: item.id,
        }
      })
    }


    return {
      title,
      access_token,
      englishToBengaliNumber,
      courseCategories,
      goToCourse
    };
  },
});
</script>



<style scoped>
.rectangle-in-corner {
  width: fit-content;
  height: 14px;
  background-color: #0095FF;
  position: absolute;
  color: white;
  border-radius: 3px;
  top: -7px;
  right: 0;
  padding-left: 10px;
  padding-right: 10px;
  //padding-top: 1px;
  font-size: 10px;
  font-weight: 600;
}
.course-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
}

.card {
  position: relative;
  background-color: #ddd;
  padding: 20px;
  margin: 10px;
  border-radius: 15px;
}
.personal-practice-button {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
  opacity: 0.9599999785423279;
  //width: 300px;
  height: 80px;
  position: relative;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1.1fr 2fr;
  grid-gap: 2px;
}

.button-icon {
  background: #fff5f6;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  position: relative;
  left: 23px;
}
.button-image {
  width: 54px;
  height: 54px;
  position: relative;
}
.title-text {
  color: #010101;
  text-align: left;
  font: 600 18px/21px "Noto Sans Bengali", sans-serif;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  top: 19px;
}

@media (min-width: 768px) {
  .course-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media (max-width: 768px) {

  .personal-practice-button {
    height: 60px;
  }

  .practice-close-btn {
    width: 100%;
  }

  .practice-start-btn {
    width: 100%;
  }

  .button-icon {
    width: 30px;
    height: 30px;
    left: 7px;
  }
  .button-image {
    width: 15px;
    height: 15px;
    position: relative;
    top: 3px;
    left: 0px;
  }
  .title-text {
    font: 600 13px/21px "Noto Sans Bengali", sans-serif;
    top: 7px;
  }

}

</style>