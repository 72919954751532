<template>
  <div v-show="modalActive" class="modal " >
    <div v-show="modalActive">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-content-title-section">
              <h2 class="modal-content-title">{{title}}</h2>
              <h2 class="content-sub-title">

              </h2>
            </div>
          </div>
          <div class="modal-body">
            <div class="modal-content-syllabus" v-if="isExamRunning && !result && (resultTime !== null)">
              <p style="color: red" >এখানে শুধু ফলাফল দেয়া হল।  সম্পূর্ণ উত্তরপত্র এবং মেধাতালিকা - 5.00 AM এ প্রকাশিত হবে। কোন সমস্যা/জিজ্ঞসা থাকলে আমাদের মেসেন্জারে অবিহিত করুন। </p>
            </div>
            <div class="modal-content-syllabus">
              <div v-if="!isExamRunning && !result && exam" class="text-center">
                <table class="table table-borderless ">
                  <thead>
                  <tr>
                    <th scope="col">পরীক্ষার্থী</th>
                    <th scope="col">উত্তীর্ণ</th>
                    <th scope="col">পজিশন</th>
                    <th scope="col">কাট মার্ক</th>
                    <th scope="col">সর্বোচ্চ</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ exam.participants }}</td>
                    <td>{{ exam.passed }}</td>
                    <td>{{ exam.position }}</td>
                    <td>{{ exam.cut_mark }}</td>
                    <td>{{ exam.best }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <table class="table table-borderless table-responsive">
                  <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">মোট</th>
                    <th scope="col" style="color: green">সঠিক</th>
                    <th scope="col" style="color: red">ভুল</th>
                    <th scope="col" style="color: blue">মার্কস</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="result">
                    <td>ফলাফল</td>
                    <td>{{ result.total }}</td>
                    <td>{{ result.correct }}</td>
                    <td>{{ result.wrong }}</td>
                    <td>{{ result.mark ? result.mark : 0 }}</td>
                  </tr>

                  <tr v-else >
                    <td>ফলাফল</td>
                    <td>{{ exam.result.total }}</td>
                    <td>{{ exam.result.correct }}</td>
                    <td>{{ exam.result.wrong }}</td>
                    <td>{{ exam.result.mark }}</td>
                  </tr>
                  </tbody>
                </table>
                <p></p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" style="width: 100%;" class="list-group-item btn btn-outline-danger" @click="close">বন্ধ করুন</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ["modalActive", "exam","isExamRunning","title", "result", "resultTime"],
    setup(props, { emit }) {
        const close = () => {
            emit("close");
        };
        const start = () => {
            emit("start")
        }
        return { close, start };
    },
};
</script>
<style lang="scss" scoped>
.exam-title {
  text-align: center;
  font-size: x-large;
  font-weight: 600;
  line-height: 30px;
}
.modal-content-syllabus {
  text-align: justify;
}
.modal-content {
  border-radius: 24px;
}
.close-btn {
  width: 150px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 24px;
}
.start-btn {
  width: 150px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 24px;
}

.modal-header {
  text-align: center;
  justify-content: center;
  border-bottom: 0px solid #e9ecef;
}
.modal-footer {
  text-align: center;
  justify-content: center;
  border-top: 0px solid #e9ecef;
}
.modal-body {
  padding: 2rem;
}
.modal-content-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 107.143% */
}
.content-sub-title {
  color: #00A8A8;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 80% */
}
.syllabus-title {
  color: #2f2d2d;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 60% */
  text-align: start;
}

.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.syllabus-content {
  color: #2f2d2d;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(14 13 13 / 71%);
}

.modal-dialog {
  width: auto;
  height: 662px;
}

@media (min-width: 576px){
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }

}

</style>