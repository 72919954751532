<template>
  <section class="in-app-solution section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h2 class="wow fadeInUp" data-wow-delay=".4s">এক অ্যাপ এই সব সমাধান
            </h2>
            <p class="wow fadeInUp" data-wow-delay=".6s">সহজ সুন্দর ডিজাইনে প্রস্তুতি হবে ঘরে বসেই</p>
          </div>
        </div>
      </div>
      <div class="">
        <carousel
            v-bind="settings" :breakpoints="breakpoints"
        >
          <slide
              v-for="(item, index) in itemList"
              :key="index"
          >

<!--            <div class="pl-2 mb-3">-->
              <div class="in-app-solution-box wow fadeInUp" data-wow-delay=".2s">
                <div class="top-image">
                  <img class="crop-image" :src="item.image">
                </div>
                <div class="bottom-text">
                  <h3 class="wow fadeInUp" data-wow-delay=".4s">{{item.title}}
                  </h3>
                  <p class="wow fadeInUp" data-wow-delay=".6s">{{item.description}}</p>
                </div>
              </div>
<!--            </div>-->

          </slide>
          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>
    </div>

  </section>
</template>
<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide } from 'vue3-carousel';


export default defineComponent({
  name: "InAppSolutions",
  components: {
    Carousel,
    Pagination,
    Slide
  },
  setup() {
    const itemList = [
      {
        image: require("@/assets/inappsolutions/liveexam.png"),
        title: "লাইভ পরীক্ষা",
        description: "প্রতিদিন কোর্সভিত্তিক লাইভ পরীক্ষা",
      },
      {
        image: require("@/assets/inappsolutions/CourseCategory.png"),
        title: "কোর্স ক্যাটাগরি",
        description: "বিসিএস, ব্যাংক, প্রাইমারি সহ সকল চাকরির প্রস্তুতি কোর্স",
      },
      {
        image: require("@/assets/inappsolutions/ExamRoutine.png"),
        title: "পরীক্ষার রুটিন",
        description: "রুটিন অনুসারে সাজানো প্রতিটি কোর্স",
      },
      {
        image: require("@/assets/inappsolutions/SubjectiveExam.png"),
        title: "বিষয়ভিত্তিক অনুশীলন / পরীক্ষা ",
        description: "টপিক সাব টপিক অনুসারে সাজানো প্রতিটি বিষয়",
      },
      {
        image: require("@/assets/inappsolutions/QuesttionBank.png"),
        title: "প্রশ্ন ব্যাংক",
        description: "সকল চাকরীর ব্যাখ্যাসহ পূর্ণাঙ্গ জব সল্যুশন",
      },
      {
        image: require("@/assets/inappsolutions/Explanation.png"),
        title: "ব্যাখ্যা সহ প্রিমিয়াম প্রশ্ন পত্র",
        description: "বিস্তারিত ব্যাখ্যা সহ সাজানো প্রতিটি প্রশ্ন",
      },
      {
        image: require("@/assets/inappsolutions/Resultbreakdown.png"),
        title: "পরীক্ষার মূল্যায়ন",
        description: "পরীক্ষার মার্কস এর বিষয়ভিত্তিক মূল্যায়ন",
      },
      {
        image: require("@/assets/inappsolutions/MeritList.png"),
        title: "মেধা তালিকা",
        description: "হাজারো প্রতিযোগীর সাথে যাচাই করা যাবে নিজের অবস্থান",
      },
      {
        image: require("@/assets/inappsolutions/lecture.png"),
        title: "লেকচার অ্যান্ড নোটস",
        description: "গোছানো প্রস্তুতি নিশ্চিতে সাজানো প্রতিটি সেকশন",
      },
      {
        image: require("@/assets/inappsolutions/quiz.png"),
        title: "কুইজ কুইজ",
        description: "প্রস্তুতি হবে মজায় মজায়, প্রস্তুতি খেলায় খেলায় ",
      },
      {
        image: require("@/assets/inappsolutions/Result.png"),
        title: "প্রস্তুতির পরিসংখ্যান",
        description: "প্রস্তুতির সামগ্রিক পরিসংখ্যানস",
      }
    ];
    return {
      itemList,
    };
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      autoplay: 4000,
      pauseAutoplayOnHover: true,
      loop: true,
      snapAlign: 'start',
      wrapAround: true,
    },
    breakpoints: {
      768: {
        itemsToShow: 1,
      },
      769: {
        itemsToShow: 3,
      },
      1024: {
        itemsToShow: 3,
      },
    },
  }),
});
</script>
<style scoped>

.crop-image {
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.in-app-solution {
  background-color: #f9f9f9;
  position: relative;
}

.in-app-solution .in-app-solution-box .top-image {
  position: relative;
  width: 267px;
  height: 400px;
  top: -35px;
  left: 50px;
  border-radius: 16px;
  border: 1px solid #DBDBDB;
}

@media (max-width: 768px) {
  .in-app-solution .in-app-solution-box .top-image {
    left: 46px;
  }
}

@media (max-width: 576px) {
  .in-app-solution .in-app-solution-box .top-image {
    left: 36px;
  }
}

.in-app-solution .section-title {
  margin-bottom: 30px;

}

.in-app-solution .in-app-solution-box {
  text-align: center;
  background-color: #fff;
  position: relative;
  margin-top: 30px;
  border: 1px solid #eee;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  width: 100%;
  height: max-content;
  border-radius: 24px;
  box-shadow: 0px 1px 24px 0px #00000014;
  overflow: hidden;
  margin: 20px;
}

.in-app-solution .in-app-solution-box:hover {
  -webkit-box-shadow: 0px 3px 5px #00000017;
  box-shadow: 0px 3px 5px #00000017;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}


.in-app-solution .in-app-solution-box .bottom-text {
  padding: 10px;
}


.in-app-solution .in-app-solution-box .bottom-text h3 {
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  text-align: center;
}

.in-app-solution .in-app-solution-box .bottom-text p {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.5px;
}
</style>