<template>
  <div class="">
    <b-button class="select-button" type="button">নিচের সকল বিষয়</b-button>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.select-button {
  width: 180px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #0095FF;
  color: #FFF;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}
.button-text {
  color: #FFF;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 75% */
}
.btn-secondary {
  border-color: #0095FF;
}

.btn-secondary:hover {
  background: #0095FF;
  border-color: #0095FF;
}
</style>