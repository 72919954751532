<template>
  <!--      Header section start -->
  <WelcomeHeader />
  <!--      Header section end -->
  <!--  Exam Header start-->

  <SubMenu :title="title"></SubMenu>
  <div class="container-fluid about-us-container">
    <div class="table-responsive">
      <table class="table " style="text-align: justify">
<!--        <caption class="caption-top">আমাদের সম্পর্কে</caption>-->
        <thead>
        <a href="https://codeminersit.com/" target="_blank">
          <tr>
            <th scope="col" colspan="2">
             <img :src="require('@/assets/companylogo/Logo.svg')" alt="" width="350" />
            </th>
          </tr>
        </a>
        </thead>
        <tbody>
        <tr>
          <th scope="row">Name</th>
          <td>Codeminers IT Limited</td>
        </tr>
        <tr>
          <th scope="row">Address</th>
          <td>22-23 Station Road, Tejgaon;<br> Tejgaon PS; Dhaka-1215;</td>
        </tr>
        <tr>
          <th scope="row">Email</th>
          <td>codeminersbd@gmail.com</td>
        </tr>
        <tr>
          <th scope="row">Phone</th>
          <td>01313899797 | 01715561961</td>
        </tr>
        <tr>
          <th scope="row">Trade License No</th>
          <td>253310</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="container-fluid">
  </div>

  <WelcomeFooter />

</template>
<script>
import {defineComponent, onMounted, ref} from "vue";
import SubMenu from "@/components/SubMenu.vue";
import WelcomeHeader from "@/components/WelcomePages/WelcomeHeader.vue";
import WelcomeFooter from "@/components/WelcomePages/WelcomeFooter.vue";

export default defineComponent({
  components: {WelcomeHeader, SubMenu, WelcomeFooter},
  setup() {
    const title = ref(null)
    onMounted(() => {
      title.value = "আমাদের সম্পর্কে"
    });


    return {
      title,
    };
  },
})
</script>
<style scoped>
.about-us-container {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
caption {
  caption-side: top !important;
}
.table-responsive {
  border-radius: 20px !important;
  background: rgba(255, 223, 193, 0.60) !important;
}


</style>