<template>

  <Header/>

  <SubMenu :title="title"></SubMenu>
  <div v-if="isExamMode" >
    <marquee width="60%" direction="left" height="30%" style="color: red">
      Please refrain from refreshing your webpage during the exam. Your given answers could be lost.
    </marquee>
  </div>
  <ExamResultModal v-if="showResult" @close="toggleResultModal" :modalActive="resultModalActive"
                   :exam="examResult ? examResult : {}" :result="result" :title="title" :isExamRunning="isExamRunning" :resultTime="resultTime">

  </ExamResultModal>
  <div class="container-fluid">
    <div class="exam-page ">
      <div class="exam-result">
        <ResultSectionView v-if="isResultShowable" :exam="exam">

        </ResultSectionView>
      </div>
      <div class="exam-counter text-right">
        <CounterView v-if="isExamMode && maxCount > 0 && isExamStarted" @finishCount="finishCount"
                     :maxCount="maxCount" v-model="counter">

        </CounterView>
      </div>
      <div class="exam_question">

        <div class="h-100 align-items-center justify-content-center">
          <div class="" v-if="questions.length > 0">
            <QuestionView v-for="(q, index) in questions" :questionIndex="index" :key="q.id" :question="q"
                          :isExamMode="isExamMode" :isExerciseMode="isExerciseMode" @onAnswered='onAnswered'
                          :viewOnly="viewOnly"
                          :isFavourite="isFavourite" :isMenuShowAble="isMenuShowAble" :isResultMode="isResultMode"
                          @updateQuestions="getQuestions" v-model:questionWithState="questionWithState[index]"/>
          </div>
        </div>


        <div v-if="isExamMode && showSubmitBtn" class="floating-buttons">
          <button class="btn-scroll btn-submit" @click="onSubmit">
            <i class="bi bi-check2-all icon"></i>
          </button>
        </div>
        <div v-if="!isExamMode && showEyeIcon" class="floating-buttons">
          <div class="on-show-result" @click="onClickedSolution">
            <i v-if="isSolutionMode" class="bi bi-eye-slash icon"></i>
            <i v-else class="bi bi-eye icon"></i>
          </div>
        </div>

      </div>


    </div>
  </div>

  <div class="container-fluid">
    <div ref="footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import QuestionView from "@/components/QuestionView.vue";
import ExamResultModal from "@/components/ExamResultModal.vue"
import Question from "@/dataModel/QuestionModel.js";
import QuestionViewInformation from "@/dataModel/QuestionViewInformation.js";
import SubMenu from "@/components/SubMenu.vue";
//import { mapGetters } from "vuex";
import {useRouter} from "vue-router";
import store from '@/store/index.js';
import httpClient from "@/config/httpClient";
import { StaticTags } from '@/static/index.js'
import Swal from 'sweetalert2/dist/sweetalert2';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ResultSectionView from "@/components/ExamDetailsView/ResultSectionView.vue";
import CounterView from "@/components/ExamList/CounterView.vue";
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import {decrypt} from "@/config/Crypto";

export default defineComponent({
  beforeRouteLeave(to, from, next) {
    if (this.isExamMode && this.isExamRunning && this.isExamStarted && !this.showResult) {
      const answer = window.confirm('Are you sure you want to leave? You will lose your progress.')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else if (this.isExamMode && this.isExamRunning && this.isExamStarted && this.showResult) {
      next()
    } else if (this.isResultMode){
      next()
    } else if (!this.isResultMode){
      const answer = window.confirm('Are you sure you want to leave? You will lose your progress.')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
    else {
      next()
    }
  },
  components: {
    Footer,
    Header,
    SubMenu,
    QuestionView, ExamResultModal, CounterView, ResultSectionView
  },
  models: {
    Question,
  },

  setup() {

    let questions = ref([]);
    let questionWithState = [];
    const resultModalActive = ref(false);
    const isResultShowable = ref(false)
    const exam = ref(null)
    const selectedQuestion = ref({
      question: "",

      options:
          {
            choice: [""]
          }
    })

    const router = useRouter()

    const isExamMode = ref(false)
    const resultTime = ref(null)
    const examInfo = store.getters[StaticTags.GET_EXAM_INFO];
    const examParams = store.getters[StaticTags.GET_EXAM_PARAMS]
    const title = ref(examInfo.title)
    const total = ref(examInfo.total)
    const isExamRunning = ref(examInfo.isRunningExam)
    const time = ref()
    const maxCount = ref()
    const ansList = []
    const viewOnly = ref(true)
    const showEyeIcon = ref(false)
    const isFavourite = ref(false)
    // const flowType = store.state.flowType
    let examFlowType = examInfo.examFlowType
    let examFlowTypeStore = examInfo.examFlowType
    const isMenuShowAble = ref(false)
    const isResultMode = ref(false)
    const isSolutionMode = ref(false)
    const isExerciseMode = ref(false)
    const showSubmitBtn = ref(true)
    const counter = ref(0)
    const showResult = ref(false)
    let correct = 0
    let wrong = 0
    const selectedExam = ref()
    const isExamStarted = ref(false)
    const examResult = ref(null)
    const result = ref(null)
    const response = ref(null)
    const questionsForSolution = ref(null)
    onMounted(() => {

      getQuestions()

    });
    const showResultModal = () => {
      resultModalActive.value = true
    }

    const toggleResultModal = () => {
      resultModalActive.value = !resultModalActive.value;
      if (isExamRunning.value && resultTime.value != null) {
        questions.value = []
        counter.value = 0
        maxCount.value = 0
        router.go(-1)
      }
      if (isExamRunning.value && resultTime.value == null) {
        examFlowType = StaticTags.TYPE_COURSE_EXAM_RESULT
        getQuestions()
      }
    };


    const onSubmit = async () => {
      Swal.fire({
        text: "",
        title: "You sure to submit?",
        icon: StaticTags.ALERT_TYPE_WARNING,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#d33',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          submitAnswer()
        }
      })

    }

    const submitAnswer = async () => {
      var ansBody = []
      for (const a of ansList) {
        if (a.selectedAns != 0 && a.selectedAns == a.correctAns) {
          correct += 1
        } else if (a.selectedAns != 0) {
          wrong += 1
        }
        ansBody.push({
          "question": a.qid,
          "mark": a.selectedAns
        })
      }
      var apiBody =
          {
            "topic": title.value,
            "total": total.value,
            "correct": correct,
            "wrong": wrong,
            "exam_time": examInfo.examTime,
            "answers": ansBody
          }

      if (examFlowType == StaticTags.TYPE_COURSE_EXAM) {
        apiBody =
            {
              "exam": examParams.examId,
              "total": total.value,
              "correct": correct,
              "wrong": wrong,
              "exam_time": time.value,
              "answers": ansBody,
              "late": (selectedExam.value.exam.ended != StaticTags.ID_EXAM_RUNNING && selectedExam.value.exam.ended != StaticTags.ID_EXAM_FINISHED) ? 1 : 0,
              "solveTime": (time.value - counter.value / 60.0) > 0 ? (time.value - counter.value / 60.0) : null,
            }
      }
      var url = process.env.VUE_APP_API_BASE_URL + "/results";

      if (examFlowType == StaticTags.TYPE_COURSE_EXAM) {
        url = process.env.VUE_APP_API_BASE_URL + "/exams/" + examParams.examId + "/results";
      }

      await httpClient
          .post(url, apiBody)
          .then((res) => {
            console.log(res)
            showResultModal()
            showResult.value = true
            counter.value = 0
            maxCount.value = 0
            examResult.value = res.exam
            if (examFlowType == StaticTags.TYPE_EXAM || examFlowType == StaticTags.TYPE_OVER_ALL_EXAM) {
              result.value = res.result
            }
            if (!isExamRunning.value) {
              if (examFlowTypeStore == StaticTags.TYPE_EXAM || examFlowTypeStore == StaticTags.TYPE_OVER_ALL_EXAM) {
                examFlowType = StaticTags.TYPE_EXAM_RESULT
              } else if (examFlowTypeStore == StaticTags.TYPE_COURSE_EXAM) {
                examFlowType = StaticTags.TYPE_COURSE_EXAM_RESULT
              }
              getQuestions()
            }
          })
    }

    const finishCount = () => {
      //showSubmitBtn.value = true
      Swal.fire({
        text: "",
        title: "Time Up",
        icon: StaticTags.ALERT_TYPE_WARNING,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Submit',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          submitAnswer()
        }
      })
    }

    const onAnswered = (qid, ans, serial) => {
      if (qid == ansList[serial].qid) {
        ansList[serial].selectedAns = ans
      }
    }

    const onClickedSolution = () => {
      if (!isExamRunning.value) {
        if (isSolutionMode.value) {
          examFlowType = examFlowTypeStore
          isSolutionMode.value = false
        } else {
          isSolutionMode.value = true

          examFlowType = StaticTags.TYPE_SOLUTION
        }
        getQuestions()
      }
    }

    const toggleViewOnly = () => {
      viewOnly.value = !viewOnly.value
    }


    const getQuestions = () => {
      if (examFlowType == StaticTags.TYPE_EXERCISE) {
        setExerciseMode()
        getExerciseQuestions()
      } else if (examFlowType == StaticTags.TYPE_EXAM_RESULT) {
        setExamResultModeOn()
        getExamResultQuestions()
        // getExamResultQuestions()
      } else if (examFlowType == StaticTags.TYPE_EXAM) {
        setExamModeOn()
        getExamQuestions()
      } else if (examFlowType == StaticTags.TYPE_OVER_ALL_EXAM) {
        setExamModeOn()
        getOverAllExamQuestions()
      } else if (examFlowType == StaticTags.TYPE_FAVOURITE) {
        setFavouriteMode()
        getFavoriteQuestions()
      } else if (examFlowType == StaticTags.TYPE_QUIZ) {
        getExerciseQuestions
      } else if (examFlowType == StaticTags.TYPE_RESULT) {
        setResultModeOn()
        getResultsQuestions()
      } else if (examFlowType == StaticTags.TYPE_SOLUTION) {
        setResultModeOnShow()
        getSolutionQuestions()
      } else if (examFlowType == StaticTags.TYPE_COURSE_EXAM) {
        getCourseExamQuestions()
      } else if (examFlowType == StaticTags.TYPE_LIVE_EXAM) {
        getCourseExamQuestions()
      } else if (examFlowType == StaticTags.TYPE_COURSE_EXAM_RESULT) {
        setResultModeOn()
        getCourseExamResultsQuestions()
      } else if (examFlowType == StaticTags.TYPE_COURSE_EXAM_EXERCISE) {
        setExerciseMode()
        getCourseExamExerciseQuestions()
      } else {
        isExamStarted.value = false
        Swal.fire({
          text: "No Exam Found. Start From Home",
          title: "Failed",
          icon: StaticTags.ALERT_TYPE_ERROR,
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: "Okay"
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/')
          }
        })
      }
    };

    const setExerciseMode = () => {
      isExerciseMode.value = true
      isExamMode.value = false
      isMenuShowAble.value = true
      viewOnly.value = false
      isResultMode.value = false
      showEyeIcon.value = true
    }
    const setFavouriteMode = () => {
      isFavourite.value = true
      isExamMode.value = false
      isMenuShowAble.value = true
      viewOnly.value = false
      isResultMode.value = false
      showEyeIcon.value = true
    }

    const setExamModeOn = () => {
      isMenuShowAble.value = true
      isExamMode.value = true
      isExamStarted.value = true
      viewOnly.value = false
    }

    const setExamResultModeOn = () => {
      isMenuShowAble.value = true
      isResultMode.value = true
      isExamMode.value = false
      isExamStarted.value = false
      viewOnly.value = true
    }

    const setResultModeOn = () => {
      isMenuShowAble.value = true
      isResultMode.value = true
      isExamMode.value = false
      isExamStarted.value = false
      viewOnly.value = true
    }

    const setResultModeOnShow = () => {
      if (examFlowTypeStore == StaticTags.TYPE_EXERCISE ) {
        isExerciseMode.value = true
        isMenuShowAble.value = true
      }
      if (examFlowTypeStore == StaticTags.TYPE_FAVOURITE ) {
        isFavourite.value = true
        isMenuShowAble.value = true
      }
      isResultMode.value = true
      isExamMode.value = false
      isExamStarted.value = false
      viewOnly.value = true
      isSolutionMode.value = true
    }

    const getResultsQuestions = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + "/questions/results/" + examParams.examId
      if (questionsForSolution.value) {
        setQuestionAndAnswerState(questionsForSolution.value)
      } else {
        await httpClient
            .get(url)
            .then((res) => {
              if (res != undefined) {
                if (res) {
                  questions.value = checkResponseQuestions(res.questions);
                  questionsForSolution.value = questions.value
                  setQuestionAndAnswerState(questions.value)
                }
              }
            })
      }
    }


    const getSolutionQuestions = async () => {
        if (questionsForSolution.value) {
          isResultShowable.value = false
          showResult.value = false
          setQuestionAndAnswerState(questionsForSolution.value)
        }
    }

    const getCourseExamExerciseQuestions = async () => {
      if ( questionsForSolution.value) {
        setQuestionAndAnswerState(questionsForSolution.value)
      } else {
        const url = process.env.VUE_APP_API_BASE_URL + "/exams/" + examParams.examId
        await httpClient
            .get(url)
            .then((res) => {
              if (res.success) {
                questions.value = checkResponseQuestions(res.questions);
                questionsForSolution.value = questions.value
                setQuestionAndAnswerState(questions.value)
              }else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR,
                  showConfirmButton: true,
                  confirmButtonText: "Okay"
                }).then((result) => {
                  if (result.isConfirmed) {
                    router.go(-1)
                  }
                })
              }


            })
      }

    }

    const getCourseExamResultsQuestions = async () => {
      if ( questionsForSolution.value) {
        setQuestionAndAnswerState(questionsForSolution.value)
      } else {
        const url = process.env.VUE_APP_API_BASE_URL + "/exams/" + examParams.examId
        await httpClient
            .get(url)
            .then((res) => {
              if (res.success) {
                console.log(res)
                exam.value = res.exam
                resultTime.value = res.exam_type.result_time
                if (exam.value != null) {
                  showResultModal()
                  isResultShowable.value = true
                  showResult.value = true
                  examResult.value = res.exam
                }
                questions.value = checkResponseQuestions(res.questions);
                questionsForSolution.value = questions.value
                setQuestionAndAnswerState(questions.value)
              } else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR,
                  showConfirmButton: true,
                  confirmButtonText: "Okay"
                }).then((result) => {
                  if (result.isConfirmed) {
                    router.go(-1)
                  }
                })
              }


            })
      }

    }

    const getOverAllExamQuestions = async () => {

      const url = process.env.VUE_APP_API_BASE_URL + "/questions/total";
      //check examparams are set or not. there need to be sure to save it offline

      if (examParams.topic) {
        if ( questionsForSolution.value) {
          setQuestionAndAnswerState(questionsForSolution.value)
        }
        else {
          const config = {
            params: {
              number: examParams.questionNumber,
            },
          };
          await httpClient
              .get(url, config)
              .then((res) => {
                questions.value = checkResponseQuestions(res.questions);
                questionsForSolution.value = questions.value
                response.value = res
                if (examFlowType != StaticTags.TYPE_EXAM_RESULT) {
                  maxCount.value = examInfo.examTime * 60
                }
                setQuestionAndAnswerState(questions.value)
              })
        }


      } else {
        console.log("No exam params found")
      }

    }

    const getExamQuestions = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + "/questions/topic";
      //check examparams are set or not. there need to be sure to save it offline

      if (examParams.topic) {
        if ( questionsForSolution.value) {
          setQuestionAndAnswerState(questionsForSolution.value)
        }
        else {
          const config = {
            params: {
              topic: examParams.topic,
              topic_ids: [examParams.topicId],
              number: examParams.questionNumber,
            },
          };
          await httpClient
              .get(url, config)
              .then((res) => {
                questions.value = checkResponseQuestions(res.questions);
                questionsForSolution.value = questions.value
                response.value = res
                if (examFlowType != StaticTags.TYPE_EXAM_RESULT) {
                  maxCount.value = examInfo.examTime * 60
                }
                setQuestionAndAnswerState(questions.value)
              })

        }

      } else {
        console.log("No exam params found")
      }

    }


    const getExamResultQuestions = async () => {
      // const url = process.env.VUE_APP_API_BASE_URL + "/questions/topic";
      questions.value = questionsForSolution.value;
      setQuestionAndAnswerState(questions.value)
    }

    const getCourseExamQuestions = async () => {
      //check examparams are set or not. there need to be sure to save it offline
      if (examParams.examId) {
        if ( questionsForSolution.value) {
          setQuestionAndAnswerState(questionsForSolution.value)
        }
        else {
          const config = {
          };
          const url = process.env.VUE_APP_API_BASE_URL + "/exams/" + examParams.examId;
          await httpClient
              .get(url, config)
              .then((res) => {
                if (res != undefined) {
                  if (res.success) {
                    resultTime.value = res.exam_type.result_time
                    questions.value = checkResponseQuestions(res.questions);
                    questionsForSolution.value = questions.value
                    setQuestionAndAnswerState(questions.value)
                    setExamModeOn()
                    time.value = res.exam.time
                    maxCount.value = time.value * 60
                    selectedExam.value = res
                  }
                  else {
                    Swal.fire({
                      text: res.message,
                      title: "Failed",
                      icon: StaticTags.ALERT_TYPE_ERROR,
                      showConfirmButton: true,
                      confirmButtonText: "Okay"
                    }).then((result) => {
                      if (result.isConfirmed) {
                        router.go(-1)
                      }
                    })
                  }
                }

              })
        }
      }
      else {
        // console.log("No exam params found")
      }

    }

    const getExerciseQuestions = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + "/questions/read";
      var page = 0
      if (examParams.page) {
        page = examParams.page
      }
      //check examparams are set or not. there need to be sure to save it offline
      if (examParams.topic) {
        const config = {
          params: {
            topic: examParams.topic,
            topic_id: examParams.topicId,
            page: page,
          },
        };
        if (questionsForSolution.value) {
          setQuestionAndAnswerState(questionsForSolution.value)
        }
        else {
          await httpClient
              .get(url, config)
              .then((res) => {
                questions.value = checkResponseQuestions(res.questions);
                questionsForSolution.value = questions.value
                setQuestionAndAnswerState(questions.value)
              })
        }
      } else {
        console.log("No exam params found")
      }

    }

    const getFavoriteQuestions = async () => {
      if ( questionsForSolution.value) {
        setQuestionAndAnswerState(questionsForSolution.value)
      }
      else {
        const url = process.env.VUE_APP_API_BASE_URL + "/favourite-questions"
        let page = 0
        if (examParams.page) {
          page = examParams.page
        }
        if (examParams.topic) {
          const config = {
            params: {
              topic: examParams.topic,
              topic_id: examParams.topicId,
              topic_ids: [examParams.topicId],
              page: page,
              number: examParams.questionNumber,
            },
          };
          await httpClient
              .get(url, config)
              .then((res) => {
                if (res.success) {
                  questions.value = checkResponseQuestions(res.questions);
                  questionsForSolution.value = questions.value
                  setQuestionAndAnswerState(questions.value)
                } else {
                  Swal.fire({
                    text: res.message,
                    title: "Failed",
                    icon: StaticTags.ALERT_TYPE_ERROR,
                    showConfirmButton: true,
                    confirmButtonText: "Okay"
                  })
                }

              })

        } else {
          console.log("No exam params found")
        }
      }

    }

    const setQuestionAndAnswerState = (questions) => {
      questionWithState = []
      while (ansList.length > 0) {
        ansList.pop();
      }
      for (const q of questions) {
        let correctOption = 0
        q.options.forEach(function (option, i) {
          if (option.answer == 1) {
            correctOption = i + 1
          }
        });

        var questionState = new QuestionViewInformation()
            .setQuestion(q)
            .setGivenAnswer(correctOption)
            .build();
        questionWithState.push(questionState)
        ansList.push({qid: q.id, correctAns: correctOption, selectedAns: 0});
      }
      store.dispatch("ExamRunningState/setQuestionInformation", questionState);

    }

    const checkResponseQuestions = (questions) => {
      if (typeof questions === "string") {
        return JSON.parse(decrypt(questions))
      }
      else if (typeof questions === "object" && questions != null) {
        return questions
      }
    }


    const scrollUp = () => {
      window.scrollBy(0, -100);
    }
    const scrollDown = () => {
      window.scrollTo(0, window.scrollY + 100);
    }

    return {
      questions,
      isExamMode,
      onAnswered,
      onClickedSolution,
      questionWithState,
      title,
      viewOnly,
      toggleViewOnly,
      onSubmit,
      scrollDown,
      scrollUp,
      toggleResultModal,
      showResultModal,
      getQuestions,
      finishCount,
      isExamStarted,
      isResultShowable,
      exam,
      maxCount,
      counter,
      isFavourite,
      showSubmitBtn,
      selectedQuestion,
      isMenuShowAble,
      isResultMode,
      isSolutionMode,
      isExerciseMode,
      resultModalActive,
      isExamRunning,
      examResult,
      result,
      showResult,
      showEyeIcon,
      resultTime,
    };
  },

});
</script>

<style>

.btn-submit:hover {
  color: #fff;
  background-color: #0095FF;
  border-color: #0095FF;
}

.btn-submit {
  color: #fff;
  background-color: #0095FF;
  border-color: #0095FF;
}

.btn-submit.focus, .btn-submit:focus {
  box-shadow: 0 0 0 0.2rem #0095FF !important;
}

.exam-counter {
}

.exam-page {
  display: grid;
  grid-template-columns: auto 1.5fr auto;
  grid-gap: 20px;
}

.exam-result {
  display: none;
}

.top-title-timer {
  position: fixed;
  top: 20px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 999;
}

.floating-buttons {
  position: fixed;
  bottom: 100px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 999;
  cursor: pointer;
}

.btn-scroll {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-white {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.on-show-result {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a0a0a;
  color: #fff;
  border: 1px solid #080809;

}

.icon {
  /* width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center; */
}

.exam-header {
  height: 132px;
  flex-shrink: 0;
  background: #ffdfc152;
  text-align: start;
}

.exam-header-content {
  padding: 50px;
}

.parent-title {
  color: #0095FF;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.child-title {
  color: rgba(7, 24, 51, 0.60);
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

@media (max-width: 992px) {
  .container-fluid-responsive {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .timer__count[data-v-be76acc2] {
    height: 70%;
  }

  .exam-page {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-gap: 20px;
  }

  .exam-result {
    grid-column: 1/2;
  }

  .exam_question {
    grid-column: 2/3;
  }

  .exam-counter {
    visibility: visible;
    grid-column: 3/4;
  }
}

@media (min-width: 994px) {
  .timer__count[data-v-be76acc2] {
    height: 100%;
  }
}
</style>