<template>
  <!--      Header section start -->
  <Header />
  <!--      Header section end -->
  <!--  Exam Header start-->

  <SubMenu :title="title"></SubMenu>
  <div class="container-fluid question-bank-section">
    <div class="subjective-title-2 text-center">
      <h2 class="top-title-div" >{{title}}</h2>
    </div>
    <div class="row content-center">
      <div class="package-box-list">
        <div class="p-2" v-for="item in questionDetails" :key="item.id">
          <div class="package-box" style="cursor: pointer" @click="goToQuestionView(item)">
            <div class="package-box-left">
              <div class="package-box-title">
                {{ item.title }}
              </div>
              <div class="package-box-sub-title">
                সর্বমোটঃ {{ englishToBengaliNumber(item.total) }}
              </div>
            </div>
            <div class="package-box-right">
            </div>
          </div>
        </div>
      </div>
      <PaginationPage
          :totalItems="itemTotal"
          :itemsPerPage="itemsPerPage"
          :currentPage="currentPage"
          @page-changed="pageChanged"
      />
    </div>
  </div>

  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer />
    </div>
    <!--      Footer section end -->
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import SubMenu from "@/components/SubMenu.vue";
import store from '@/store';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import {StaticTags, URLs} from "@/static";
import httpClient from "@/config/httpClient";
// import {useRouter} from "vue-router";
import Swal from 'sweetalert2/dist/sweetalert2';
import {englishToBengaliNumber, setExamInfo, setExamParams} from "@/config/utils";
import {useRoute} from "vue-router";
import router from "@/router";
import PaginationPage from "@/components/PaginationPage.vue";
export default defineComponent({
  components: {
    Footer,
    Header,
    SubMenu,
    PaginationPage,
  },
  setup() {
    const title = ref(null)
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
    const questionDetails = ref([])
    const route = useRoute();
    const currentPage = ref(1);
    const itemsPerPage = ref(20);
    const itemTotal = ref(null);


    const pageChanged = (page) => {
      currentPage.value = page;
      getCourseTypes();
    };

    // const router = useRouter()
    onMounted(() => {
      title.value = store.state.titleBar ? store.state.titleBar : "প্রশ্ন ব্যাংক"
      getCourseTypes()
    });

    const getCourseTypes = async () => {
      const config = {
        params: {
          "exam_type": route.params.id,
          "running": 1,
          "upcoming": 0,
          "previous": 0,
          "own": 0,
          "page": currentPage.value - 1,
        },
      };
      const url = process.env.VUE_APP_API_BASE_URL + "/exams";
      if (isLoggedIn) {
        await httpClient
            .get(url, config)
            .then((res) => {
              console.log('Data fetched:', res);
              if (res.success) {
                itemTotal.value = res.total;
                questionDetails.value = res.exams;
              } else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }
            })
      }
    }

    const goToQuestionView = (exam) => {
      setExamParams({
        examId: exam.id
      })
      setExamInfo({
        id: exam.id,
        title: exam.title,
        examFlowType: StaticTags.TYPE_COURSE_EXAM_EXERCISE
      })
      router.push({name: URLs.ROUTE_EXAM_NAME})
    }

    return {
      title,
      access_token,
      englishToBengaliNumber,
      questionDetails,
      goToQuestionView,
      currentPage,
      itemsPerPage,
      pageChanged,
      itemTotal,
    };
  },
});
</script>



<style scoped>
.question-bank-section {
  min-height: 80vh;
}
.content-center {
  display: flex;

  flex-direction: column;
}
.package-box {
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: grid;
  grid-gap: 20px;
  justify-content: left;
  align-items: self-start;
  padding: 20px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.package-box:hover {
  background-color: #0095FF;
  color: white !important;

  .package-box-title {
    color: white !important;
  }

  .package-box-sub-title {
    color: white !important;
  }

  .package-box-price {
    color: white !important;

    .dam {
      color: white !important;
    }

    .actual_dam {
      color: white !important;
    }
  }
}

.package-box-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.package-box-sub-title {
  color: #009688;
  font-family: Noto Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.package-box-list {
  display: grid;
  padding: 20px;
}

@media (min-width: 768px) {
  .package-box {
    grid-template-columns: 1fr auto;
  }
  .package-box-list {
    display: grid;
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
  }
}


</style>