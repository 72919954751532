<template>
  <Header />

  <SubMenu :title="title"></SubMenu>

  <div class="container-fluid">
    <div class="container-fluid pl-0 pr-0 pt-5">
      <div class="container">
        <div class="row g-0">
          <div class="col-lg-8 col-md-8 col-sm-8">
            <h1 style="text-align: left">{{ packageDetails ? packageDetails.name : '' }}</h1>
            <p style="text-align: left">{{ packageDetails ? packageDetails.short_description : '' }}</p>
            <div class="d-block text-left">
              <h5 style="text-align: left; font-weight: 600">ফুল প্যাকেজ যা যা থাকছে</h5>
              <p style="white-space: pre-line" v-html="packageDetails ? packageDetails.long_description : ''"></p>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4">
          </div>
          <div v-if="!showPromoInput" class="col-lg-12 col-md-12 col-sm-12 p-2 text-justify">
                      <span  @click="showPromoInput = true" style="cursor: pointer; color: #0095FF">
                        <svg _ngcontent-serverApp-c87="" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-serverApp-c87="" d="M3.67063 12.9386L7.44563 16.7136C8.99563 18.2636 11.5123 18.2636 13.0706 16.7136L16.729 13.0553C18.279 11.5053 18.279 8.98864 16.729 7.43031L12.9456 3.66364C12.154 2.87197 11.0623 2.44697 9.94563 2.50531L5.77896 2.70531C4.1123 2.78031 2.7873 4.10531 2.70396 5.76364L2.50396 9.93031C2.45396 11.0553 2.87896 12.147 3.67063 12.9386Z" stroke="currentColor" stroke-width="1.41176" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-serverApp-c87="" d="M8.11458 10.1891C9.26518 10.1891 10.1979 9.25639 10.1979 8.10579C10.1979 6.9552 9.26518 6.02246 8.11458 6.02246C6.96399 6.02246 6.03125 6.9552 6.03125 8.10579C6.03125 9.25639 6.96399 10.1891 8.11458 10.1891Z" stroke="currentColor" stroke-width="1.41176" stroke-linecap="round"></path><path _ngcontent-serverApp-c87="" d="M11.0312 14.3558L14.3646 11.0225" stroke="currentColor" stroke-width="1.41176" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        <span style="cursor: pointer">প্রোমো কোড যোগ করুন</span>
                      </span>

          </div>
          <div v-if="showPromoInput && !appliedPromoCode" class="col-lg-12 col-md-12 col-sm-12 p-2">
            <div class="text-left">
              <input class="form-control-promo" type="text" v-model="promoCode" placeholder="প্রোমো কোড যোগ করুন">
              <button class="m-3 btn btn-outline-primary" @click="verifyPromoCode(packageDetails.id)">Apply</button><span style="color: red; cursor: pointer" @click="resetPromoCode">X</span>
              <p v-if="promoCodeError" style="color: red">{{ promoCodeError }}{{ "\xa0" }}{{"\xa0" }}<span style="color: red; cursor: pointer" @click="resetPromoCode">X</span></p>
            </div>
          </div>
          <div v-if="appliedPromoCode" class="col-lg-12 col-md-12 col-sm-12 p-2">
            <div class="d-block text-left">
              <h5> <b>প্রোমোঃ</b> <span style="color: green">{{ appliedPromoCode }}</span>{{ "\xa0" }}{{"\xa0" }}{{ "\xa0" }}{{"\xa0" }}<span style="cursor: pointer" @click="resetPromoCode">X</span></h5>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 p-2">
            <div class="tacbox" style="
              display: flex;
              padding: 1em;

              border: 3px solid #ddd;
              background-color: #eee;
              max-width: 800px;
            ">
              <input type="checkbox" id="checkbox" v-model="checked" class="inp-cbx"
                     style="margin-right: 10px; margin-bottom: 10px" />
              <label for="checkbox">
                I agree to these
                <a target="_blank" href="https://bcsprostuti.com/terms-condition.html">Terms and Conditions</a>,<a
                  target="_blank" href=" https://bcsprostuti.com/privacy-policy.html">Privacy policy</a>
                and
                <a target="_blank" href=" https://bcsprostuti.com/refund-policy.html">Refund policy</a></label>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 p-2" v-if="packageDetails" style="text-align: left">
            <button v-if="discountedPrice" class=" btn btn-outline-primary" type="button" :disabled="!checked"
                    @click="onPayClicked(packageDetails.id)">
              পে <span class="pay-dam" style="font-size: 1rem !important;"> ৳{{ englishToBengaliNumber(packageDetails.dam) }} </span>
              <span class=" pay-actual_dam" style="font-size: 1rem !important;">{{ "\xa0" }}{{"\xa0" }}৳{{ englishToBengaliNumber(packageDetails.dam - discountedPrice) }} </span>
            </button>
            <button v-else class="mt-3 btn btn-outline-primary" type="button" :disabled="!checked"
                    @click="onPayClicked(packageDetails.id)">
              পে  <span class="pay-dam" style="font-size: 1rem !important;" v-if="packageDetails.dam != packageDetails.actual_dam"> ৳{{englishToBengaliNumber(packageDetails.dam)}} </span>
              <span class="pt-2 pay-actual_dam" style="font-size: 1rem !important;">{{ "\xa0" }}{{ "\xa0" }}৳{{ englishToBengaliNumber(packageDetails.actual_dam) }} </span>
            </button>
          </div>
        </div>
      </div>

    </div>
    <div style="padding-top: 40px"></div>
    <div ref="footer">
      <Footer />
    </div>

  </div>
</template>
  
<script>
// @ is an alias to /src
// import Header from "@/components/HeaderView.vue";
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import store from '@/store/index.js';
import httpClient from "@/config/httpClient";
import {englishToBengaliNumber} from "@/config/utils";
import { defineComponent, ref, onMounted } from "vue";
import { StaticTags } from "@/static";
import { useRoute, useRouter } from "vue-router";

import Swal from 'sweetalert2/dist/sweetalert2';
import SubMenu from "@/components/SubMenu.vue";

export default defineComponent({
  components: {
    SubMenu,
    Footer,
    Header,
  },

  setup() {
    //const router = useRouter();
    const title = store.state.titleBar ? store.state.titleBar : "প্যাকেজ বিস্তারিত"
    const route = useRoute();
    const packageId = route.params.id
    const packageDetails = ref(null)
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const checked = ref(false)
    const router = useRouter()
    const promoCode = ref('')
    const promoCodeError = ref('')
    const discountedPrice = ref('')
    const appliedPromoCode = ref('')
    const showPromoInput = ref(false)
    const item =
          {
              "id": 25,
              "title": "৪৫ বিসিএস স্পেশাল ক্র্যাশ কোর্স",
              "start_hour": 6,
              "end_hour": 26,
              "result_hour": 29,
              "pass_percent": 10,
              "unit_mark": 1,
              "negative_mark": 0.5,
              "ended": 0,
              "start_time": "06:00:00",
              "end_time": "02:00:00",
              "result_time": "05:00:00",
              "image": "https://bcsprostuti.com/admin/images/63f7b12ccc725.jpg",
              "thumbnail": "https://bcsprostuti.com/admin/images/63f7b12ccc725.jpg",
              "color": "#fe8e4d",
              "creator_name": "BCS Prostuti",
              "user_result": 0,
              "running": 0,
              "upcoming": 23
          }




    const verifyPromoCode = async (packageID) => {
      if (promoCode.value == '') {
        promoCodeError.value = 'Please enter a promo code'
        return
      }
      await httpClient
          .post(process.env.VUE_APP_API_BASE_URL + "/promo-codes/" + promoCode.value + "/verify/" + packageID)
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                console.log("Promo Success:::",response)
                discountedPrice.value = response.discount
                appliedPromoCode.value = promoCode.value
                promoCode.value = ''
              } else {
                console.log("Promo Failed:::",response)
                promoCodeError.value = response.message
              }
            }
            else {
              console.log("Promo Failed:::",response)
            }
          })
      console.log(promoCode.value)
    }

    const resetPromoCode = () => {
      promoCode.value = ''
      promoCodeError.value = ''
      discountedPrice.value = ''
      appliedPromoCode.value = ''
      showPromoInput.value = false
    }
    const gotoExamList = () => {
          // router.push({
          //   name: StaticTags.ROUTE_PACKAGE_DETAILS_NAME,
          // })
          store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_LIVE_COURSE)
          router.push({
              name: StaticTags.ROUTE_COURSE_EXAM_LIST_NAME,
              params: {
                  exam_type: item.id,
              },
          })
      }
    onMounted(() => {
      if (packageId > -1) {
        getPackageDetails()

      }
      else {
        Swal.fire({
          text: "No Package ID found",
          title: "Failed",
          icon: StaticTags.ALERT_TYPE_ERROR,
          showConfirmButton: true,
          confirmButtonText: "Okay"
        }).then((result) => {
          if (result.isConfirmed) {
            router.push("/");
          }
        })
      }
    })

    const onPayClicked = (id) => {
      if (checked.value) {
        if ((!access_token) || access_token == "null") {
          store.commit("setPackId", id);
          router.push("/login");
        }
        else {
          buyPackage(id)
        }

      }
      else {
        Swal.fire({
          text: "Please agree to the Terms & Conditions",
          title: "Failed",
          icon: StaticTags.ALERT_TYPE_ERROR,
          showConfirmButton: true,
          confirmButtonText: "Okay"
        }).then((result) => {
          if (result.isConfirmed) {
            // do nothing
          }
        })
      }
    }

    const buyPackage = async (id) => {
      var data = JSON.stringify({
        website: true,
      });

      var config = {
        method: "post",
        url: process.env.VUE_APP_API_BASE_URL + "/memberships/" + id,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await httpClient(config)
        .then((response) => {
          if (response.success) {

            window.location.href = response.url;

          } else {
            Swal.fire({
              text: response.message,
              title: "Failed",
              icon: StaticTags.ALERT_TYPE_ERROR,
              showConfirmButton: true,
              confirmButtonText: "Okay"
            }).then((result) => {
              if (result.isConfirmed) {
                // do nothing
              }
            })
          }
        })

    }

    const getPackageDetails = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + "/packages/" + packageId
      await httpClient
        .get(url)
        .then((res) => {
          if (res) {
            if (res.success) {
              packageDetails.value = res.package
            }
            else {
              Swal.fire({
                text: res.message,
                title: "Failed",
                icon: StaticTags.ALERT_TYPE_ERROR,
                showConfirmButton: true,
                confirmButtonText: "Okay"
              }).then((result) => {
                if (result.isConfirmed) {
                  // do nothing
                }
              })
            }

          }


        })
    }

    return {
      packageDetails,
      checked,
      onPayClicked,
      item,
      gotoExamList,
      englishToBengaliNumber,
      promoCode,
      promoCodeError,
      verifyPromoCode,
      resetPromoCode,
      discountedPrice,
      appliedPromoCode,
      showPromoInput,
      title
    }
  }
});
</script>
<style>
.form-control-promo {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #007bff;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #007bff;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.cards:hover {
    /*box-shadow: 0 4px 10px rgba(0,0,0,0.16), 0 4px 10px rgba(0,0,0,0.23);*/
    border: 1px solid #0095FF;
}
.package-course {
    padding: 31px;
    margin-top: 74px;
}

.pay-dam {
  text-decoration: line-through;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.pay-actual_dam {
  font-family: Noto Sans Bengali;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}


@media (min-width: 300px) and (max-width: 580px) {
    .pay-dam {
        font-size: 14px !important;
    }

    .pay-actual_dam {
        font-size: 18px !important;
    }
}
</style>
  