<template>
  <header id="navbar_top" class="navbar navbar-expand-lg navbar-dark" :class="{'fixed-top': isNavbarFixed}">


    <a class="navbar-brand logo-image" href="/">
      <img :src="require('@/assets/newlog.svg')" alt="" width="50" />
    </a>

    <!-- Mobile Menu Toggle Button -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault" aria-expanded="isNavOpen.toString()" @click="isNavOpen = !isNavOpen" aria-label="Toggle navigation">
      <span v-if="!isNavOpen" class="navbar-toggler-awesome fas fa-bars"></span>
      <span v-else class="navbar-toggler-awesome fas fa-times"></span>
    </button>
    <!-- end of mobile menu toggle button -->

    <div class="collapse navbar-collapse" id="navbarsExampleDefault">
      <ul class="navbar-nav ml-auto">

        <li class="nav-item ">
          <a class="nav-link page-scroll" href="/">হোম</a>
        </li>

        <li class="nav-item">
          <a class="nav-link page-scroll" href="/#features">ফিচার</a>
        </li>

        <li class="nav-item">
          <a class="nav-link page-scroll" href="/#pricing">প্যাকেজ প্ল্যান</a>
        </li>


        <li class="nav-item">
          <a class="nav-link page-scroll" href="/blogs">ব্লগ</a>
        </li>

        <li class="nav-item">
          <a href="" class="nav-link page-scroll"
             onclick="window.open('https://play.google.com/store/apps/details?id=com.bcsprostuti.tanim.bcsprostuti&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1', '_blank')">
            ডাউনলোড{{ "\xa0" }}অ্যাপ
          </a>
        </li>

        <li v-if="isLoggedIn && userDetails" class="nav-item">
          <a class="nav-link page-scroll" href="/home"> পরীক্ষা দেই</a>
        </li>

        <li v-else class="nav-item">
          <a class="nav-link page-scroll" @click="checkForLogin"><button type="button" class="btn btn-primary">সাইন ইন{{ "\xa0" }}/আপ</button></a>
        </li>
      </ul>
    </div>
  </header>
</template>
<script >
import store from "@/store";
import {StaticTags} from "@/static";
import {onMounted, ref} from "vue";
import httpClient from "@/config/httpClient";
import {setStore} from "@/config/utils";

export default {
  data() {
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const userDetails = ref(null)
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
    const checkForLogin = () => {
      if (isLoggedIn) {
        this.$router.push('/home');
      } else {
        this.$router.push('/login');
      }
    };
    onMounted(()=>{
      if (isLoggedIn) {
        getUserDetails()
      }

    })
    const getUserDetails = async () => {
      await httpClient
          //.post(baseUrl + "api2/login/", loginParams)
          .get(process.env.VUE_APP_API_BASE_URL + "/user-info")
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                userDetails.value = response.user
                setStore(StaticTags.USER_DETAILS, response.user)
              }

            }

          })
    }
    return {
      isNavbarFixed: false,
      isNavOpen: false,
      checkForLogin,
      userDetails,
      isLoggedIn
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      var logo = document.querySelector('.navbar-brand img');
      var backToTo = document.querySelector(".nav-item");
      if (window.scrollY > 50) {
        this.isNavbarFixed = true;
        const navbarHeight = document.querySelector('.navbar').offsetHeight;
        document.body.style.paddingTop = navbarHeight + 'px';
        backToTo.style.color = '#000';
        logo.src = require('@/assets/logo.png');
      } else {
        this.isNavbarFixed = false;
        backToTo.style.color = '#fff';
        logo.src = require('@/assets/newlog.svg');
        document.body.style.paddingTop = '0';
      }
    }
  }
};
</script>


<style scoped>

.navbar-expand-lg .navbar-nav {
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.navbar-dark .navbar-toggler {
  color: #0095ff;
  border-color: #0095ff;
}


.fixed-top .nav-item .nav-link {
  color: black !important; /* Replace with the color you want */
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  background: #fff !important;
}

.navbar {
  background-color: #191B47
}

.navbar-custom {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  background-color: #191B47 !important;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  font: 600 0.875rem/0.875rem "Open Sans", sans-serif;
  transition: all 0.2s;
  top: 50px;
}
.navbar-brand img {
  width: 80px !important;
}
.navbar-custom .navbar-brand {
  font-size: 0;
}

.navbar-custom .navbar-brand.logo-image img {
  width: 7.0625rem;
  height: 2rem;
  -webkit-backface-visibility: hidden;
}

.navbar-custom .navbar-brand.logo-text {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font: 700 2.25rem/1.5rem "Montserrat", sans-serif;
  color: #fff;
  text-decoration: none;
}

.navbar-custom .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.navbar-custom .nav-item .nav-link {
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.2s ease;
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
  color: #14bf98;
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover > .dropdown-menu {
  display: block; /* this makes the dropdown menu stay open while hovering it */
  min-width: auto;
  animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar-custom .dropdown-toggle:focus { /* removes dropdown outline on focus  */
  outline: 0;
}

.navbar-custom .dropdown-menu {
  margin-top: 0;
  border: none;
  border-radius: 0.25rem;
  background-color: #191B47;
}

.navbar-custom .dropdown-item {
  color: #fff;
  text-decoration: none;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff ;
}

.navbar-custom .dropdown-item:hover {
  background-color: #191B47;
}

.navbar-custom .dropdown-item .item-text {
  font: 600 0.875rem/0.875rem "Open Sans", sans-serif;
}

.navbar-custom .dropdown-item:hover .item-text {
  color: #14bf98;
}

.navbar-custom .dropdown-items-divide-hr {
  width: 100%;
  height: 1px;
  margin: 0.75rem auto 0.75rem auto;
  border: none;
  background-color: #b5bcc4;
  opacity: 0.2;
}
/* end of dropdown menu */

.navbar-custom .social-icons {
  display: none;
}

.navbar-custom .navbar-toggler {
  border: none;
  color: #fff;
  font-size: 2rem;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times{
  display: none;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars{
  display: inline-block;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars{
  display: none;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times{
  display: inline-block;
  margin-right: 0.125rem;
}
</style>