<template>
  <!--      Header section start -->
  <Header/>
  <!--      Header section end -->
  <SubMenu :title="title"></SubMenu>

  <MenuDialog @close="closeMenuModal" :coordinates="coordinates" :modalMenuActive="modalMenuActive" v-model:exam="selectedExam">

  </MenuDialog>

  <SyllabusDialog @close="closeSyllabusModal" :modalSyllabusActive="modalSyllabusActive"
                  v-model:exam="selectedExam" @startExam="onStartExamClicked" :isStartBtn="isShowStartBtn">

  </SyllabusDialog>

  <div class="container-fluid pl-0 pr-0">
    <div class="container-fluid">
      <div style="margin-top: 10px;">

        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                    type="button" role="tab" aria-controls="nav-profile" aria-selected="true"
                    @click="getLiveTypeResult"> লাইভ এক্সাম
            </button>
            <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact"
                    type="button" role="tab" aria-controls="nav-contact" aria-selected="false" v-if="true"
                    @click="getOtherTypeResult"> অন্যান্য
            </button>
          </div>
        </nav>
      </div>


      <transition-group name="">
        <div class="tab-content" id="nav-tabContent" style="margin-top: 20px;">
          <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div v-if="isResult && Object.keys(liveTypeResultList).length > 0">
              <div v-for="( items, key ) in liveTypeResultList" :key="key">
                <div class="exam-list-container">
                  <div class="container-child-1">
                    <h2 class="date-format">{{ convertToBanglaDate(key) }}</h2>
                  </div>
                  <div class="container-child-2">
                    <CourseExamDetails v-for="(item, index) in items" :key="index" :item="item" :isSingleCourse="isSingleCourse"
                                       :status="getStatus(item)" :isResult="isResult" :isLive="isLive"
                                       :isMenuDisabled="isMenuDisabled" @showMenuModal="showMenuModal"
                                       @showSyllabusModal="showSyllabusModal"
                                       @showStartExamDialog="showStartExamDialog">
                    </CourseExamDetails>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
            <div v-if="isOtherResult && otherTyperResultList.length > 0">
              <div class="exam-list-container">
                <div class="container-child-1">
                </div>
                <div class="container-child-2">
                  <OtherResult v-for="(item, index) in otherTyperResultList" :key="index" :item="item" :status="getStatus(item)"
                               :isResult="isResult" :isLive="isLive" :isMenuDisabled="isMenuDisabled"
                               @showMenuModal="showMenuModal" @showSyllabusModal="showSyllabusModal"
                               @showStartExamDialog="showStartExamDialog">
                  </OtherResult>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <PaginationPage :total-items="itemTotal"
                          :items-per-page="itemsPerPage"
                          :current-page="currentPage"
                          @page-changed="onPageChanged" />

        </div>

      </transition-group>
    </div>
  </div>

  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer/>
    </div>
    <!--      Footer section end -->
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import store from '@/store/index.js';
import {StaticTags, URLs} from "@/static";
import httpClient from "@/config/httpClient";
import { setExamInfo, setExamParams } from "@/config/utils";
import SubMenu from "@/components/SubMenu.vue";
import Footer from "@/components/FooterView.vue";
import Header from "@/components/NewHeaderView.vue";
import PaginationPage from "@/components/PaginationPage.vue";
import CourseExamDetails from "@/components/CourseExamDetails.vue";
import { convertToBanglaDate } from "@/config/utils";
import SyllabusDialog from "@/components/ExamList/SyllabusDialog.vue";
import MenuDialog from "@/components/ExamList/MenuDialog.vue";
import OtherResult from "@/views/results/OtherResult.vue";

export default defineComponent({
  components: {OtherResult, MenuDialog, SyllabusDialog, CourseExamDetails, PaginationPage, Header, Footer, SubMenu},

  setup() {
    const router = useRouter();
    const title = ref(null);
    const isResult = ref(true)
    const isOtherResult = ref(false)
    const isLive = ref(false)
    const isSingleCourse = ref(false)
    const isMenuDisabled = ref(false)
    const modalMenuActive = ref(false);
    const modalSyllabusActive = ref(false)
    const selectedExam = ref(null)
    const isShowStartBtn = ref(false)
    const isRunningExam = ref(false)
    const coordinates = ref({x: 0, y: 0})
    let flowType = store.state.flowType
    const currentPageForLiveResult = ref(1);
    const currentPageForOtherResult = ref(1);
    const currentPage = ref(1);
    const itemsPerPage = ref(20);
    const itemTotal = ref(null);
    const resultType = ref(StaticTags.TYPE_LIVE_EXAM_RESULT)
    const liveTypeResultList = ref([])
    const otherTyperResultList = ref([])
    onMounted(() => {
      title.value = store.state.titleBar ? store.state.titleBar : "সামগ্রিক ফলাফল"
      if (!flowType) {
        router.push({
          name: StaticTags.ROUTE_OOPS_PAGE_NAME
        })
      }
      else {
        getLiveTypeResult()
      }


    });

    const closeMenuModal = () => {
      modalMenuActive.value = false
    }

    const showMenuModal = (exam, coordinate) => {
      modalMenuActive.value = true
      modalSyllabusActive.value = false
      selectedExam.value = exam
      coordinates.value = coordinate
    }

    const closeSyllabusModal = () => {
      modalSyllabusActive.value = false
    }

    const showSyllabusModal = (exam) => {
      isShowStartBtn.value = false
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam
    }

    const showStartExamDialog = (exam) => {
      isShowStartBtn.value = true
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam
      if (exam.ended == StaticTags.ID_EXAM_RUNNING) {
        isRunningExam.value = true
      } else {
        isRunningExam.value = false
      }
    }

    const onStartExamClicked = () => {
      setExamParams({
        examId: selectedExam.value.id
      })
      setExamInfo({
        id: selectedExam.value.id,
        title: selectedExam.value.title,
        examFlowType: StaticTags.TYPE_COURSE_EXAM,
        isRunningExam: isRunningExam.value,
      })
      router.push({name: URLs.ROUTE_EXAM_NAME})
    }

    const getLiveTypeResult = async () => {
      resultType.value = StaticTags.TYPE_LIVE_EXAM_RESULT
      currentPage.value = currentPageForLiveResult.value
      isResult.value = true
      const config = {
        params: {
          "page": currentPageForLiveResult.value - 1,
        },
      };
        const url = process.env.VUE_APP_API_BASE_URL + "/exam-results";

        await httpClient
          .get(url, config)
          .then((res) => {
            itemTotal.value = res.total;
            liveTypeResultList.value = groupByDate(res.exams);
          })


    }

    const getOtherTypeResult = async () => {
      resultType.value = StaticTags.TYPE_OTHER_EXAM_RESULT
      currentPage.value = currentPageForOtherResult.value
      isResult.value = false
      isOtherResult.value = true
      const config = {
        params: {
          "page": currentPageForOtherResult.value - 1,
        },
      };
        const url = process.env.VUE_APP_API_BASE_URL + "/results";

        await httpClient
          .get(url, config)
          .then((res) => {
            itemTotal.value = res.total;
            otherTyperResultList.value = res.results;
          })

    }

    const onPageChanged = (page) => {
      if (resultType.value === StaticTags.TYPE_LIVE_EXAM_RESULT) {
        currentPageForLiveResult.value = page
        currentPage.value = currentPageForLiveResult.value
        getLiveTypeResult()
      } else if (resultType.value === StaticTags.TYPE_OTHER_EXAM_RESULT) {
        currentPageForOtherResult.value = page
        currentPage.value = currentPageForOtherResult.value
        getOtherTypeResult()
      }
    }

    const groupByDate = (items) => {
      const responseListGroup = {}
      items.forEach(item => {
        const date = item.date;

        if (!responseListGroup[date]) {
          responseListGroup[date] = [];
        }

        responseListGroup[date].push(item);
      });
      return responseListGroup
    }

    const goToExamPage = (item) => {
      setExamParams({
        examId: item.id
      })
      setExamInfo({
        id: item.id,
        title: item.topic
      })
      router.push({
        name: StaticTags.ROUTE_RESULT_QUESTION_NAME
      })
    }


    const getStatus = (exam) => {
      let isEnded = exam.ended
      let isParticipated = (exam.result != null || exam.result != undefined)
      let isPass = false
      if (isParticipated) {
        isPass = exam.result.mark >= exam.cut_mark
      }
      if (isEnded == StaticTags.ID_EXAM_RUNNING) {
        isRunningExam.value = true
        isLive.value = true
        if (isParticipated) {
          return "Participated";
        } else {
          return "Running";
        }
      } else if (isEnded == StaticTags.ID_EXAM_FINISHED) {
        return "Finished";
      } else if (isEnded == StaticTags.ID_EXAM_UPCOMING) {
        return "Upcoming";
      } else {
        if (isParticipated) {
          if (isPass) {
            return "Passed";
          } else {
            return "Failed";
          }
        } else {
          return "Archived";
        }
      }
    }

    return {
      getStatus,
      getOtherTypeResult,
      getLiveTypeResult,
      otherTyperResultList,
      liveTypeResultList,
      goToExamPage,
      title,
      onStartExamClicked,
      currentPageForLiveResult,
      currentPageForOtherResult,
      currentPage,
      itemsPerPage,
      itemTotal,
      resultType,
      onPageChanged,
      convertToBanglaDate,
      isResult,
      isOtherResult,
      isLive,
      isSingleCourse,
      isMenuDisabled,
      modalMenuActive,
      modalSyllabusActive,
      selectedExam,
      isShowStartBtn,
      isRunningExam,
      coordinates,
      closeMenuModal,
      showMenuModal,
      closeSyllabusModal,
      showSyllabusModal,
      showStartExamDialog,
    };
  },
});
</script>
  
<style lang="scss" scoped>
.nav {
  gap: 10px;
}
.nav-tabs .nav-link {
  border-radius: 28px;
  background: #F0F5F9;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: white;
  border-radius: 28px;
  background: #000;
}
.nav-tabs {
  border-bottom: white;
}

.exam-list-container {
  display: flex;
  flex-wrap: wrap;
}

.container-child-1 {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.container-child-2 {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.live-exam-box {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .exam-list-container {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
  }
  .container-child-1 {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 10px;
  }
  .container-child-2 {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 10px 0 10px 0;
  }
}


.date-format {
  color: #000;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
}

.selected {
  background-color: rgb(1, 1, 135);
}

.btn-type {
  margin-right: 10px;
}

.no-exam-text {
  position: relative;
  width: 40%;
  text-align: center;
  top: 25%;
  left: 50%;
  padding: 20px;
  transform: translate(-50%, 0);
  opacity: 0.5;
  color: black;
}

</style>