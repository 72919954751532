  import { StaticTags } from '@/static';
  import store from '@/store/index.js';
  import { createCanvas } from 'canvas';
  /**
   * Set localStorage
   */

  export const setStore = (name, content) => {
      if (!name) return;
      if (typeof content !== "string") {
        content = JSON.stringify(content);
      
      }
      return localStorage.setItem(name, content);
      
    };
    /**
     * Get localStorage
     */
    export const getStore = (name) => {
      if (!name) return;
      if (localStorage.getItem(name) != 'undefined')
        if(typeof localStorage.getItem(name) == "string")
        {
          return localStorage.getItem(name)
        }
        else
        {
          return JSON.parse(window.localStorage.getItem(name));
        }
        
      else
        return undefined;
    };
    /**
     * Clear localStorage
     */
    export const removeItem = (name) => {
      if (!name) return;
      return window.localStorage.removeItem(name);
    };
    /**
     * Validate Email address
     */
    export const isValidEmail = (value) => {
      return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(value)
        ? false
        : true;
    };
    
    // calculate offset for getting time for the time
    export const calculateOffset = (val) => {
      if(val)
      {
        return Math.min(val/10.0, 1/0.6)
      }
      else return 1;
    }

    // get the time difference of two time
    export const getTimeDifference = (oldTime, newTime) => {
      return (parseFloat(newTime.getTime())-parseFloat(oldTime))/1000;
    }

    export const isCategoryListExpired = (oldTime, newTime) => {
      var expiaryTime = 24*3600*1  // set expiry time here
      var timeDiff = getTimeDifference(oldTime, newTime)
      if(timeDiff > expiaryTime)
      {
        return true
      }
      else{
        return false
      }
    }

    /**
     * 
     * set all exam information  
     */

    export const setExamInfo = ({id, examType, title, isViewOnly, time, questionNumber,page, isExam, startTime, endTime, examFlowType, isRunningExam}) => {
      let examInfo =  store.getters[StaticTags.GET_EXAM_INFO];
      examInfo.examId = id ? id: examInfo.examId ? examInfo.examId : null 
      examInfo.examTime = time ? time : examInfo.time ? examInfo.time : null
      examInfo.startTime = new Date()
      examInfo.total = questionNumber ? questionNumber : examInfo.total? examInfo.total : null
      examInfo.examType = examType ? examType : examInfo.examType
      examInfo.title = title ? title : examInfo.title
      examInfo.isViewOnly = isViewOnly ? isViewOnly : examInfo.isViewOnly
      examInfo.isExam = isExam ? isExam : examInfo.isExam
      examInfo.page = page ? page : examInfo.page
      examInfo.startTime = startTime ? startTime : examInfo.startTime
      examInfo.endTime = endTime ? endTime : examInfo.endTime
      examInfo.examFlowType = examFlowType ? examFlowType : examInfo.examFlowType
      examInfo.isRunningExam = isRunningExam != null ? isRunningExam : examInfo.isRunningExam
      dispatch({
        id : StaticTags.DISPATCH_ID_EXAM_INFO, 
        payload : examInfo,
      })
    }

    /**
     * set all exam params that need to get exam questions
     * @param {*} topic 
     * @param {*} topicId 
     * @param {*} examId 
     */
    export const setExamParams = ({topic, topicId, examId, page, questionNumber, time}) => {
      let examParams = store.getters[StaticTags.GET_EXAM_PARAMS];
      examParams.topic = topic ? topic : examParams.topic
      examParams.topicId = topicId ? topicId : examParams.topicId
      examParams.examId = examId ? examId : examParams.examId
      examParams.questionNumber = questionNumber ? questionNumber : examParams.questionNumber
      examParams.page = page ? page : examParams.page
      examParams.time = time ? time : examParams.time
      dispatch({
        id : StaticTags.DISPATCH_ID_EXAM_PARAMS,
        payload : examParams
      })
    }

    export const generateImageSrc = (text) => {
      const canvas = createCanvas(200, 50);
      const ctx = canvas.getContext('2d');

      // Set font and size
      ctx.font = '24px Arial';

      // Draw text onto the canvas
      ctx.fillText(text, 10, 30);

      // Convert canvas to a data URL (PNG format)
      return canvas.toDataURL('image/png');
  }

  // export const mergeChabi = (chabi) => {
  //   chabir_first_half = ""
  //   chabir_second_half = ""
    
  // }

  export const processBDNumbers = (number) => {
    const cleanedNumber = number.replace(/\D/g, '');

    const bdPhoneNumberPattern = /^01[3-9]\d{8}$/;
    if (bdPhoneNumberPattern.test(cleanedNumber)) {
      const formattedNumber = `0${cleanedNumber.substr(1, 10)}`;
      return formattedNumber;
    } else {
      return "Invalid BD phone number";
    }
  }


  export const convertToBanglaDate = (englishDate) => {
    const monthsMap = {
      '01': 'জানুয়ারি',
      '02': 'ফেব্রুয়ারি',
      '03': 'মার্চ',
      '04': 'এপ্রিল',
      '05': 'মে',
      '06': 'জুন',
      '07': 'জুলাই',
      '08': 'আগস্ট',
      '09': 'সেপ্টেম্বর',
      '10': 'অক্টোবর',
      '11': 'নভেম্বর',
      '12': 'ডিসেম্বর',
    };
    const daysMap = {
      '0': 'রবিবার',
      '1': 'সোমবার',
      '2': 'মঙ্গলবার',
      '3': 'বুধবার',
      '4': 'বৃহস্পতিবার',
      '5': 'শুক্রবার',
      '6': 'শনিবার',
    };
    const englishToBengaliNumber = (number) => {
      const bengaliNumbers = {
        '0': '০',
        '1': '১',
        '2': '২',
        '3': '৩',
        '4': '৪',
        '5': '৫',
        '6': '৬',
        '7': '৭',
        '8': '৮',
        '9': '৯'
      };

      const englishNumber = String(number);
      const bengaliNumber = englishNumber.split('').map(digit => bengaliNumbers[digit] || digit).join('');

      return bengaliNumber;
    }
    const dateParts = englishDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    const dayOfWeek = new Date(englishDate).getDay();
    const banglaDate = `${englishToBengaliNumber(day)} ${monthsMap[month]} ${englishToBengaliNumber(year)}, ${daysMap[dayOfWeek]}`;
    return banglaDate;
  }

  export const englishToBengaliNumber = (number) => {
    const bengaliNumbers = {
      '0': '০',
      '1': '১',
      '2': '২',
      '3': '৩',
      '4': '৪',
      '5': '৫',
      '6': '৬',
      '7': '৭',
      '8': '৮',
      '9': '৯'
    };

    const englishNumber = String(number);
    const bengaliNumber = englishNumber.split('').map(digit => bengaliNumbers[digit] || digit).join('');

    return bengaliNumber;
  }

  /**
   * 
   * change course visit flow type
   */

  // export const changeFlowType = (id,flowType) => {
  //   dispatch({
  //     id: id,
  //     payload: flowType
  //   })
  // }

    export const dispatch = ({id, payload}) => {
      store.dispatch[id, payload]
    }

    export const courseList = [
      {
        url_text: "bcs-prostuti-6-month",
        image: require("@/assets/ourcourses/six_month.png"),
        title: "বিসিএস প্রস্তুতি ৬ মাস",
        short_description:
            'চাকুরীর বা অনার্স ৩য় বর্ষ বা ফাইনাল ইয়ারের ছাত্র, তবে "বিসিএস প্রস্তুতি ৬ মাস" কোর্সটি আপনার জন্যেই। 👊',
        long_description:
            'একদম নতুন করে বা চাকুরীর পাশাপাশি প্রস্তুতি শুরু করেছেন বা অনার্স ৩য় বর্ষ বা ফাইনাল ইয়ারের ছাত্র, ডিসিশান নিয়েছেন বিসিএস প্রস্তুতির, তবে "বিসিএস প্রস্তুতি ৬ মাস" কোর্সটি আপনার জন্যেই। 👊\n' +
            "\n" +
            "বিসিএস এর প্রস্তুতি কিভাবে শুরু করবেন বুঝে উঠতে পারছেন না কিংবা চাকুরী বা ভার্সিটির পড়াশুনার জন্যে যথেষ্ট সময় পান না 😵? আপনার কথা ভেবেই খুব সুন্দর করে সাজানো হয়েছে সম্পূর্ণ সিলেবাস। যে কেউ প্রতিদিন শুধুমাত্র ২-৩ ঘন্টা পড়েই যেনো প্রতিদিনের পড়া প্রতিদিন শেষ করতে পারে এটি মাথায় রেখেই তৈরি করা হয়েছে সম্পূর্ণ রুটিন যেখানে মাত্র ২৪ সপ্তাহ বা ৬ মাসে শেষ হবে বিসিএস প্রিলির টোটাল সিলেবাস। 💯 💯\n" +
            "\n" +
            "\n" +
            "কাদের জন্য এই কোর্স ?\n" +
            "\n" +
            "১। নতুন করে যারা প্রস্তুতি শুরু করেছেন \n" +
            "২। চাকরীর পাশাপাশি প্রস্তুতি নিচ্ছেন \n" +
            "৩। প্রতিদিন অল্প অল্প করে শেষ করতে চান পুরো সিলেবাস\n" +
            "\n" +
            "কি থাকছে সম্পূর্ণ কোর্সে - \n" +
            "\n" +
            "✅ মোট পরীক্ষাঃ ১৪৪ টি\n" +
            "✅ সময়ঃ ৬ মাস। \n" +
            "✅ পরীক্ষাঃ রবি থেকে শুক্র \n" +
            "✅ সাপ্তাহিক ফাইনালঃ শনিবার  \n" +
            "✅ এটি চক্রাকারে চলতে থাকবে। \n" +
            "✅ রুটিন লিংকঃ \n" +
            "\n" +
            "এই সেকশনে যে কোন একটি সাবজেক্ট এর পরীক্ষা হবে রবি, সোম, মঙ্গল, বুধ এবং বৃহস্পতিবার। এবং শনিবার হবে পুরো সপ্তাহের সিলেবাসের উপর সাপ্তাহিক ফাইনাল। \n" +
            "\n" +
            "কোর্সটি চক্রাকারে চলতে থাকবে। যেকোন দিন থেকেই এই কোর্স শুরু করা যাবে কেননা পরীক্ষা গুলো শেষ হলে তা পুনরায় আবার নতুন করে শুরু হবে। তাই আপনি যখনি শুরু করেন না কেনো টোটাল সিলেবাস ঐ ২৪ সপ্তাহ বা ৬ মাসের ভিতরেই শেষ হয়ে যাবে। \n" +
            "\n" +
            "কী পড়বো, কীভাবে পড়বো, কোথা থেকে পড়বো, কতটুকু পড়বো অনেক সময় আমরা বুঝতে পারি না। এজন্য বিসিএস ক্যাডার দ্বারা এনালাইসিস কোরে তৈরি করা হয়েছে প্রিমিয়াম গাইডলাইন। বিসিএস প্রিলিমিনারি সম্পূর্ণ সিলেবাস টপিক অনুসারে ভেঙে ভেঙে মার্ক ডিস্ট্রিবিউশন সহ কোন কোন বই পড়বেন সেজন্য রেফারেন্স বুক, টপিক অনুসারে বিগত বছরের প্রশ্ন বিশ্লেষণ এবং কোন টপিক থেকে কেমন প্রশ্ন আসে তার পরিসংখ্যান।    \n" +
            "\n" +
            "বিসিএস এর পুরো প্রশ্ন এনালাইসিস এবং গাইডলাইন বিসিএস প্রস্তুতি অ্যাপ এর ‘লেকচার এন্ড নোটস’ এর ‘BCS Guideline’ সেকশনে পেয়ে যাবেন। পরীক্ষার আগে একবার করে গাইডলাইনগুলো দেখে নিবেন। \n" +
            "\n" +
            `গাইডলাইন: <a target="_blank" href="http://cutt.ly/38jNDBb"> cutt.ly/38jNDBb </a>  \n` +
            "\n" +
            "\n" +
            "এছাড়াও চলছে আমাদের আরও ১টি কোর্স ‘বিসিএস প্রস্তুতি (১২০ দিন)’। যেখানে ১২০ দিনে শেষ করা হয় সম্পূর্ণ সিলেবাস । \n" +
            "\n" +
            `অংশ নেয়া যাবে বিসিএস প্রস্তুতি অ্যাপ <a target='_blank' href="https://play.google.com/store/apps/details?id=com.bcsprostuti.tanim.bcsprostuti"> BCS Prostuti</a> অথবা ওয়েবসাইট থেকে <a target="_blank" href="http://www.bcsprostuti.com"> 🌐 </a> \n` +
            "\n" +
            `যেকোন জিজ্ঞাসায় কল করুন - <a href="tel:+8801894442944"> 📞 01894442944 </a> \n` +
            "\n" +
            "এই সম্পুর্ণ সিলেবাস একবার ভাল করে শেষ করলে বিসিএস প্রিলিমিনারীর জন্য আপনি প্রস্তুত। তখন নিয়মিত চর্চা এবং পরীক্ষা দিতে থাকলে একটি কাঙ্ক্ষিত ফলাফল পাওয়া যাবে আশা করি। \n" +
            "\n" +
            "শুভকামনা\n",
      },
      {
        url_text: "bcs-prostuti-120-days",
        image: require("@/assets/ourcourses/three_month.jpg"),
        title: "বিসিএস প্রস্তুতি ১২০ দিন",
        short_description: "বিসিএস প্রস্তুতি ১২০ দিন কোর্সটিটি ডিজাইন করা হয়েছে পুরাতন বা নতুন সবাইকে সবার জন্য",
        long_description: "বিসিএস প্রস্তুতি ১২০ দিন কোর্সটিটি ডিজাইন করা হয়েছে পুরাতন বা নতুন সবাইকে সবার জন্য। টোটাল বিসিএস সিলেবাসকে কয়েকটি ভাগে ভাগ করে প্রতি সপ্তাহে ৪ টি (৩ টি টপিক ভিত্তিক, ১ টি সাপ্তাহিক ফাইনাল) করে পরীক্ষা নিয়ে টোটাল ১৭ সপ্তাহে বা ১২০ দিনে পুরো সিলেবাস কাভার করা হবে। \n" +
            "\n" +
            "কাদের জন্য এই কোর্স ? \n" +
            "\n" +
            "টার্গেট যাদের নেক্সট বিসিএস \n" +
            "ফিক্সড সিলেবাসে শেষ করতে চান পুরো সিলেবাস \n" +
            "পুরাতন বা পড়ার মত ভাল সময় আছে \n" +
            "\n" +
            "কি থাকছে সম্পূর্ণ কোর্সে - \n" +
            "\n" +
            "✅ মোট পরীক্ষাঃ ৬৮ টি\n" +
            "✅ সময়ঃ ১২০ দিন। \n" +
            "✅ পরীক্ষাঃ রবি, মঙ্গল, বৃহস্পতি\n" +
            "✅ সাপ্তাহিক ফাইনালঃ শুক্রবার  \n" +
            "✅ এটি চক্রাকারে চলতে থাকবে। \n" +
            `✅ রুটিনঃ <a target='_blank' href=' https://cutt.ly/vw6AmdWC'>রুটিন </a> \n` +
            "\n" +
            "টপিকভিত্তিক পরীক্ষা গুলো মূলত হয়ে থাকে রবি, মঙ্গল এবং বৃহস্পতিবারে ৩০ মার্ক করে এবং সাপ্তাহিক ফাইনাল হয়ে থাকে প্রতি শুক্রবারে ঐ সপ্তাহের তিন টপিক এর উপর ভিত্তি করে ৫০ মার্কের। কোর্সটি চক্রাকারে চলতে থাকবে। তাই যখনই শুরু করেন না করেন ১২০ দিন পরেই পুরো কোর্স শেষ হয়ে যাবে। \n" +
            "\n" +
            "কী পড়বো, কীভাবে পড়বো, কোথা থেকে পড়বো, কতটুকু পড়বো অনেক সময় আমরা বুঝতে পারি না। এজন্য বিসিএস ক্যাডার দ্বারা এনালাইসিস কোরে তৈরি করা হয়েছে প্রিমিয়াম গাইডলাইন। বিসিএস প্রিলিমিনারি সম্পূর্ণ সিলেবাস টপিক অনুসারে ভেঙে ভেঙে মার্ক ডিস্ট্রিবিউশন সহ\n" +
            "কোন কোন বই পড়বেন সেজন্য রেফারেন্স বুক, টপিক অনুসারে বিগত বছরের প্রশ্ন বিশ্লেষণ এবং কোন টপিক থেকে কেমন প্রশ্ন আসে তার পরিসংখ্যান।    \n" +
            "\n" +
            "বিসিএস এর পুরো প্রশ্ন এনালাইসিস এবং গাইডলাইন বিসিএস প্রস্তুতি অ্যাপ এর ‘লেকচার এন্ড নোটস’ এর ‘BCS Guideline’ সেকশনে পেয়ে যাবেন। পরীক্ষার আগে একবার করে গাইডলাইনগুলো দেখে নিবেন। \n" +
            "\n" +
            `👉 গাইডলাইন: <a target="_blank" href="http://cutt.ly/38jNDBb"> cutt.ly/38jNDBb </a>  \n` +
            "\n" +
            "এছাড়াও একদম নতুন বা চাকুরীর পাশাপাশি প্রস্তুতি নিচ্ছেন তাদের জন্য চলছে আমাদের আরও ১টি কোর্স ‘বিসিএস প্রস্তুতি (৬ মাস)’। যেখানে ছোট ছোট টপিকে ভাগ করে ১৮০ দিন বা ৬ মাসে শেষ করা হয় সম্পূর্ণ সিলেবাস ।  \n" +
            "\n" +
            `অংশ নেয়া যাবে বিসিএস প্রস্তুতি অ্যাপ <a target='_blank' href="https://play.google.com/store/apps/details?id=com.bcsprostuti.tanim.bcsprostuti"> BCS Prostuti</a> অথবা ওয়েবসাইট থেকে <a target="_blank" href="http://www.bcsprostuti.com"> 🌐 </a> \n` +
            "\n" +
            `যেকোন জিজ্ঞাসায় কল করুন - <a href="tel:+8801894442944"> 📞 01894442944 </a> \n` +
            "\n" +
            "এই সম্পুর্ণ সিলেবাস একবার ভাল করে শেষ করলে বিসিএস প্রিলিমিনারীর জন্য আপনি প্রস্তুত। তখন নিয়মিত চর্চা এবং পরীক্ষা দিতে থাকলে একটি কাঙ্ক্ষিত ফলাফল পাওয়া যাবে আশা করি। \n" +
            "\n" +
            "শুভ কামনা ✌️\n",
      },
      {
        url_text: "weekly-model-test",
        image: require("@/assets/ourcourses/weekly_model_test.png"),
        title: "সাপ্তাহিক ফ্রি ২০০ নম্বরের পূর্ণাঙ্গ মডেল টেস্ট",
        short_description: "বিসিএস প্রস্তুতি অ্যাপের সাপ্তাহিক আয়োজন \n" +
            "প্রতি শনিবার সম্পূর্ণ ফ্রিতে ২০০ নম্বরের পূর্ণাঙ্গ মডেল টেস্ট। ",
        long_description: "বিসিএস প্রস্তুতি অ্যাপের সাপ্তাহিক আয়োজন \n" +
            "প্রতি শনিবার সম্পূর্ণ ফ্রিতে ২০০ নম্বরের পূর্ণাঙ্গ মডেল টেস্ট। \n" +
            "সপ্তাহের প্রতিশনিবার কয়েক হাজার পরীক্ষার্থী স্ট্যান্ডার্ড প্রশ্নে নিজেকে যাচাই করে। সকল প্রশ্নের সাথে থাকবে বিস্তারিত ব্যাখ্যা। \n" +
            "\n" +
            "প্রতি শনিবার তাই ক্যালেন্ডারে ডাগিয়ে রাখুন। সবার সাথে নিজেকে যাচাই করুন।\n",
      },
      {
        url_text: "daily-model-test",
        image: require("@/assets/ourcourses/daily_model_test.png"),
        title: "ডেইলি মডেল টেস্ট",
        short_description: "প্রতিদিন বিসিএস ২০০ নম্বরের পূর্ণাঙ্গ পরীক্ষার আনুপাতিকহারে ৫০ নম্বরের মডেল টেস্ট। \n",
        long_description: "প্রতিদিন বিসিএস ২০০ নম্বরের পূর্ণাঙ্গ পরীক্ষার আনুপাতিকহারে ৫০ নম্বরের মডেল টেস্ট। \n" +
            "\n" +
            "বিসিএসের পূর্ণাঙ্গ  মডেল টেস্ট নিজেকে যাচাই করতে চান তাদের জন্য প্রতিদিন এই কোর্স খুবই কর্জকরি ভূমিকা রাখবে। সকল প্রশ্নের সাথে থাকবে বিস্তারিত ব্যাখ্যা। প্রতিদিন স্ট্যান্ডার্ড প্রশ্ন বিসিএসের পুরানো পূর্ণাঙ্গ ঘরে বসে বসে।\n",
      },
      {
        url_text: "bank-prostuti-80-days",
        image: require("@/assets/ourcourses/bank.jpg"),
        title: "ব্যাংক প্রস্তুতি | ৮০ দিন ",
        short_description: " ৮০ দিনের ব্যাংক প্রস্তুতি কোর্সটি সাজানো হয়েছে যারা ব্যাংক এর প্রস্তুতি নিতে চাচ্ছে তাদের জন্য। ",
        long_description: " ৮০ দিনের ব্যাংক প্রস্তুতি কোর্সটি সাজানো হয়েছে যারা ব্যাংক এর প্রস্তুতি নিতে চাচ্ছে তাদের জন্য। টোটাল সিলেবাসকে কয়েকটি ভাগে ভাগ করে প্রতি সপ্তাহে ৪ টি (৩ টি টপিক, ১ টি সাপ্তাহিক) করে পরীক্ষা নিয়ে টোটাল ৮০ দিনে পুরো সিলেবাস কাভার করা হবে। \n" +
            "\n" +
            "✅ মোট পরীক্ষাঃ ৪৪ টি\n" +
            "✅ সময়ঃ ৮০ দিন। \n" +
            "✅ পরীক্ষাঃ সোম, বুধ এবং শুক্র \n" +
            "✅ সাপ্তাহিক ফাইনালঃ শনিবার  \n" +
            "✅ এটি চক্রাকারে চলতে থাকবে। \n" +
            "\n" +
            "টপিক পরীক্ষা পরীক্ষা গুলো মূলত হয়ে থাকে সোম, বুধ এবং শুক্রবারে ৩০ মার্ক করে এবং সাপ্তাহিক পরীক্ষাটি হয়ে থাকে ঐ সপ্তাহের শনিবার তিন টপিক এর উপর ভিত্তি করে ৫০ মার্কের। \n" +
            "কোর্সটি চক্রাকারে চলতে থাকবে। তাই যখনই শুরু করেন না করেন ৮০ দিন পরেই পুরো কোর্স শেষ হবে। \n" +
            "\n" +
            "এই সম্পুর্ণ সিলেবাস একবার ভাল করে শেষ করলে ব্যাংক এর জন্য আপনি প্রস্তুত। তখন নিয়মিত চর্চা এবং পরীক্ষা দিতে থাকলে একটি কাঙ্ক্ষিত ফলাফল পাওয়া যাবে আশা করি। \n" +
            "\n" +
            "শুভ কামনা ✌️\n",
      },
      {
        url_text: "primary-prostuti-80-days",
        image: require("@/assets/ourcourses/primary_80_days.jpg"),
        title: "প্রাইমারি প্রস্তুতি | ৮০ দিন",
        short_description: "৮০ দিনের প্রাইমারি প্রস্তুতি কোর্সটি সাজানো হয়েছে যারা প্রাইমারি এর প্রস্তুতি নিতে চাচ্ছে তাদের জন্য। ",
        long_description: "৮০ দিনের প্রাইমারি প্রস্তুতি কোর্সটি সাজানো হয়েছে যারা প্রাইমারি এর প্রস্তুতি নিতে চাচ্ছে তাদের জন্য। টোটাল সিলেবাসকে কয়েকটি ভাগে ভাগ করে টোটাল ৮০ দিনে পুরো সিলেবাস কাভার করা হবে। \n" +
            "\n" +
            "✅ মোট পরীক্ষাঃ ২৪ টি\n" +
            "✅ সময়ঃ ৮০ দিন। \n" +
            "✅ এটি চক্রাকারে চলতে থাকবে। \n" +
            "\n" +
            "\n" +
            "এই সম্পুর্ণ সিলেবাস একবার ভাল করে শেষ করলে প্রাইমারি এর জন্য আপনি প্রস্তুত। তখন নিয়মিত চর্চা এবং পরীক্ষা দিতে থাকলে একটি কাঙ্ক্ষিত ফলাফল পাওয়া যাবে আশা করি। \n" +
            "\n" +
            "শুভ কামনা ✌️\n",
      },
      {
        url_text: "bank-job-solution",
        image: require("@/assets/ourcourses/bank_job_solution.jpg"),
        title: "ব্যাংক জব সল্যুশন",
        short_description: "ব্যাংক প্রিলি পরীক্ষার বিগত সকল বছরের ব্যাখ্যা সহ প্রশ্নে সাজানো ব্যাংক জব সল্যুশন সেকশন।",
        long_description: "ব্যাংক প্রিলি পরীক্ষার বিগত সকল বছরের ব্যাখ্যা সহ প্রশ্নে সাজানো ব্যাংক জব সল্যুশন সেকশন। \n" +
            "\n" +
            "ব্যাংক প্রিলি পরীক্ষার পরিকল্পিত এবং পরিপূর্ণ প্রস্তুতি শুরু করেতে বিগত বছরের ব্যাংক পরীক্ষার ব্যাখ্যাসহ প্রশ্ন সমাধান হল সবচেয়ে কার্যকরী পদ্ধতি। বিগত বছরের পরীক্ষার প্রশ্নে অনুশীলন এবং এনালাইসিস করলে পরীক্ষার ধরণ এবং প্রশ্নের প্যাটার্ন সম্পর্কে বিস্তারিত বুঝা যায়। \n" +
            "\n" +
            "যারা নতুনভাবে শুরু করছেন তাদের জন্য কিছু টিপসঃ\n" +
            "\n" +
            "➢ প্রথমেই ব্যাংকের বিগত বছরের প্রশ্নগুলো ব্যাখা সহ পড়ে ফেলবেন। তাহলে ব্যাংক প্রশ্ন এবং পড়াশুনা নিয়ে একটা ভালো ধারনা পাবনে। \n" +
            "\n" +
            "➢ অবশ্যই বোর্ড বই কে প্রাধান্য দিয়ে প্রস্তুতি নিন।\n" +
            "\n" +
            "➢ ব্যাংক পরীক্ষা মূলত ইংরেজি এবং গণিতের দক্ষতার উপর নির্ভরশীল। আপনি যত বেশী ইংরেজি ও গণিতে দক্ষ হবেন ব্যাংকে ভালো করার সুযোগ আপনার তত বেশি বেড়ে যাবে। \n" +
            "\n" +
            "➢ সম্পুর্ণ সিলেবাস একবার শেষ করলে আপনি ব্যাংক প্রিলিমিনারীর জন্য প্রস্তুত\n" +
            "\n" +
            "ব্যাংক চাকুরি নিয়ে কিছু গুরুত্বপূর্ণ তথ্য যা জানা খুবই জরুরীঃ\n" +
            "\n" +
            "ব্যাংকের চাকুরির নিয়োগ হয় তিনটি ধাপে-\n" +
            "১. প্রিলিমিনারি \n" +
            "২. লিখিত\n" +
            "৩. ভাইভা\n" +
            "\n" +
            "প্রিলিমিনারিঃ ব্যাংকের চাকুরীতে সবচেয়ে কঠিন ধাপ এটি। প্রিলিমিনারির প্রশ্নের ধরন এক্সাম টেকার অনুযায়ী ভিন্ন হতে পারে। তবে, সম্প্রতি ব্যাংকের সকল পরীক্ষা BIBM নিচ্ছে এবং ধারণা করা যায় যে পরবর্তীতে ব্যাংকের পরীক্ষা গুলো BIBM-ই নিবে। \n" +
            "BIBM এর প্রিলি প্রশ্নের ধরন অনেকটা নিম্নরূপ -\n" +
            "\n" +
            "➢ ৮০/১০০ টি MCQ প্রশ্ন থাকতে পারে। বাংলা- ২০/২৫, ইংরেজি -২০/২৫, গণিত-২০/২৫, সাধারণ জ্ঞান-২০/২৫।  সময় ১ ঘন্টা। নেগেটিভ থাকবে ০.২৫।\n" +
            "\n" +
            "\n" +
            "জব সল্যুশনস যত বেশি এনালাইসিস করবেন পরীক্ষার সম্পর্কে আপনার ধারণা তত স্বচ্ছ হবে। তাহলে আপনি যখন পরবর্তীতে পড়বেন তখন নিজ থেকেই বুঝতে পারবেন কি কি পড়া লাগবে আর কি কি লাগবে না। বিগত বছরের প্রশ্ন এনালাইসিস গুলো ভালোভাবে দেখুন এবং সেই ভাবে বেসিক থেকে পড়াশুনা করার চেষ্টা করুন। যেসব টপিক থেকে বেশী প্রশ্ন হয় সেগুলো খুব ভালো ভাবে গভীর থেকে পড়ুন।\n" +
            "\n" +
            "ব্যাংক প্রস্তুতির জন্য আমাদের অ্যাপ বা ওয়েবসাইট থেকে লাইভ কোর্স গুলো ফলো করুন। মেইন পরীক্ষার আগে চূড়ান্ত মডেল টেস্ট গুলোতে অংশ নিলে মেইন পরীক্ষায় যা খুবই সহায়ক হবে। \n" +
            "\n" +
            "আপনার জন্য শুভ কামনা \n"
      },
      {
        url_text: "bcs-question-bank",
        image: require("@/assets/ourcourses/bcs_question_bank.jpg"),
        title: "বিসিএস প্রশ্ন ব্যাংক",
        short_description: "পরিকল্পিত সমন্বিত প্রস্তুতির মাধ্যমে প্রস্তুতি নিলে বিসিএস পরীক্ষায় ভাল করা সম্ভব।",
        long_description: "পরিকল্পিত সমন্বিত প্রস্তুতির মাধ্যমে প্রস্তুতি নিলে বিসিএস পরীক্ষায় ভাল করা সম্ভব। পরিকল্পিত এবং পরিপূর্ণ প্রস্তুতি শুরু করেতে বিগত বছরের পরীক্ষার ব্যাখ্যাসহ প্রশ্ন সমাধান হল সবচেয়ে কার্যকরী পদ্ধতি। বিগত বছরের পরীক্ষার প্রশ্নে অনুশীলন এবং এনালাইসিস করলে পরীক্ষার ধরণ এবং প্রশ্নের প্যাটার্ন সম্পর্কে বিস্তারিত বুঝা যায়। \n" +
            "\n" +
            "এজন্যই বিগত সকল বছরের বিসিএস পরীক্ষার প্রশ্নে পরীক্ষার সাজানো হয়েছে বিসিএস প্রশ্ন ব্যাংক কোর্স। \n" +
            "যেখানে আপনি অনুশীলন করার পাশাপাশি পরীক্ষা দিয়েও যাচাই করতে পারবেন  নিজেকে। ব্যাখ্যাসহ সমাধান আপনাকে অন্যদের থেকে অনেকটাই এগিয়ে রাখবে। \n" +
            "\n" +
            "সমন্বিত প্রস্তুতির জন্য কিছু পরামর্শঃ \n" +
            "\n" +
            `✅ প্রথমেই  <a href='https://bcsprostuti.com/app' target='_blank'>বিসিএস প্রস্তুতি</a> অ্যাপ থেকে বিসিএস পরীক্ষায় আসা বিগত বছরের প্রশ্নগুলো ব্যাখ্যা সহ পড়ে ফেলুন। এতে করে একটা সামগ্রিক ধারনা পাবেন কি টাইপের প্রশ্ন বিসিএস পরীক্ষায় হয়। তাহলে আপনি যখন পরবর্তীতে পড়বেন তখন নিজ থেকেই বুঝতে পারবেন কি কি পড়া লাগবে আর কি কি লাগবে না।\n` +
            "\n" +
            "✅ বিগত বছরের প্রশ্ন এনালাইসিস গুলো ভালোভাবে দেখুন এবং সেই ভাবে বেসিক থেকে পড়াশুনা করার চেষ্টা করুন। যেসব টপিক থেকে বেশী প্রশ্ন হয় সেগুলো খুব ভালো ভাবে গভীর থেকে পড়ুন।\n" +
            "\n" +
            "✅ আন্তর্জাতিক বিষয়াবলির জন্য PSC নেয়া বিগত বছরের আন্তর্জাতিক বিষয়াবলির সকল প্রশ্ন ব্যাখ্যা সহ পড়ে ফেলুন। এতে প্রস্তুতি খুব ভালো হবে।\n" +
            "\n" +
            `✅ টপিক অনুসারে বেসিক থেকে পড়াশুনা করার চেষ্টা করুন। যে কোন একটি টপিক ধরে খুব ভালমত অনুশীলন করুন। <a href='https://bcsprostuti.com/app' target='_blank'>বিসিএস প্রস্তুতি</a> অ্যাপে টপিক/সাব-টপিক এবং চ্যাপ্টার অনুসারে সাজানো আছে। আর অ্যাপ এর স্মার্ট সিস্টেম ট্র্যাক করবে আপনার প্রোগ্রেস। কি কি পড়ছেন আর কতটুক পড়ছেন: পুরোটাই ট্র্যাক রাখা যাবে। \n` +
            "\n" +
            `✅ অনুশীলন সেকশন পড়া শেষ হলেই <a href='https://bcsprostuti.com/app' target='_blank'>বিসিএস প্রস্তুতি</a> অ্যাপ থেকে যত খুশি তত বিষয়ভিত্তিক পরীক্ষা দিয়ে যাচাই করে নিন নিজের দুর্বলতাগুলো। মনে রাখবেন বিসিএস এ পরীক্ষা দেবার কোন বিকল্প নেই।\n` +
            "\n" +
            `✅ এছাড়াও <a href='https://bcsprostuti.com/app' target='_blank'>বিসিএস প্রস্তুতি</a> অ্যাপে রয়েছে গুরুত্বপূর্ণ ফিচার সার্চ অপশন এবং প্রশ্ন মার্ক অপশন। কোন টপিকে কনফিউশন হলে সার্চ করে পড়ে ফেলুন। সাথে  গুরুত্বপূর্ণ বা ভুল প্রশ্ন গুলো মার্ক করে রাখুন আর বার বার রিভিশন দিন।\n` +
            "\n" +
            `✅ <a href='https://bcsprostuti.com/app' target='_blank'>বিসিএস প্রস্তুতি</a> অ্যাপ এর যে কোন একটি লাইভ কোর্স (যেমন ১২০ দিন বা ৬ মাসের কোর্স) খুব ভালোভাবে ফলো করুন। কোর্স সমূহ চক্রাকারে চলতে থাকে। তাই আপনি যখনি শুরু করেন না কেন একটি নির্দিষ্ট সময় পরেই আপনার কোর্স শেষ হয়ে যাবে। ফাইনাল পরীক্ষার আগের ক্র্যাশ কোর্স এবং চূড়ান্ত মডেল টেস্ট গুলো ফলো করুন। আশা করা যায় বিসিএসের খুব ভালো একটি প্রস্তুতি হয়ে যাবে।\n` +
            "\n" +
            "আপনার জন্য শুভ কামনা \n"
      },
      {
        url_text: "primary-question-bank",
        image: require("@/assets/ourcourses/primary_question_bank.jpg"),
        title: "প্রাইমারি প্রশ্ন ব্যাংক",
        short_description: "প্রাথমিক শিক্ষক নিয়োগ পরীক্ষার বিগত সকল বছরের ব্যাখ্যা সহ প্রশ্নে সাজানো প্রাইমারি প্রশ্ন ব্যাংক কোর্স।",
        long_description: "প্রাথমিক শিক্ষক নিয়োগ পরীক্ষার বিগত সকল বছরের ব্যাখ্যা সহ প্রশ্নে সাজানো প্রাইমারি প্রশ্ন ব্যাংক কোর্স। \n" +
            "\n" +
            "প্রাথমিক শিক্ষক নিয়োগ পরীক্ষার পরিকল্পিত এবং পরিপূর্ণ প্রস্তুতি শুরু করেতে বিগত বছরের প্রাথমিক শিক্ষক নিয়োগ পরীক্ষার ব্যাখ্যাসহ প্রশ্ন সমাধান হল সবচেয়ে কার্যকরী পদ্ধতি। বিগত বছরের পরীক্ষার প্রশ্নে অনুশীলন এবং এনালাইসিস করলে পরীক্ষার ধরণ এবং প্রশ্নের প্যাটার্ন সম্পর্কে বিস্তারিত বুঝা যায়। \n" +
            "\n" +
            "\n" +
            "এজন্যই বিগত সকল বছরের প্রাইমারি নিয়োগ পরীক্ষার প্রশ্নে পরীক্ষার সাজানো হয়েছে প্রাইমারি প্রশ্ন ব্যাংক কোর্স। \n" +
            "যেখানে আপনি অনুশীলন করার পাশাপাশি পরীক্ষা দিয়েও যাচাই করতে পারবেন \n" +
            " নিজেকে। ব্যাখ্যাসহ সমাধান আপনাকে অন্যদের থেকে অনেকটাই এগিয়ে রাখবে। \n" +
            "\n" +
            "জব সল্যুশনস যত বেশি এনালাইসিস করবেন পরীক্ষার সম্পর্কে আপনার ধারণা তত স্বচ্ছ হবে। তাহলে আপনি যখন পরবর্তীতে পড়বেন তখন নিজ থেকেই বুঝতে পারবেন কি কি পড়া লাগবে আর কি কি লাগবে না। বিগত বছরের প্রশ্ন এনালাইসিস গুলো ভালোভাবে দেখুন এবং সেই ভাবে বেসিক থেকে পড়াশুনা করার চেষ্টা করুন। যেসব টপিক থেকে বেশী প্রশ্ন হয় সেগুলো খুব ভালো ভাবে গভীর থেকে পড়ুন।\n" +
            "\n" +
            "প্রাইমারি প্রস্তুতির জন্য আমাদের অ্যাপ বা ওয়েবসাইট থেকে লাইভ কোর্স গুলো ফলো করুন। মেইন পরীক্ষার আগে চূড়ান্ত মডেল টেস্ট গুলোতে অংশ নিলে মেইন পরীক্ষায় যা খুবই সহায়ক হবে। \n" +
            "\n" +
            "আপনার জন্য শুভ কামনা \n"
      },
      {
        url_text: "ntrca-prostuti-70-days",
        image: require("@/assets/ourcourses/ntrca_19.png"),
        title: "শিক্ষক নিবন্ধন (NTRCA) প্রস্তুতি  | ৭০ দিন ",
        short_description: "কোর্সটি সাজানো হয়েছে যারা বছরব্যাপী শিক্ষক নিবন্ধন (NTRCA) এর প্রস্তুতি নিতে চাচ্ছে তাদের জন্য।",
        long_description: "৭০ দিনের শিক্ষক নিবন্ধন (NTRCA) প্রস্তুতি কোর্সটি সাজানো হয়েছে যারা বছরব্যাপী শিক্ষক নিবন্ধন (NTRCA) এর প্রস্তুতি নিতে চাচ্ছে তাদের জন্য। টোটাল সিলেবাসকে কয়েকটি ভাগে ভাগ করে টোটাল ৭০ দিনে পুরো সিলেবাস কাভার করা হবে। কলেজ বা স্কুল পর্যায় উভয় পরীক্ষার জন্যই এই একটি কোর্স ফলো করলেই হবে। \n" +
            "\n" +
            "🔵 মোট পরীক্ষাঃ ২৬ টি\n" +
            "🔵 পরীক্ষা হবে ৩ দিন পর পর। \n" +
            "🔵 সময়ঃ ৭০ দিন\n" +
            `🔵 <a href='https://cutt.ly/deqWfGuu' target='_blank'>রুটিন পিডিএফ</a>\n` +
            "\n" +
            "এই সম্পুর্ণ সিলেবাস একবার ভাল করে শেষ করলে  শিক্ষক নিবন্ধন (NTRCA) এর জন্য আপনি প্রস্তুত। তখন নিয়মিত চর্চা এবং পরীক্ষা দিতে থাকলে একটি কাঙ্ক্ষিত ফলাফল পাওয়া যাবে আশা করি। \n" +
            "\n" +
            "শুভ কামনা ✌️\n"
      },
      {
        url_text: "ntrca-question-bank",
        image: require("@/assets/ourcourses/ntrca_job_solution.jpg"),
        title: "শিক্ষক নিবন্ধন (NTRCA)  প্রশ্ন ব্যাংক",
        short_description: "বেসরকারী স্কুল ও কলেজে শিক্ষক নিয়োগ পরীক্ষার বিগত সকল বছরের ব্যাখ্যা সহ প্রশ্নে সাজানো শিক্ষক নিবন্ধন (NTRCA) প্রশ্ন ব্যাংক কোর্স।",
        long_description: "বেসরকারী স্কুল ও কলেজে শিক্ষক নিয়োগ পরীক্ষার বিগত সকল বছরের ব্যাখ্যা সহ প্রশ্নে সাজানো শিক্ষক নিবন্ধন (NTRCA) প্রশ্ন ব্যাংক কোর্স। \n" +
            "\n" +
            "শিক্ষকতাকে পেশা হিসেবে পেতে চান তাদের জন্য পরিকল্পিত এবং পরিপূর্ণ প্রস্তুতি শুরু করেতে বিগত বছরের শিক্ষক নিয়োগ পরীক্ষার ব্যাখ্যাসহ প্রশ্ন সমাধান হল সবচেয়ে কার্যকরী পদ্ধতি। বিগত বছরের পরীক্ষার প্রশ্নে অনুশীলন এবং এনালাইসিস করলে পরীক্ষার ধরণ এবং প্রশ্নের প্যাটার্ন সম্পর্কে বিস্তারিত বুঝা যায়।  বিগত বছরের প্রশ্নব্যাংক থেকে NTRCA সিলেবাস সম্পর্কেও স্বচ্ছ ধারণা পাবেন। \n" +
            "\n" +
            "বিগত বছরের স্কুল এবং কলেজ পর্যায়ে শিক্ষক নিয়োগ পরীক্ষার প্রশ্নব্যাংক থেকে আপনি অনুশীলন করার পাশাপাশি পরীক্ষা দিয়েও যাচাই করতে পারবেন  নিজেকে। ব্যাখ্যাসহ সমাধান আপনাকে অন্যদের থেকে অনেকটাই এগিয়ে রাখবে। \n" +
            "\n" +
            "আমাদের অ্যাপ বা ওয়েবসাইট থেকে লাইভ কোর্স গুলো ফলো করুন। মেইন পরীক্ষার আগে চূড়ান্ত মডেল টেস্ট গুলোতে অংশ নিলে মেইন পরীক্ষায় যা খুবই সহায়ক হবে। \n" +
            "\n" +
            "আপনার জন্য শুভ কামনা\n"
      },
      {
        url_text: "job-solution-all",
        image: require("@/assets/ourcourses/job_solution.jpg"),
        title: "সকল জব সল্যুশন ",
        short_description: "ব্যাখ্যাসহ প্রতিদিন আপডেটেড জব সল্যুশন  \n" +
            "মাসে মাসে জব সল্যুশন কেনার দিন শেষ \n",
        long_description: "ব্যাখ্যাসহ প্রতিদিন আপডেটেড জব সল্যুশন  \n" +
            "মাসে মাসে জব সল্যুশন কেনার দিন শেষ \n" +
            "\n" +
            "আমাদের ব্যাখ্যাসহ সল্যুশন আপনার প্রস্তুতি আপনাকে কয়েক ধাপ এগিয়ে রাখবে। \n" +
            "\n" +
            "জব সল্যুশনস যত বেশি এনালাইসিস করবেন পরীক্ষার সম্পর্কে আপনার ধারণা তত স্বচ্ছ হবে। তাহলে আপনি যখন পরবর্তীতে পড়বেন তখন নিজ থেকেই বুঝতে পারবেন কি কি পড়া লাগবে আর কি কি লাগবে না। বিগত বছরের প্রশ্ন এনালাইসিস গুলো ভালোভাবে দেখুন এবং সেই ভাবে বেসিক থেকে পড়াশুনা করার চেষ্টা করুন। যেসব টপিক থেকে বেশী প্রশ্ন হয় সেগুলো খুব ভালো ভাবে গভীর থেকে পড়ুন।\n" +
            "\n" +
            "প্রতি সপ্তাহেই থাকে চাকরীর পরীক্ষা থাকেই। \n" +
            "\n" +
            "কিছুদিন পরপরই নতুন নতুন সব এক্সাম আর সেই সাথে বারবার আপটুডেট জব সল্যুশন কেনাও একটা ঝামেলা। \n" +
            "\n" +
            "আর আপনাকে সেই ঝামেলা থেকে মুক্তি দিতেই আছে বিসিএস প্রস্তুতি অ্যাপ। এখন পরীক্ষার পরপরই সকল সরকারি চাকরির প্রশ্নপত্রের ব্যাখ্যাসহ সমাধান পাচ্ছেন বিসিএস প্রস্তুতি অ্যাপে। 💥\n" +
            "\n" +
            "এতে করে দ্রুততম সময়ে যেমন নির্ভুল ব্যাখ্যা সহ সকল কোশ্চেন আপনি পেয়ে যাচ্ছেন এক অ্যাপেই গোছানো অবস্থায়। সেই সাথে বারবার আপডেটেড জব সল্যুশন কেনার ঝামেলাও থাকছে না। ✅\n" +
            "\n" +
            "জব প্রিপারেশনের স্মার্ট সল্যুশন – বিসিএস প্রস্তুতি অ্যাপ। 💯\n"
      },
      // {
      //   url_text: "",
      //   image: require("@/assets/ourcourses/"),
      //   title: "",
      //   short_description: "",
      //   long_description: ""
      // }
    ];

    export const getCourseDetails = (url_text) => {
      return courseList.find(course => course.url_text === url_text)
    }

    export const blogList = [
      {
        url_text: "bcs-prostuti-5-lakhs-family",
        title: "বিসিএস প্রস্তুতির ৫ লাখ-এর বিশাল পরিবারে আপনাকে স্বাগতম!\n",
        image: require("@/assets/blogs/five_lack.png"),
        short_description:
            "কয়েকজন ইঞ্জিনিয়ারের হাত ধরে যাত্রা শুরু হয়েছিলো বিসিএস প্রস্তুতি অ্যাপের। ",
        long_description: "দিনটা ছিলো ২৬ মে, ২০১৮। নিছক খেয়ালের বসেই বিসিএস পরীক্ষার প্রিলিমিনারি প্রস্তুতির জন্য একটা অ্যাপ তৈরির চিন্তা থেকে; কয়েকজন ইঞ্জিনিয়ারের হাত ধরে যাত্রা শুরু হয়েছিলো বিসিএস প্রস্তুতি অ্যাপের। এরপর দেখতে দেখতেই পেরিয়ে গেলো প্রায় ছয়টা বছর। সেই দিনের ছোট্ট বিসিএস প্রস্তুতি অ্যাপ এখন ৫ লাখ সদস্যের এক বিশাল পরিবার। দীর্ঘ এই পথযাত্রায় যারা যেভাবেই বিসিএস প্রস্তুতির পাশে ছিলেন না কেন, প্রত্যেককে জানাই আন্তরিক ধন্যবাদ এবং কৃতজ্ঞতা। ♥️\n" +
            "\n" +
            "আমরা প্রত্যেকেই জানি বিসিএস ঠিক কতোটা প্রতিযোগিতামূলক একটি পরীক্ষা। সেই শুরুর সময়টা থেকেই বিসিএস প্রস্তুতি চেয়েছে ঠিক কীভাবে চাকুরি প্রত্যাশীদের এই জার্নিটাকে সহজ করা যায়। দূর-দূরান্তের লাখো চাকরিপ্রত্যাশীকে যেন কয়েকশ মাইল পাড়ি দিয়ে ঢাকা পাড়ি জমাতে না হয় কেবলমাত্র কোচিং আর মডেল টেস্টের জন্য। বরং আমরা সবসময়ই চেয়েছি কীভাবে বিসিএস প্রিলিমিনারির প্রস্তুতিটাকে ডিজিটালাইজড করে আপনার দোরগোড়ায় নিয়ে যাওয়া যায়। সেই চেষ্টা থেকেই নামমাত্র কিছু মূল্যে বিভিন্ন প্যাকেজের মাধ্যমে আমরা আপনাদের চাকুরি প্রস্তুতিতে পাশে আছি সবসময়।\n" +
            "\n" +
            "আপনাদের প্রয়োজন আর দাবির কথা মাথায় রেখেই বিসিএস ছাড়াও প্রাইমারি শিক্ষক নিয়োগ, নিবন্ধন পরীক্ষা সহ অন্যান্য বিভিন্ন সরকারি চাকুরির প্রশ্ন, গাইডলাইন সহ সবই যুক্ত হয়েছে বিসিএস প্রস্তুতি অ্যাপে। আমরা সবসময়ই চেষ্টা করছি কীভাবে চাকুরি প্রস্তুতিকে আরও আকর্ষণীয়, সহজ আর যুগোপযোগী করে আপনাদের সামনে উপস্থিত করা যায়।  \n" +
            "\n" +
            "৫ লক্ষ বার বিসিএস প্রস্তুতি অ্যাপ ডাউনলোড দিনশেষে নিছকই আমাদের কাছে একটি সংখ্যামাত্র। বিসিএস প্রস্তুতি অ্যাপের মাধ্যমে যদি একজন চাকুরি প্রত্যাশীরও জীবন সহজ করা সম্ভব হয়, তবে সেটিই হবে আমাদের কাঙ্খিত অর্জন। এখানেই থেমে থাকা নয়। আপনাদের সকলকে পাশে নিয়েই বিসিএস প্রস্তুতি অ্যাপ পাড়ি দিতে চায় সামনের আরও অনেকটা পথ। দীর্ঘ এই পথযাত্রায় আমাদের প্রতি ভরসা রাখায় আপনার প্রতি আন্তরিক কৃতজ্ঞতা ও ধন্যবাদ।\n",
      },
      {
        url_text: "bcs-prostuti-130-plus-in-first-bcs-preli",
        title: "প্রথম বিসিএস প্রিলিতেই পাবেন ১৩০+",
        image: require("@/assets/blogs/prili_prostuti.jpg"),
        short_description: "সঠিক স্ট্র‍্যাটিজিতে প্রস্তুতি নিলে প্রথম বিসিএস প্রিলিতেই পাবেন ১৩০+ পাওয়া সম্ভব। 🤔",
        long_description: "সঠিক স্ট্র‍্যাটিজিতে প্রস্তুতি নিলে প্রথম বিসিএস প্রিলিতেই পাবেন ১৩০+ পাওয়া সম্ভব। 🤔\n" +
            "একটা সময় অত্যন্ত প্রচলিত ধারণা ছিলো প্রথম বারেই বিসিএস ক্যাডার হওয়া যায় না কিংবা কাঙ্খিত ক্যাডার পাওয়া যায় না। বর্তমানে সেই ধারণা পুরোপুরি বদলে গেছে। বর্তমানের প্রতিটি বিসিএসেই প্রথমবারের পরীক্ষার্থীদেরই ক্যাডার পাওয়ার সংখ্যাটা বেশি। এমনি পররাষ্ট্র, প্রশাসন, পুলিশের মতো ক্যাডারগুলোতে যারা প্রথম হচ্ছেন তাদের অনেকের ক্ষেত্রেই সেটি জীবনের প্রথম বিসিএস। \n" +
            "\n" +
            "সঠিক স্ট্র‍্যাটেজি আর গাইডলাইন ফলো করে পুরো বছরব্যাপী একটি গোছানো প্রস্তুতি নিয়ে ৪৭ বিসিএসে বসলে বাজিমাত করা সম্ভব প্রথমবারেই। আর আপনার এই নতুন পথচলাকে যতোটা সম্ভব সহজ ও মসৃণ করে তুলতে পাশে আছে বিসিএস প্রস্তুতি। ✊\n" +
            "\n" +
            "৪৭ বিসিএস-এর গোছানো প্রস্তুতিতে ৫মে থেকে শুরু হচ্ছে \"বিসিএস প্রস্তুতি (১২০ দিন)\" এবং ১১ মে থেকে শুরু হচ্ছে \"বিসিএস প্রস্তুতি (৬ মাস)\" কোর্সের নতুন সাইকেল। 💯 💯\n" +
            "\n" +
            "আপনার বছরব্যাপী প্রস্তুতিকে সহজ করতে আগামী ৪মে পর্যন্ত বিসিএস প্রস্তুতির প্রিমিয়াম প্যাকেজে থাকছে ৩০% পর্যন্ত স্পেশাল ডিস্কাউন্ট অফার। 💥\n" +
            "\n" +
            "যেখানে এক্সপার্ট মেন্টরদের গাইডলাইনের পাশাপাশি থাকছে  নিয়মিত ডেইলি, উইকলি, মান্থলি টেস্ট। সেই সাথে পাচ্ছেন সকল ধরনের নিয়োগ পরীক্ষার প্রশ্নের ইন্সট্যান্ট ব্যাখ্যা-সহ সল্যুশন, বিষয়ভিত্তিক গুরুত্বপূর্ণ টপিক অনুযায়ী প্রিপারেশন সহ অসংখ্য ইউনিক ফিচার। ⚡\n" +
            "\n" +
            `এ জন্য ডাউনলোড করুন বিসিএস প্রস্তুতি অ্যাপ - <a target='_blank' href="https://play.google.com/store/apps/details?id=com.bcsprostuti.tanim.bcsprostuti"> BCS Prostuti</a>\n` +
            "\n" +
            `অথবা ভিজিট করুন বিসিএস প্রস্তুতি ওয়েবসাইট - <a target="_blank" href="http://www.bcsprostuti.com"> 🌐 </a>\n` +
            "\n" +
            `যেকোন জিজ্ঞাসায় কল করুন -<a href="tel:+8801894442944"> 📞 01894442944 </a> \n` +
            "\n" +
            "সঠিক স্ট্র‍্যাটেজি আর গাইডলাইনে প্রথমবারেই একটি সলিড প্রিপারেশন নিন। কাঙ্খিত স্বপ্ন ধরা দিবে ৪৭ বিসিএসেই ✊\n",
      },
      {
        url_text: "bcs-prostuti-golden-rule-of-success",
        title: "সাফল্যের একমাত্র Golden Rule",
        image: require("@/assets/blogs/success.jpg"),
        short_description: "Failure is only the opportunity to begin again, this time more intelligently.",
        long_description: "বিশ্বখ্যাত মোটর কোম্পানি Ford Motors এর প্রতিষ্ঠাতা Henry Ford বলেছিলেন, “Failure is only the opportunity to begin again, this time more intelligently.”\n" +
            "\n" +
            "ফোর্ডের উক্তিতেই লুকিয়ে আছে সাফল্যের একমাত্র Golden Rule – ব্যর্থতায় মুষড়ে না পড়ে, নতুন করে নতুন উদ্যমে শুরু করা। ✅\n" +
            "\n" +
            "আর তাই আপনারও উচিত ৪৬-এর ব্যর্থতা ভুলে দ্রুত ৪৭ বিসিএস এর জন্য জোরদার প্রস্তুতি শুরু করা এখন থেকেই। ৪৬ এর ভুলগুলো পুনরাবৃত্তি না করলে আর সঠিক স্ট্র‍্যাটেজি আর গাইডলাইন মেনে বছরব্যাপী সলিড একটা প্রিপারেশন নিলে ৪৭ বিসিএসে আপনার সাফল্য আসবেই। 🌟\n" +
            "\n" +
            "আপনার ৪৭ বিসিএস জার্নিকে সহজ আর গুছানো করে তুলতে বিসিএস প্রস্তুতি দিচ্ছে আগামী ৪মে পর্যন্ত প্রিমিয়াম প্যাকেজে ৩০% পর্যন্ত ডিস্কাউন্ট অফার। যেখানে বিসিএস প্রস্তুতির এক্সপার্ট মেন্টররা আপনাকে গাইড করবে ৪৭ বিসিএস-এর সম্পূর্ণ জার্নি জুড়েই। অগোছালো প্রস্তুতি থেকে বেরিয়ে এসে বছর জুড়ে এক্সপার্টদের গাইডলাইনে একটি সলিড প্রিপারেশন নিন, ৪৭-এ আপনার স্বপ্ন পূরণ হবেই। 💯\n" +
            "\n" +
            `এ জন্য ডাউনলোড করুন বিসিএস প্রস্তুতি অ্যাপ - <a target='_blank' href="https://play.google.com/store/apps/details?id=com.bcsprostuti.tanim.bcsprostuti"> BCS Prostuti\n</a>` +
            "\n" +
            `অথবা ভিজিট করুন বিসিএস প্রস্তুতি ওয়েবসাইট - <a target="_blank" href="http://www.bcsprostuti.com"> 🌐 </a>\n` +
            "\n" +
            `যেকোন জিজ্ঞাসায় কল করুন -<a href="tel:+8801894442944"> 📞 01894442944 </a> \n` +
            "\n" +
            "\n" +
            "তাহলে আর দেরি কেন? ডিস্কাউন্ট অফারে প্যাকেজ Enroll করে; ৪৭-এর বেস্ট প্রস্তুতিটা শুরু হোক আজ থেকেই। ⬇️\n",
      },
      {
        url_text: "bcs-prostuti-smart-solution-for-job-preparation",
        title: "জব প্রিপারেশনের স্মার্ট সল্যুশন – বিসিএস প্রস্তুতি অ্যাপ",
        image: require("@/assets/blogs/jobsolution.png"),
        short_description: "এক অ্যাপেই পাচ্ছেন রেগুলার আপডেটেড\n" +
            "সকল সরকারি চাকরির কমপ্লিট জব সল্যুশন\n",
        long_description: "এক অ্যাপেই পাচ্ছেন রেগুলার আপডেটেড\n" +
            "সকল সরকারি চাকরির কমপ্লিট জব সল্যুশন\n" +
            "\n" +
            "\n" +
            "কিছুদিন পরপরই নতুন নতুন সব এক্সাম আর সেই সাথে বারবার আপটুডেট জব সল্যুশন কেনার ঝামেলা। \n" +
            "\n" +
            "আর আপনাকে সেই ঝামেলা থেকে মুক্তি দিতেই আছে বিসিএস প্রস্তুতি অ্যাপ। এখন পরীক্ষার পরপরই সকল সরকারি চাকরির প্রশ্নপত্রের নির্ভুল ব্যাখ্যাসহ সমাধান পাচ্ছেন বিসিএস প্রস্তুতি অ্যাপে। 💥\n" +
            "\n" +
            "এতে করে দ্রুততম সময়ে যেমন নির্ভুল ব্যাখ্যা সহ সকল কোশ্চেন আপনি পেয়ে যাচ্ছেন এক অ্যাপেই গোছানো অবস্থায়। সেই সাথে বারবার আপডেটেড জব সল্যুশন কেনার ঝামেলাও থাকছে না। ✅\n" +
            "\n" +
            "বিসিএস প্রস্তুতি অ্যাপে আপনি পাচ্ছেন:\n" +
            "\n" +
            "- সরকারি চাকরির পরীক্ষার সকল প্রশ্ন\n" +
            "- প্রতিটি প্রশ্নের ব্যাখ্যাসহ সমাধান\n" +
            "- পরীক্ষার পর দ্রুততম সময়ে কোশ্চেন আপলোড\n" +
            "- বারবার জব সল্যুশন কেনা থেকে মুক্তি\n" +
            "\n" +
            "জব প্রিপারেশনের স্মার্ট সল্যুশন – বিসিএস প্রস্তুতি অ্যাপ। 💯\n",
      }
    ];

    export const getBlogDetails = (url_text) => {
      return blogList.find(blog => blog.url_text === url_text)
    }

    /**
     * check exam time expired or not
     * @param {*} oldTime 
     * @param {*} newTime 
     * @returns 
     */
    export const isExamTimeExpired = (oldTime, newTime) => {
      var expiaryTime = 24*3600*1  // set expiry time here
      var timeDiff = getTimeDifference(oldTime, newTime)
      if(timeDiff > expiaryTime)
      {
        return false
      }
      else{
        return true
      }
    }

    export const firebaseConfig = {
      apiKey: "AIzaSyCN41gRLrtfYlPtE_r4qL8Ce7YwlES-SUc",
      authDomain: "bcs-prostuti-new.firebaseapp.com",
      databaseURL: "https://bcs-prostuti-new.firebaseio.com",
      projectId: "bcs-prostuti-new",
      storageBucket: "bcs-prostuti-new.appspot.com",
      messagingSenderId: "634940306772",
      appId: "1:634940306772:web:ab62d124243fc0362ec4c1",
      measurementId: "G-RW1BM0BQ37"
    }

    export const vapidKey = "BExkB7aXTevWoxjHpaC15G59xCaxGWVAJQpZl1qnqrcBeBeqOqfyrNRNHCqYfxJvdLbshT7imHYyNAUcw1y8aRk"
