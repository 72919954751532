<template>
  <!-- Start Hero Area -->
  <section id="home" class="hero-area">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-12">
          <div class="hero-content">
            <h1 class="wow fadeInLeft" data-wow-delay=".4s">বিসিএস প্রস্তুতি <br>স্মার্ট প্রস্তুতির প্রতিশ্রুতি </h1>
            <p class="wow fadeInLeft" data-wow-delay=".6s">বিসিএস, ব্যাংক, প্রাইমারি, শিক্ষক নিবন্ধন (NTRCA), সহাকারী জজ সহ সকল  চাকরির প্রস্তুতি হবে এক অ্যাপেই।</p>
            <div class="buttons wow fadeInLeft" data-wow-delay=".8s">
              <button class="btn btn-primary app-download-btn">
                <a style="margin-right: 20px" href="javascript:void(0)" onclick="window.open('https://play.google.com/store/apps/details?id=com.bcsprostuti.tanim.bcsprostuti&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1', '_blank')">অ্যাপ ডাউনলোড</a>
                <svg
                    class="archived-button-arrow"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="top: 4px; cursor: pointer"
                >
                  <path
                      d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"
                      fill="#fff"
                  />
                </svg>
              </button>

              <button class="btn btn-outline-secondary app-call-btn" style=" line-height: 15px;">
                <a href="tel:+8801894442944">
                  <span>আমাদের কল করুন</span><br>
                  <span style="font-size: 15px">০১৮৯৪৪৪২৯৪৪</span>
                </a>
              </button>

            </div>
            <div v-if="!fromFooter" class="wow fadeInLeft">
              <div class="profile-images">
                <img :src="require('@/assets/heroimages/user3.png')" alt="" width="48" height="48" style="border-radius: 50%" />
                <img :src="require('@/assets/heroimages/user2.png')" alt="" width="48" height="48" style="border-radius: 50%;     margin-left: -12px;" />
                <img :src="require('@/assets/heroimages/user1.png')" alt="" width="48" height="48" style="border-radius: 50%;    margin-left: -12px;" />
                <span class="total-users" style="margin-left: 10px"> ৫ লাখ+ অ্যাপ ডাউনলোড </span>
              </div>


            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-12">
          <div class="hero-image wow fadeInRight" data-wow-delay=".4s">
            <img class="hero-image-one" :src="require('@/assets/src.png')" alt="#">
            <img class="hero-image-two" :src="require('@/assets/src2.png')" alt="#">
          </div>
        </div>
      </div>
    </div>

<!--    <div class="container-fluid">-->
<!--      -->
<!--    </div>-->
  </section>
  <!-- End Hero Area -->
</template>
<script>
import {defineComponent} from "vue";


export default defineComponent({
  props: ['fromFooter'],
  components: {},
})
</script>
<style scoped>
.hero-image-one {
  width: 290px;
  height: 580px;
  border-radius: 24px;
  position: relative;
  bottom: -94px;
  z-index: 999;
  -o-object-fit: cover;
  object-fit: cover;
}
.hero-image-two {
  width: 250px;
  height: 530px;
  border-radius: 24px;
  position: relative;
  bottom: -140px;
  left: -28px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: bottom;
  object-position: bottom;
}

.hero-area .hero-image {
  overflow: hidden;
  text-align: end;
}

.total-users {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #FAFAFA;


}
.profile-images {
  margin-top: 40px;

}

.hero-area {
  position: relative;
  background: #191B47;
  height: 580px;
  display: flex;

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area {
    padding: 150px 0 60px 0;
  }
}

@media (max-width: 767px) {
  .hero-area {
    padding: 50px 0 50px 0;
  }

  .hero-area .hero-image {
    display: none;
  }
}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-image {
    margin-top: 40px;
  }
}

.hero-area .hero-content {
  border-radius: 0;
  position: relative;
  z-index: 1;
  padding-top: 80px;
  text-align: left;
}

.hero-area .hero-content h1 {
  font-family: Noto Sans;
  font-size: 45px;
  font-weight: 700;
  line-height: 55px;
  color: #fff;
  text-shadow: 0px 3px 8px #00000017;
  text-transform: capitalize;
}

.hero-area .hero-content h1 span {
  display: block;
}

.hero-area .hero-content p {
  margin-top: 30px;
  color: #FAFAFA;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.5px;
}

.hero-area .hero-content .buttons {
  margin-top: 40px;
}

.app-download-btn {
  width: 183px;
  height: 54px;
  border-radius: 8px;
  color: white;
  margin-right: 20px;
}

.app-download-btn a {
  color: white;
}

.app-call-btn {
  width: 166px;
  height: 54px;
  border-radius: 8px;
}

.app-call-btn a {
  color: white;
}

@media (max-width: 767px) {
  .hero-area .hero-content .buttons .video-button {
    margin-top: 20px;
  }
}

.hero-area .hero-content .buttons .video-button .text {
  display: inline-block;
  margin-left: 15px;
  color: #fff;
  font-weight: 500;
}

.hero-area .hero-content .buttons .video-button:hover .video {
  color: #fff;
  background-color: #081828;
}

.hero-area .hero-content .buttons .video-button .video {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  color: #191B47;
  background-color: #fff;
  border-radius: 50%;
  margin-left: 10px;
  font-size: 16px;
  padding-left: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: relative;
}

.hero-area .hero-content .buttons .video-button .video:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-animation: pulse-border-2 2s linear infinite;
  animation: pulse-border-2 2s linear infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-content {
    text-align: center;
  }
  .hero-area .hero-content h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
  .hero-area .hero-content p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .hero-area .hero-content {
    padding: 0 10px;
    text-align: center;
  }
  .hero-area .hero-content h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .hero-area .hero-content p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
  }
  .hero-area .hero-content .buttons .btn {
    width: 60%;
    margin: 0;
    margin-bottom: 7px;
  }
  .hero-area .hero-content .buttons .btn:last-child {
    margin: 0;
  }
}

</style>