<template>
  <SyllabusDialog @close="closeSyllabusModal" :modalSyllabusActive="modalSyllabusActive"
                  v-model:exam="selectedExam" @startExam="onExamStartClicked" :isStartBtn="isShowStartBtn">

  </SyllabusDialog>
  <!--      Header section start -->
  <Header/>
  <!--      Header section end -->
  <SubMenu :title="title"></SubMenu>

  <div class="container-fluid">
    <div class=" mt-3 exam-section-container">
      <div class="exam-section-header">
        <h2 class="exam-section-title text-left"> লাইভ এক্সাম </h2>
      </div>
      <div class="exam-section-content">

        <div v-for="(item, index) in routineList" :key="index" >
          <CourseExamDetails
              :item="item"
              :isLive="true"
              :hideBtn="true"
              :isHome="true"
              @syllabusModal="syllabusModal"
              @startExamDialog="startExamDialog"
          >
          </CourseExamDetails>
        </div>
      </div>

    </div>
    <!--      Archive section start -->
    <div class="m-3 archived-section-container">
      <RoutineArchived/>
    </div>
    <!--      Archive section end -->
  </div>

  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer/>
    </div>
    <!--      Footer section end -->
  </div>

</template>
<script>
import {defineComponent, onMounted, ref} from "vue";
import Header from "@/components/NewHeaderView.vue";
import SubMenu from "@/components/SubMenu.vue";
import Footer from "@/components/FooterView.vue";
import store from "@/store";
import RoutineArchived from "@/components/RoutineArchived.vue";
import httpClient from "@/config/httpClient";
import CourseExamDetails from "@/components/CourseExamDetails.vue";
import SyllabusDialog from "@/components/ExamList/SyllabusDialog.vue";
import {StaticTags, URLs} from "@/static";
import {setExamInfo, setExamParams} from "@/config/utils";
import router from "@/router";

export default defineComponent({
  components: {SyllabusDialog, CourseExamDetails, RoutineArchived, Footer, SubMenu, Header},

  setup() {
    const title = ref(null);
    const routineList = ref([]);
    const modalMenuActive = ref(false);
    const modalSyllabusActive = ref(false)
    const selectedExam = ref(null)
    const isShowStartBtn = ref(false)
    const isRunningExam = ref(false)

    onMounted(() => {
      title.value = store.state.titleBar ? store.state.titleBar : "লাইভ এক্সাম "
      getLiveExamList()
    });

    const syllabusModal = (exam) => {
      isShowStartBtn.value = false
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam
    }
    const closeSyllabusModal = () => {
      modalSyllabusActive.value = false
    }

    const startExamDialog = (exam) => {
      isShowStartBtn.value = true
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam

      if (exam.ended == StaticTags.ID_EXAM_RUNNING) {
        isRunningExam.value = true
      }
    }

    const onExamStartClicked = () => {
      setExamParams({
        examId: selectedExam.value.id
      })
      setExamInfo({
        id: selectedExam.value.id,
        title: selectedExam.value.title,
        examFlowType: StaticTags.TYPE_COURSE_EXAM,
        isRunningExam: isRunningExam.value,
      })
      router.push({name: URLs.ROUTE_EXAM_NAME})
    }


    const getLiveExamList = async () => {
      const config = {
        params: {
          "running": 1,
          "upcoming": 0,
          "previous": 0,
          "own": 0
        },
      };

      const url = process.env.VUE_APP_API_BASE_URL + "/exams";

      await httpClient
          .get(url, config)
          .then((res) => {
            routineList.value = res.exams
          })
    }


    return {
      title,
      routineList,
      closeSyllabusModal,
      isShowStartBtn,
      modalSyllabusActive,
      selectedExam,
      isRunningExam,
      modalMenuActive,
      syllabusModal,
      startExamDialog,
      onExamStartClicked,
    }
  }
})
</script>
<style scoped>
.exam-section-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

@media (min-width: 768px) {
  .exam-section-content {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
}
</style>