<template>
  <!--      Header section start -->
  <Header/>
  <!--      Header section end -->
  <!--  Exam Header start-->

  <SubMenu :title="title"></SubMenu>

  <div class="container-fluid ">
    <div class="row content-center">
      <div class="col-lg-2 col-md-2 col-sm-2 "></div>
      <div class="col-lg-8 col-md-8 col-sm-8 mt-3 px-0" id="packages-section">
        <div class="package-box-list">
          <div class="p-2" v-for="pack in packages" :key="pack.id">
<!--            <div class="modal fade" :id="'details-modal-' + pack.id" data-bs-backdrop="static" data-bs-keyboard="false"-->
<!--                 tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" @hide="resetPromoCode">-->
<!--              <div class="modal-dialog">-->
<!--                <div class="modal-content">-->
<!--                  <div class="modal-header">-->
<!--                    <h5 class="modal-title package-box-title" id="staticBackdropLabel">{{ pack.name }}</h5>-->
<!--                  </div>-->
<!--                  <div class="modal-body">-->
<!--                    <div class="col-lg-12 col-md-12 col-sm-12">-->
<!--                      <p style="text-align: left">{{ pack.short_description }}</p>-->
<!--                      <div class="d-block text-left">-->
<!--                        <h5 style="text-align: left; font-weight: 600">ফুল প্যাকেজ যা যা থাকছে</h5>-->
<!--                        &lt;!&ndash;                        <p style="white-space: pre-line">{{ pack.long_description }}</p>&ndash;&gt;-->
<!--                        <p style="white-space: pre-line">-->
<!--                          {{-->
<!--                            showFullDescription ? pack.long_description : pack.long_description.substr(0, 170) + '...'-->
<!--                          }}<a @click="toggleDescription" style="cursor: pointer; color: #005cbf">-->
<!--                          {{ showFullDescription ? '  ...বন্ধ করুন' : 'আরও দেখুন' }}-->
<!--                        </a>-->
<!--                        </p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div v-if="!showPromoInput" class="col-lg-12 col-md-12 col-sm-12 p-2 text-justify">-->
<!--                      <span  @click="showPromoInput = true" style="cursor: pointer; color: #0095FF">-->
<!--                        <svg _ngcontent-serverApp-c87="" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-serverApp-c87="" d="M3.67063 12.9386L7.44563 16.7136C8.99563 18.2636 11.5123 18.2636 13.0706 16.7136L16.729 13.0553C18.279 11.5053 18.279 8.98864 16.729 7.43031L12.9456 3.66364C12.154 2.87197 11.0623 2.44697 9.94563 2.50531L5.77896 2.70531C4.1123 2.78031 2.7873 4.10531 2.70396 5.76364L2.50396 9.93031C2.45396 11.0553 2.87896 12.147 3.67063 12.9386Z" stroke="currentColor" stroke-width="1.41176" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-serverApp-c87="" d="M8.11458 10.1891C9.26518 10.1891 10.1979 9.25639 10.1979 8.10579C10.1979 6.9552 9.26518 6.02246 8.11458 6.02246C6.96399 6.02246 6.03125 6.9552 6.03125 8.10579C6.03125 9.25639 6.96399 10.1891 8.11458 10.1891Z" stroke="currentColor" stroke-width="1.41176" stroke-linecap="round"></path><path _ngcontent-serverApp-c87="" d="M11.0312 14.3558L14.3646 11.0225" stroke="currentColor" stroke-width="1.41176" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>-->
<!--                        <span style="cursor: pointer">প্রোমো কোড যোগ করুন</span>-->
<!--                      </span>-->

<!--                    </div>-->
<!--                    <div v-if="showPromoInput && !appliedPromoCode" class="col-lg-12 col-md-12 col-sm-12 p-2">-->
<!--                      <div class="text-left">-->
<!--                        <input class="form-control-promo" type="text" v-model="promoCode" placeholder="প্রোমো কোড যোগ করুন">-->
<!--                        <button class="m-3 btn btn-outline-primary" @click="verifyPromoCode(pack.id)">Apply</button><span style="color: red; cursor: pointer" @click="resetPromoCode">X</span>-->
<!--                        <p v-if="promoCodeError" style="color: red">{{ promoCodeError }}{{ "\xa0" }}{{"\xa0" }}<span style="color: red; cursor: pointer" @click="resetPromoCode">X</span></p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div v-if="appliedPromoCode" class="col-lg-12 col-md-12 col-sm-12 p-2">-->
<!--                      <div class="d-block text-left">-->
<!--                       <h5> <b>প্রোমোঃ</b> <span style="color: green">{{ appliedPromoCode }}</span>{{ "\xa0" }}{{"\xa0" }}{{ "\xa0" }}{{"\xa0" }}<span style="cursor: pointer" @click="resetPromoCode">X</span></h5>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-12 col-md-12 col-sm-12 p-2">-->
<!--                      <div class="tacbox" style="-->
<!--              display: flex;-->
<!--              font-size: 14px;-->
<!--              max-width: 800px;-->
<!--              text-align: left;-->
<!--            ">-->
<!--                        <input type="checkbox" id="checkbox" v-model="checked" class="inp-cbx"-->
<!--                               style="margin-right: 10px; margin-bottom: 10px"/>-->
<!--                        <label for="checkbox">-->
<!--                          I agree to these-->
<!--                          <a target="_blank" href="https://bcsprostuti.com/terms-condition.html" style="font-size: 14px">Terms and-->
<!--                            Conditions</a>,-->
<!--                          <a target="_blank" href=" https://bcsprostuti.com/privacy-policy.html" style="font-size: 14px">Privacy policy</a>-->
<!--                          and-->
<!--                          <a target="_blank" href=" https://bcsprostuti.com/refund-policy.html" style="font-size: 14px">Refund-->
<!--                            policy</a></label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-12 col-md-12 col-sm-12 p-2" style="text-align: left">-->
<!--                      <button v-if="discountedPrice" class=" btn btn-outline-primary" type="button" :disabled="!checked"-->
<!--                              @click="onPayClicked(pack.id)">-->
<!--                        পে <span class="pay-dam" style="font-size: 1rem !important;"> ৳{{ englishToBengaliNumber(pack.dam) }} </span>-->
<!--                        <span class=" pay-actual_dam" style="font-size: 1rem !important;">{{ "\xa0" }}{{"\xa0" }}৳{{ englishToBengaliNumber(pack.dam - discountedPrice) }} </span>-->
<!--                      </button>-->
<!--                      <button v-else class=" btn btn-outline-primary" type="button" :disabled="!checked"-->
<!--                              @click="onPayClicked(pack.id)">-->
<!--                        পে <span class="pay-dam" style="font-size: 1rem !important;" v-if="pack.dam != pack.actual_dam"> ৳{{ englishToBengaliNumber(pack.dam) }} </span>-->
<!--                        <span class=" pay-actual_dam" style="font-size: 1rem !important;">{{ "\xa0" }}{{-->
<!--                            "\xa0"-->
<!--                          }}৳{{ englishToBengaliNumber(pack.actual_dam) }} </span>-->
<!--                      </button>-->
<!--                      <button type="button" class="m-3 btn btn-outline-danger" data-bs-dismiss="modal" @click="resetPromoCode">বাতিল করুন-->
<!--                      </button>-->

<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="package-box" data-bs-toggle="modal" :data-bs-target="'#'+'details-modal-' + pack.id" style="cursor: pointer">-->
            <div @click="goToPackageDetails(pack)" class="package-box" style="cursor: pointer">
              <div class="package-box-left">
                <div class="package-box-title">
                  {{ pack.name }}
                </div>
                <div class="package-box-sub-title">
                  {{ pack.short_description }}
                </div>
              </div>
              <div class="package-box-right">
                <div class="package-box-price">
                  <span class="dam" v-if="pack.dam != pack.actual_dam">৳{{ englishToBengaliNumber(pack.dam) }}</span>
                  <span class="pt-2 actual_dam">{{ "\xa0" }}{{ "\xa0" }}৳{{
                      englishToBengaliNumber(pack.actual_dam)
                    }}</span>
                </div>
                <div class="package-box-price-2">
                  <span class="pt-2 actual_dam">৳{{ englishToBengaliNumber(pack.actual_dam) }}{{ "\xa0" }}{{
                      "\xa0"
                    }}</span>
                  <span class="dam" v-if="pack.dam != pack.actual_dam">৳{{ englishToBengaliNumber(pack.dam) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 "></div>
    </div>
  </div>

  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer/>
    </div>
    <!--      Footer section end -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import SubMenu from "@/components/SubMenu.vue";
import store from '@/store/index.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import {StaticTags} from "@/static";
import {useRouter} from "vue-router";
import httpClient from "@/config/httpClient";
import Swal from 'sweetalert2/dist/sweetalert2';
import {englishToBengaliNumber} from "@/config/utils";

export default defineComponent({
  components: {
    Footer,
    Header,
    SubMenu,
  },
  setup() {
    const title = ref(null)
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const userDetails = ref([])
    const packages = ref([])
    const checked = ref(false)
    const showFullDescription = ref(false)
    const modalOn = ref(false)
    const router = useRouter()
    const promoCode = ref('')
    const promoCodeError = ref('')
    const discountedPrice = ref('')
    const appliedPromoCode = ref('')
    const showPromoInput = ref(false)
    onMounted(() => {
      title.value = store.state.titleBar ? store.state.titleBar : "প্যাকেজ"
      getUserDetails()
      getPackages()
    });

    const verifyPromoCode = async (packageID) => {
      if (promoCode.value == '') {
        promoCodeError.value = 'Please enter a promo code'
        return
      }
      await httpClient
          .post(process.env.VUE_APP_API_BASE_URL + "/promo-codes/" + promoCode.value + "/verify/" + packageID)
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                console.log("Promo Success:::",response)
                discountedPrice.value = response.discount
                appliedPromoCode.value = promoCode.value
                promoCode.value = ''
              } else {
                console.log("Promo Failed:::",response)
                promoCodeError.value = response.message
              }
            }
            else {
              console.log("Promo Failed:::",response)
            }
          })
      console.log(promoCode.value)
    }

    const resetPromoCode = () => {
      promoCode.value = ''
      promoCodeError.value = ''
      discountedPrice.value = ''
      appliedPromoCode.value = ''
      showPromoInput.value = false
    }
    const onSubscribeClicked = (id) => {
      this.showModal(id);
    }

    const getUserDetails = async () => {
      await httpClient
          .get(process.env.VUE_APP_API_BASE_URL + "/user-info")
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                userDetails.value = response.user
              }
            }
          })
    }

    const onPayClicked = (id) => {
      if ((!access_token) || access_token == "null") {
        store.commit("setPackId", id);
        router.push("/login");
      } else {
        buyPackage(id)
      }

    }

    const buyPackage = async (id) => {
      const params = {
        website: true,
        promo: appliedPromoCode.value,
        user_id: userDetails.value.userId,
      }
      const queryString = new URLSearchParams(params).toString()
      await httpClient
          .post(`${process.env.VUE_APP_API_BASE_URL}/memberships/${id}?${queryString}`)
          .then((response) => {
            if (response.success) {

              window.location.href = response.url;


            } else {
              Swal.fire({
                text: response.message,
                title: "Failed",
                icon: StaticTags.ALERT_TYPE_ERROR,
                showConfirmButton: true,
                confirmButtonText: "Okay"
              }).then((result) => {
                if (result.isConfirmed) {
                  // do nothing
                }
              })
            }
          })

    }

    const showModal = (i) => {
      // router.push("packages/"+i)
      this.$refs["modal" + i][0].show();
    }

    const hideModal = (i) => {
      this.$refs["modal" + i][0].hide();
    }

    const toggleDescription = () => {
      if (showFullDescription.value) {
        showFullDescription.value = false
      } else {
        showFullDescription.value = true
      }
    }

    const goToPackageDetails = (pack) => {
      store.dispatch("setTitleBar", pack.name)
      router.push('/packages/' + pack.id)
    }

    const getPackages = async () => {
      await httpClient
          .get(process.env.VUE_APP_API_BASE_URL + "/packages", {
            params: {
              website: true,
              user: userDetails.value.userid,
            },
          })
          .then((response) => {
            packages.value = response.package;
          });
    }
    return {
      title,
      packages,
      checked,
      modalOn,
      showModal,
      hideModal,
      onSubscribeClicked,
      onPayClicked,
      englishToBengaliNumber,
      showFullDescription,
      toggleDescription,
      promoCode,
      promoCodeError,
      verifyPromoCode,
      resetPromoCode,
      discountedPrice,
      appliedPromoCode,
      showPromoInput,
      goToPackageDetails
    };
  },
});
</script>

<style scoped>
.package-box {
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.package-box:hover {
  background-color: #0095FF;
  color: white !important;

  .package-box-title {
    color: white !important;
  }

  .package-box-sub-title {
    color: white !important;
  }

  .package-box-price {
    color: white !important;

    .dam {
      color: white !important;
    }

    .actual_dam {
      color: white !important;
    }
  }
}

.form-control-promo {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #007bff;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #007bff;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.package-box-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.package-box-sub-title {
  color: #999;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.package-box-price {
  color: #0095FF;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
  display: none;
}

.package-box-price-2 {
  color: #0095FF;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
  visibility: visible;
}

.package-box-list {
  display: grid;

}

.dam {
  color: #807e7d;
  text-decoration: line-through;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.actual_dam {
  color: #066cbd;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

@media (min-width: 768px) {
  .package-box {
    grid-template-columns: 4fr 1fr;
  }

  .package-box-price {
    display: block;
    text-align: right;
  }

  .package-box-price-2 {
    display: none;
    text-align: right;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 300px) and (max-width: 580px) {
  .dam {
    font-size: 14px !important;
  }

  .actual_dam {
    font-size: 18px !important;
  }
}



</style>