<template>

  <Header/>

  <SubMenu :title="title"></SubMenu>
  <SearchMenu :title="searchQuery"></SearchMenu>

  <div class="container-fluid">
    <div class="search-page row">
      <div class="col-lg-3 col-md-3 col-sm-3"></div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div v-if="!showRecentSearch" class="h-100 align-items-center justify-content-center">
          <div v-if="questions.length > 0">
            <SearchQuestionView v-for="(q, index) in questions" :questionIndex="questionNumbers(index)" :key="q.id" :question="q"
                                :isExamMode="isExamMode" :isExerciseMode="isExerciseMode" :viewOnly="viewOnly"
                                :isMenuShowAble="isMenuShowAble" :isResultMode="isResultMode"
                                v-model:questionWithState="questionWithState[index]" />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3"></div>
      <div class="p-2">
        <PaginationPage :total-items="itemTotal"
                        :items-per-page="itemsPerPage"
                        :current-page="currentPage"
                        @page-changed="onNextPage" />
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div ref="footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import SubMenu from "@/components/SubMenu.vue";
import Header from "@/components/NewHeaderView.vue";
import SearchQuestionView from "@/components/SearchQuestionView.vue";
import Question from "@/dataModel/QuestionModel";
import {setStore} from "@/config/utils";
import httpClient from "@/config/httpClient";
import QuestionViewInformation from "@/dataModel/QuestionViewInformation";
import Footer from "@/components/FooterView.vue";
import {useRouter} from "vue-router";
import store from "@/store";
import SearchMenu from "@/components/SearchMenu.vue";
import PaginationPage from "@/components/PaginationPage.vue";

export default defineComponent({
  props: [],
  components: {PaginationPage, SearchMenu, Footer, SearchQuestionView, Header, SubMenu},
  models: {
    Question,
  },

  setup() {
    const router = useRouter();
    let questions = ref([]);
    let questionWithState = [];
    const isExamMode = ref(false)
    const viewOnly = ref(true)
    const isResultMode = ref(false)
    const isExerciseMode = ref(false)
    const isMenuShowAble = ref(true)
    const saerchType = ref(1) // change type if needed to search other thing, by default it search for questions
    const searchTypeQuestion = 1
    let ansList = []
    const title = ref("")

    const searchQuery = ref('')
    const showRecentSearch = ref(true)
    const savedSearchList = ref([])
    const currentPage = ref(1);
    const itemsPerPage = ref(20);
    const itemTotal = ref(null);


    onMounted(() => {
      title.value = store.state.titleBar ? store.state.titleBar : "সার্চ ফলাফল"
      searchQuery.value = router.currentRoute.value.params.query
      getSearchResultList()
    });

    const clearSearch = () => {
      searchQuery.value = ''
      showRecentSearch.value = true
    }

    const deleteFromSearchList = (i) => {
      savedSearchList.value.splice(i, 1)
      setStore("searchList", savedSearchList.value)

    }

    const searchByTheSelectedItem = (i) => {
      searchQuery.value = savedSearchList.value[i]
      deleteFromSearchList(i)
      getSearchResultList()
    }

    const getSearchResultList = () => {
      if (searchQuery.value) {
        if (saerchType.value == searchTypeQuestion) {
          setSearchModeOn()
          getSearchedQuestions()
          showRecentSearch.value = false
          savedSearchList.value.unshift(searchQuery.value)
        }
      }
    };

    const setSearchModeOn = () => {
      isResultMode.value = true
      isExamMode.value = false
      viewOnly.value = true
      isMenuShowAble.value = true

    }

    const getSearchedQuestions = async () => {

      const url = process.env.VUE_APP_API_BASE_URL + "/questions/search";

      await httpClient
          .post(url, {
            "text": searchQuery.value
          }, {
            params: {
              "size": 20,
              "page": currentPage.value - 1,
            }
          })
          .then((res) => {
            if (res) {
              itemTotal.value = res.totalElement;
              questions.value = res.result;
              setQuestionAndAnswerState(res)
            }
          })
    }

    const questionNumbers = (i) => {
      return i + (currentPage.value - 1) * itemsPerPage.value
    }

    const setQuestionAndAnswerState = (res) => {
      questionWithState = []
      ansList = []
      for (const q of res.result) {
        //here need to be changed for multiple option
        let correctOption = 0
        q.options.forEach(function (option, i) {
          if (option.answer == 1) {
            correctOption = i + 1
          }
        });

        var questionState = new QuestionViewInformation()
            .setQuestion(q)
            .setGivenAnswer(correctOption)
            .build();
        questionWithState.push(questionState)
        if (!isExerciseMode.value) {
          ansList.push({ qid: q.id, correctAns: correctOption, selectedAns: 0 });
        }
      }

    }

    const onNextPage = (page) => {
      currentPage.value = page;
      getSearchResultList();
    }


    return {
      questions,
      questionNumbers,
      isExamMode,
      questionWithState,
      viewOnly,
      getSearchResultList,
      clearSearch,
      deleteFromSearchList,
      searchByTheSelectedItem,
      showRecentSearch,
      title,
      isMenuShowAble,
      isResultMode,
      isExerciseMode,
      searchQuery,
      savedSearchList,
      currentPage,
      itemsPerPage,
      itemTotal,
      onNextPage,
    };
  },
})
</script>
<style>

</style>