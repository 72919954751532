<template>
  <div class="d-flex search-input-form">
    <i class="fa-solid fa-magnifying-glass search-icon"></i>
    <input
        class="nav-form-control form-control input-form"
        type="text"
        placeholder="খুঁজুন"
        aria-label="Search"
        v-model="searchQuery"
        @keyup.enter="getSearchList"
    />
    <i v-if="searchQuery" class="bi bi-x-circle-fill clear-icon" @click="clearSearch"></i>
  </div>
<!--    <div class="container-fluid container-fluid-responsive">-->
<!--        <div class="input-group mb-3">-->
<!--            <input type="text" class="form-control" v-model="searchQuery" placeholder="Enter your search query">-->
<!--            <span v-if="searchQuery" class="clear-icon" @click="clearSearch">-->
<!--                <i class="bi bi-x-circle-fill"></i>-->
<!--            </span>-->
<!--            <br>-->
<!--            <br>-->
<!--            <button class="btn btn-primary" @click="getSearchList">Search</button>-->

<!--        </div>-->

<!--        <div v-if="showRecentSearch && savedSearchList.length>0">-->
<!--            <h4>Recent Search</h4>-->
<!--            <div v-if="savedSearchList.length > 0">-->
<!--                <div v-for="(item, index) in savedSearchList" :key="index" @click="searchByTheSelectedItem(index)">-->
<!--                   &lt;!&ndash; design as like the mobile &ndash;&gt;-->
<!--                   <p>{{item}}</p>-->
<!--                   <p @click="deleteFromSearchList(index)">delete</p>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div v-if="!showRecentSearch" class="h-100 align-items-center justify-content-center">-->
<!--            <div class="" v-if="questions.length > 0">-->
<!--                <SearchQuestionView v-for="(q, index) in questions" :questionIndex="index" :key="q.id" :question="q"-->
<!--                    :isExamMode="isExamMode" :isExerciseMode="isExerciseMode" :viewOnly="viewOnly"-->
<!--                    :isMenuShowAble="isMenuShowAble" :isResultMode="isResultMode"-->
<!--                    v-model:questionWithState="questionWithState[index]" />-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</template>



<script>
import { defineComponent, onMounted, ref } from "vue";
import Question from "@/dataModel/QuestionModel.js";

import 'bootstrap-icons/font/bootstrap-icons.css';

import { setStore, getStore } from "@/config/utils";
import {URLs} from "@/static";
import {useRouter} from "vue-router";
import store from "@/store";


export default defineComponent({
    models: {
        Question,
    },

    setup() {
      const router = useRouter();
        const searchQuery = ref('')
        const showRecentSearch = ref(true)
        const savedSearchList = ref([])

        onMounted(() => {
            if (getStore("searchList")) {
                let slist = JSON.parse(getStore("searchList"))
                slist.forEach(function(item) {
                    savedSearchList.value.push(item)
                })
            }
        });

        const clearSearch = () => {
            searchQuery.value = ''
            showRecentSearch.value = true
        }

        const deleteFromSearchList = (i) => {
            savedSearchList.value.splice(i, 1)
            setStore("searchList", savedSearchList.value)
 
        }

        const searchByTheSelectedItem = (i) => {
            searchQuery.value = savedSearchList.value[i]
            deleteFromSearchList(i)
            getSearchList()
        }

        const getSearchList = () => {
          store.dispatch("setTitleBar", " সার্চ ফলাফল")
            if (searchQuery.value) {
                if (savedSearchList.value.length > 0) {
                    let isExist = false
                    savedSearchList.value.forEach(function(item) {
                        if (item === searchQuery.value) {
                            isExist = true
                        }
                    })
                    if (!isExist) {
                        savedSearchList.value.push(searchQuery.value)
                        setStore("searchList", savedSearchList.value)
                    }
                } else {
                    savedSearchList.value.push(searchQuery.value)
                    setStore("searchList", savedSearchList.value)
                }
                router.push({
                    name: URLs.ROUTE_SEARCH_PAGE_NAME,
                    params: { query: searchQuery.value },
                })
            }

        };

        return {
            getSearchList,
            clearSearch,
            deleteFromSearchList,
            searchByTheSelectedItem,
            showRecentSearch,
            searchQuery,
            savedSearchList,
        };
    },
});
</script>

<style>
.clear-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}
</style>