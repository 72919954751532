<template>
  <!--      Header section start -->
  <Header/>
  <!--      Header section end -->

  <!-- Notification section start -->
  <SubMenu v-if="showNotification" :userDetails="userDetails" :isNotification="true"></SubMenu>
  <!-- Notification section end -->
  <StartExamModal @close="toggleModal" @start="checkFields" :modalActive="modalActive"
                  v-model:questionNumber="questionNumber" v-model:time="time" v-model:showErrorOfQnumber="showErrorOfQnumber"
                  v-model:showErrorOfTime="showErrorOfTime" :title="title">

  </StartExamModal>
  <div class="container-fluid">
    <div class="container-fluid pl-0 pr-0">
      <!--      Hero content section start -->
      <div class=" mt-2 hero-section-container">
        <div class="row">
          <div class="col-lg-6 col-md-6  col-sm-6  ellipse-container-wrapper">
            <div class="d-flex justify-content-center align-items-center h-100">
              <div>
                <h1 class="hero-title">
                  স্মার্ট প্রস্তুতি'র অঙ্গীকার
                </h1>
                <p class="sub-title">
                  সরকারি চাকরি'র প্রস্তুতি এখন আরও স্মার্ট, আরও সহজ আর একদম হাতের মুঠোয়, আপনার মুঠোফোনে
                </p>
                <div class="playstore-btn-box">
                  <button class="playstore-btn my-1">
                    <a class="nav-link link text-dark bg-transparent"
                       onclick="window.open('https://play.google.com/store/apps/details?id=com.bcsprostuti.tanim.bcsprostuti&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1', '_blank')"><img
                    >
                      <img
                          src="@/assets/images/playstore.png"
                          alt=""
                          class="img-fluid playstore-img"
                      />
                      অ্যাপ থেকে শিখুন
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="ellipse-container">
              <img src="@/assets/images/webimage.png" style="height: 96%; width: 88%;"/>
            </div>
          </div>
        </div>
      </div>
      <!--      Hero content section end -->
      <!--      Live exam section-->
      <div class=" mt-3 exam-section-container">
        <div class="exam-section-header">
          <h2 class="exam-section-title text-left"> লাইভ এক্সাম </h2>
          <a class="routine-title text-lg-right" href="" @click="handleAllButtonClick"> সম্মিলিত রুটিন/আর্কাইভ  </a>
        </div>
        <CourseLiveExamList/>
      </div>
      <!--      Live exam section end-->
      <!--      Archive section start -->
      <div class="m-3 archived-section-container">
        <RoutineArchived/>
      </div>
      <!--      Archive section end -->
      <!--      Program section start -->
      <div class="mt-3 program-section-container">
        <div class="exam-section-header" >
          <h2 class="exam-section-title text-left"> কোর্স ক্যাটাগরি </h2>
          <a class="routine-title text-lg-right" href="#"> সকল কোর্স </a>
        </div>
        <div class="exam-section-body">

          <CourseCategories/>
        </div>
        <div class="exam-section-footer">
          <div class="subjective-footer" @click="goToCoursePage" style="width: 100%; margin-top: 40px; padding-left: 15px">
            <div class=" col-lg-12 col-md-12 col-sm-12">
              <a class="live-exam-all" href="">
                সবগুলো দেখুন
                <svg
                    class="archived-button-arrow"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="top: 4px; cursor: pointer"
                    @click="goToCoursePage"
                >
                  <path
                      d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"
                      fill="#0095FF"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

<!--        <CourseHorizontalScrollView/>-->
      </div>
      <!--      Program section end -->
      <!--      Subjective Test section start -->
      <div class="mt-3 subjective-test-container ">
        <div class="subjective-body">
          <div class="subjective-title" style="width: 100%; padding-left: 15px">
            <h2 class="subjective-test-title">বিষয়ভিত্তিক পরীক্ষা</h2>
          </div>
          <div class="subjective-content">
            <div class="subjective-box" v-for="category in categoryList" :key="category.id" :name="category.name" @click="onCategorySelected(category)" style=" cursor: pointer">

              <div class="rectangle-56" width="220" height="130">{{ category.sign }}</div>
              <div class=" subjective-box-title">{{ category.name }}</div>
              <div class="subjective-box-arrow" @click="onCategorySelected(category)">
                <svg
                    class="subjective-arrow"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9212 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9212 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"
                      fill="#0095FF"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="subjective-footer" @click="goToCategoryPage" style="width: 100%; margin-top: 40px; padding-left: 15px">
            <div class=" col-lg-12 col-md-12 col-sm-12">
              <a class="live-exam-all" href="">
                সবগুলো দেখুন
                <svg
                    class="archived-button-arrow"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="top: 4px; cursor: pointer"
                    @click="goToCategoryPage"
                >
                  <path
                      d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"
                      fill="#0095FF"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="subjective-body-mobile" @click="goToCategoryPage">
          <div class="subjective-body-mobile-section ">
            <div class="subjective-body-mobile-text text-left m-4">
              <div class="archived-title text-left">
                বিষয়ভিত্তিক পরীক্ষা
                <svg
                    class="archived-button-arrow"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="top: 4px; cursor: pointer"
                    @click="goToCategoryPage"
                >
                  <path
                      d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"
                      fill="#0095FF"
                  />
                </svg>
              </div>
              <div class="archived-content text-left ">যত খুশি বিষয়ভিত্তিক পরীক্ষা দিন। নিজেই সেট করুন পরীক্ষার সময় এবং নম্বর।</div>

            </div>
          </div>
        </div>

      </div>
      <!--      Subjective Test section end -->
      <!--      Personal Practice section start -->
      <div class="mt-3 personal-practice-section">
        <div class="personal-practice-header">
          <h1 class="practice-header_title">অনুশীলন</h1>
        </div>
        <div class="personal-button-list">
          <PracticeButtons/>
        </div>
      </div>
      <!--      Personal Practice section end -->
      <!--      Lecture & Notes section start -->
      <div class="m-3 lecture-notes-container">
        <LectureAndNotes/>
      </div>
      <!--      Lecture & Notes section end -->

      <!--      FAQ section start -->
      <div class="mt-3 faq-section-container">
        <div class="faq-section-content">
          <div class="faq">FAQ</div>
          <div class='d-display'>
            <div class='d-details'>
              <details class='tab-control'>
                <summary>বিসিএস প্রস্তুতি অ্যাপটি কি? কাদের জন্য এই অ্যাপ? </summary>
                <p>বিসিএস সহ যে কোন ধরণের সরকারী চাকুরীর প্রস্তুতির জন্যই এই অ্যাপ 💯। আপনার মোবাইলে যে কোন জায়গা থেকে
                  লক্ষাধিক পরীক্ষার্থীদের সাথে বিভিন্ন রকম লাইভ পরীক্ষা, টপিক কিংবা বিষয়ভিত্তিক পরীক্ষা দিয়ে নিজেকে
                  প্রতিনিয়ত যাচাই করার জন্যই একটি এন্ড্রয়েড অ্যাপ বেইজড অনলাইন প্লাটফর্ম:<b> বিসিএস প্রস্তুতি।</b> </p>
              </details>
              <details class='tab-control'>
                <summary>আমি এখনও ছাত্র, আমি কিভাবে বিসিএস প্রস্তুতি শুরু করব?</summary>
                <p>বিসিএস এখন অনেক অনেক বেশী প্রতিযোগীতাপূর্ণ একটা পরীক্ষা। তাই আগে থেকেই বিসিএস এর জন্য নিজেকে প্রস্তুত করা
                  খুবই বিচক্ষণ একটি সিদ্ধান্ত। ✅ যদি আপনি এখনো একজন ছাত্র হন, তাহলে এখন থেকে ম্যাথ আর ইংলিশ এর ওপর জোর দিন।
                  রেগুলার বাংলা ও ইংরেজি পত্রিকা পড়ুন। বেশী বেশী ইংরেজি ও বাংলা বই পড়ুন। মুক্তিযুদ্ধ নিয়ে যত উপন্যাস আছে
                  সেগুলা পড়ুন। রবীন্দ্রনাথ, সমরেশ মজুমদার, শীর্ষেন্দু, শরৎচন্দ্র এদের বই পড়ুন। ইংলিশ বই হ্যারি পটার দিয়ে
                  শুরু করুন। বেশী বেশী করে ইংলিশ মুভি আর ইউটিউবে ইংলিশ টেড টক শুনুন। এতে ইংলিশ বলা এবং লিখা দুটোতেই কাজে
                  দেবে। আর নিয়মিত গণিত চর্চা টা চালু রাখুন। আপাতত অনার্স শেষ হবার আগ পর্যন্ত এগুলোই করুন। পরে আপনার দুর্বলতা
                  কই, সেটা ঠিক করতে কি করতে হবে নিজেই বুঝে যাবেন। ✊</p>
              </details>
              <details class='tab-control'>
                <summary>বিসিএস প্রস্তুতি অ্যাপ কিভাবে ইউজ করলে আমি সবচেয়ে ভাল করতে পারব?</summary>
                <p>
                  আপনি যেই টপিক কিংবা চ্যাপ্টার পড়ছেন, সেটা পড়ার পর অ্যাপে ঢুকে টপিক-ভিত্তিক পরীক্ষা যত বেশি দিবেন তত বেশি
                  উপকার পাবেন। পরীক্ষাতে যা যা ভুল করছেন, সেগুলো অ্যাপেই মার্ক করে রেখে দিন। এরপর রিভিশন দিন। এছাড়াও আপনি
                  যেই টপিক পড়া আছে সেগুলো অ্যাপ এর আর্কাইভ থেকে পরীক্ষা দিয়ে প্রতিনিয়ত নিজেকে যাচাই করবেন। সাথে আপনার জন্য
                  উপযোগী একটি কোর্স খুব ভাল করে ফলো করুন 👍।
                </p>
              </details>

              <details class='tab-control'>
                <summary>আমি চাকরি করি, চাকুরীর পাশাপাশি কিভাবে পড়াশোনা করব বুঝতে পারছি না?</summary>
                <p>
                  আপনি চাকুরী করেন তার মানে আপনি চাকুরীর পাশাপাশি পড়াশোনা করবেন। সেক্ষেত্রে আপনার দিনে পড়ার জন্য সময় পাবেন
                  অন্যদের তুলনায় কিছুটা কম। তাই ঠিক আপনাদের কথা চিন্তা করেই আমরা নিয়ে এসেছি <b>‘বিসিএস প্রস্তুতি (৬ মাস)’</b>
                  কোর্সটি 📌। খুব অল্প সিলেবাসে দৈনিক ২-৩ ঘণ্টাতেই শেষ করা যায়: এমনভাবেই তৈরি করা হয়েছে সম্পূর্ণ সিলেবাস এবং
                  রুটিন। এই কোর্সটি ফলো করলেই আপনি চাকুরীর পাশাপাশি একটা ভালো প্রস্তুতি নিয়ে নিতে পারবেন। তাছাড়া, আপনি
                  আমাদের অ্যাপস এর লেকচার অ্যান্ড নোটস অপশনে গিয়ে গাইডলাইনগুলো 📚 ফলো করতে পারেন।
                </p>
              </details>

              <details class='tab-control'>
                <summary>আমি অনেকদিন ধরেই চেষ্টা করছি, কোন ভাবেই ভাল ফলাফল করতে পারছি না। কি করব?</summary>
                <p>
                  প্রচুর পরিমাণে পড়াশোনা করার কোন বিকল্প নেই। সাথে বিকল্প নেই পরীক্ষা দেয়ার। আপনি যত বেশী পড়বেন তত বেশী ভালো
                  করবেন ✌️। আর যত পরীক্ষা দেবেন, তত আপনার উত্তীর্ণ হবার সম্ভাবনা বাড়বে। কোথায় কোথায় বেশী ভুল করেন সেগুলোর
                  দিকে বেশী করে নজর দিতে হবে। ওগুলো নিয়ে কাজ করতে হবে। ✊

                </p>
              </details>
            </div>
            <div class="d-image">
              <p><a href="/faq">More FAQ</a></p>
            </div>
          </div>
        </div>
      </div>
      <!--      FAQ section end -->

      <!--      Join Section start -->
      <div class="mt-3 join-section-container">
        <div class="join-section-box">
          <div class="join-section-frame">
            <div class="initial-text">শুরু করুন এখনই</div>
            <div class="join-content">সরকারি চাকরি'র প্রস্তুতি এখন আরও স্মার্ট, আরও সহজ আর একদম হাতের মুঠোয়, আপনার মুঠোফোনে</div>
            <div class="join-button">

              <button type="button"  class="btn btn-outline-primary" style="padding: 0">
                <a class="nav-link link bg-transparent"
                   onclick="window.open('https://play.google.com/store/apps/details?id=com.bcsprostuti.tanim.bcsprostuti&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1', '_blank')"><img
                >
                  <img
                      src="@/assets/images/playstore.png"
                      alt=""
                      class="img-fluid playstore-img"
                  />
                  অ্যাপ থেকে শিখুন
                </a>
              </button>
            </div>
          </div>

        </div>
      </div>
      <!--      Join Section end -->
      <!--      Footer section start -->
      <div ref="footer">
        <Footer/>
      </div>
      <!--      Footer section end -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import {defineComponent, onMounted, ref} from "vue";
import {URLs} from "@/static";
import {useRouter} from "vue-router";
import {calculateOffset, removeItem, setExamInfo, setExamParams, vapidKey} from "@/config/utils";
import {StaticTags} from "@/static";
import LectureAndNotes from "@/components/LectureAndNotes.vue"
import 'vue3-carousel/dist/carousel.css'
// import Swal from 'sweetalert2/dist/sweetalert2';
import CourseLiveExamList from "@/components/CourseLiveExamList.vue";
import PracticeButtons from "@/components/PracticeButton.vue";
// import CourseHorizontalScrollView from "@/components/CourseHorizontalScrollView.vue";
import CourseCategories from "@/components/CourseCategories.vue";
import RoutineArchived from "@/components/RoutineArchived.vue";
import store from "@/store";
import {mapState} from "vuex";
import httpClient from "@/config/httpClient";
import SubMenu from "@/components/SubMenu.vue";
import StartExamModal from "@/components/StartExamModal.vue";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { firebaseConfig } from "@/config/utils";



export default defineComponent({
  name: "PackagePage",
  components: {
    StartExamModal,
    SubMenu,
    // CourseHorizontalScrollView,
    CourseLiveExamList,
    Footer,
    Header,
    PracticeButtons,
    LectureAndNotes,
    CourseCategories,
    RoutineArchived,
  },
  watch: {
    categoryList() {
      return store.categoryList
    },
  },
  computed: mapState(["categoryList"]),
  created: function () {
    this.$store.dispatch("loadCategoryList"); // load data
  },
  setup() {
    const modalActive = ref(false);
    const router = useRouter();
    const showNotification = ref(false);
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const userDetails = ref(null);
    const questionNumber = ref(30)
    const time = ref(18)
    const showErrorOfTime = ref(false)
    const showErrorOfQnumber = ref(false)
    const title = ref()
    const quiztime = ref(0)
    const selectedCat = ref()
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
    removeItem("packId")
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    onMounted(() => {
      if (isLoggedIn) {
        getUserDetails()
        getPushNotificationPermission()
      }
    })

    const getPushNotificationPermission = async () => {
      if (Notification.permission !== 'granted') {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const token = await getToken(messaging, { vapidKey: vapidKey });
          await sendTokenToServer(token)
        }
      } else if (Notification.permission === 'granted'){
        const token = await getToken(messaging, { vapidKey: vapidKey });
        await sendTokenToServer(token)
      }
    }

    const sendTokenToServer = async (token) => {
      const config = {
        fcm_token: token,
      };
      console.log("Token", token)
      await httpClient
          .get(process.env.VUE_APP_API_BASE_URL + "/user-info", config)
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                console.log("Token saved")
              }
            }
          })
    }

    const getUserDetails = async () => {
      await httpClient
          .get(process.env.VUE_APP_API_BASE_URL + "/user-info" )
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                userDetails.value = response.user
                if (userDetails.value.warning_message) {
                  showNotification.value = true
                }
              }
            }
          })
    }


    const goToFavouritQuestionPage = () => {
      store.dispatch("setFlowType", StaticTags.TYPE_FAVOURITE)
      router.push("/categories/");
    }

    function goToExercisePage() {
      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_EXERCISE)
      router.push("/categories/");
    }

    function goToQuestionSet() {
      this.$router.push("/signinsignup");
    }

    const goto = (refName) => {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    }
    const getImage = (imagePath) => {
      return require(imagePath);
    }

    function goToSubjectExamPage() {
      store.dispatch("setFlowType", StaticTags.TYPE_EXAM)
      router.push("/categories/");
    }

    const onCategorySelected = (category) => {
      title.value = category.name
      quiztime.value = category.quiz_time
      selectedCat.value = category
      if (category.subcategories.length > 0)
      {
        goToSubCategories(category)
      } else {
        showModalForCategoryExam()
      }

    }
    const goToCategoryPage = () => {
      store.dispatch("setFlowType", StaticTags.TOPIC_FOR_CATEGORY)
      store.dispatch("setTitleBar", "বিষয়ভিত্তিক পরীক্ষা")
      router.push("/categories/");
    }
    const goToCoursePage = () => {
      store.dispatch("setFlowType", StaticTags.TOPIC_FOR_CATEGORY)
      store.dispatch("setTitleBar", "কোর্স সমূহ")
      router.push({
        name: URLs.ROUTE_COURSE_TYPES_ALL_NAME,
        params: {
          id: StaticTags.NO_ID,
        }
      });
    }

    const startExamOnCategory = () => {
      setExamInfo({
        id: selectedCat.value.id,
        examType: StaticTags.TYPE_OVER_ALL_EXAM,
        title: selectedCat.value.name,
        isViewOnly: false,
        questionNumber: questionNumber.value,
        isExam: true,
        startTime: new Date(),
        time: time.value,
        examFlowType: StaticTags.TYPE_OVER_ALL_EXAM
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_CATEGORY,
        topicId: selectedCat.value.id,
        questionNumber: questionNumber.value
      })

      router.push("/exam");
    };

    const handleAllButtonClick = () => {

      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_LIVE_COURSE)
      router.push({
        name: URLs.ROUTE_LIVE_COURSE_EXAM_LIST_NAME,
      })
    };

    const showModalForCategoryExam = () => {
      modalActive.value = true
    }

    const goToSubCategories = (category) => {
      store.dispatch("setFlowType", StaticTags.TYPE_EXAM)
      router.push({
        name: URLs.ROUTE_EXAM_SUB_CATEGORIES_NAME,
        params: {
          cid: category.id,
        }
      })
    }

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };

    const checkFields = () => {
      let offset = calculateOffset(quiztime)
      let maxQ = Infinity
      maxQ = Math.min(StaticTags.MAX_QUESTION_CATEGORY, selectedCat.value.questions_count) // maxQ will be the minimum of 50 and question in the sub category
      if (questionNumber.value > maxQ) {
        showErrorOfQnumber.value = true
      }
      //else if for checking time

      else if (time.value > offset * maxQ) {
        showErrorOfTime.value = true
      }
      else {
        showErrorOfQnumber.value = false
        showErrorOfTime.value = false
        startExamOnCategory()
      }

    }

    return {
      getImage,
      goto,
      goToFavouritQuestionPage,
      goToExercisePage,
      goToSubjectExamPage,
      goToQuestionSet,
      isLoggedIn,
      onCategorySelected,
      goToCategoryPage,
      goToCoursePage,
      showNotification,
      userDetails,
      handleAllButtonClick,
      questionNumber,
      modalActive,
      showErrorOfTime,
      showErrorOfQnumber,
      toggleModal,
      checkFields,
      title,
      time,
    }
  },
});
</script>

<style>

.carousel__pagination-button:hover::after, .carousel__pagination-button--active::after {
  background-color: #00A8A8 !important;
}

.subjective-list-mobile {
  display: none;
}
details p {
  text-align: justify;
}

details[open]  {
  height: fit-content !important;
}

.join-section-frame {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  //grid-template-rows: auto auto auto;
  justify-content: center;
  align-items: center;
}

.group-9056 {
  position: absolute;
  inset: 0;
}

.join-button {
  //background: #0095ff;
  //border-radius: 12px;
  //width: 211px;
  //height: 52px;
  //position: absolute;
  //left: 116px;
  //top: 87px;
}

.button-text {
  color: #fffbfa;
  text-align: right;
  font: 700 16px/28px "Noto Sans Bengali", sans-serif;
  position: absolute;
  left: 165px;
  top: 99px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.initial-text {
  color: #000000;
  text-align: center;
  font: 600 20px/20px "Noto Sans Bengali", sans-serif;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.join-content {
  color: #999999;
  text-align: center;
  font: 600 16px/20px "Noto Sans Bengali", sans-serif;
  position: relative;
}

.join-section-container {
  min-height: 30vh;
  margin-top: 60px;
}

.join-section-box {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
  opacity: 0.9599999785423279;
  height: 160px;
  position: relative;
}

.faq-section-container {
  min-height: 50vh;
  margin-top: 40px;
}

.faq-section-content {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
  opacity: 0.9599999785423279;
  position: relative;
}

.faq {
  color: #000000;
  text-align: center;
  font: 700 32px/32px "Noto Sans Bengali", sans-serif;
  position: relative;
  top: 25px;
}

.d-display {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  padding: 2rem;
}

details summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  cursor: pointer;
  color: #000000;
  text-align: left;
  font: 600 20px/20px "Noto Sans Bengali", sans-serif;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

details summary:after {
  content: "›";
  font-size: 2rem;
  transition: 0.3s;
  transform: rotate(90deg);
}

/* transitions */
details {
  margin-bottom: 1rem;
  height: 4rem;
  transition: height 0.3s ease;
}

details[open] {
  height: 6rem;
}

details p {
  height: fit-content;
  padding: 1rem;
}

details[open] summary:after {
  transform: rotate(-90deg);
}

/* responsive */
@media (max-width: 1190px) {
  body,
  .d-display {
    height: fit-content;
    width: 100%;
  }

  .d-display {
    justify-content: center;
    padding: 2rem 0;
  }
}

.lecture-notes-container {
  min-height: 30vh;
}

.personal-practice-section {
  min-height: 25vh;
  display: grid;
  grid-gap: 10px;
  //grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 50px 1fr;
  margin-top: 50px;
}

.personal-practice-header {
  text-align: left;
  grid-column: 1 / -1;
}

.practice-header_title {
  color: #000000;
  text-align: left;
  font: 700 28px/32px "Noto Sans Bengali", sans-serif;
  position: relative;
}


.personal-button-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.card-img-top {
  border-radius: 1.25rem !important;
}

.course-card {
  border-radius: 1.25rem !important;
  width: fit-content;
}

.hero-section-container {
  min-height: 20vh;
  display: flex;
  justify-content: left;
  align-items: normal;
}

.exam-section-container {
  min-height: 25vh;
  justify-content: center;
  display: block;
}

.archived-section-container {
  min-height: 38vh;
  justify-content: center;
  display: none;
  align-items: center;
}

.archived-content {
  color: #999999;
  text-align: center;
  font: 600 16px/20px "Noto Sans Bengali", sans-serif;
  position: relative;
}

.exam-section-header {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 98px;
}

.exam-section-title {
  font-family: 'Noto Sans Bengali';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
}

.routine-title {
  visibility: visible;
  font-family: 'Noto Sans Bengali';
  font-size: 15px;
  line-height: 30px;
}

.archived-button {
  color: #0095ff;
  text-align: center;
  font: 700 20px/30px "Noto Sans Bengali", sans-serif;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.archived-button-arrow {
  position: relative;
  overflow: visible;
}

.program-section-container {
  min-height: 30vh;
  justify-content: center;
  display: block;
}

.subjective-content {
  padding-left: 15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
}

.top-title-div {
  font: 600 30px/30px "Noto Sans Bengali", sans-serif;
}

.top-sub-title {
  color: rgba(7, 24, 51, 0.60);
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.subjective-title-2 {
  width: 100%;
  padding: 15px;
}

.subjective-test-container {
  min-height: 50vh;
  justify-content: center;
  display: block;
}

.subjective-body {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.subjective-body-mobile {
  display: none;
}

.subjective-body-mobile-section {
    box-sizing: border-box;
    border-radius: 16px;
    border-style: solid;
    border-color: #e6e4e4;
    border-width: 1px;
    opacity: 0.9599999785423279;
    position: relative;
    width: 100%;
    background: #ffffff;
    height: fit-content;
}

.subjective-test-title {
  color: #000000;
  text-align: left;
  font: 700 28px/28px "Noto Sans Bengali", sans-serif;
  position: relative;
}

.subjective-content .subjective-box:nth-child(2n) .rectangle-56 {
  background: #EDF1F7;
}

.subjective-content .subjective-box:nth-child(3n) .rectangle-56 {
  background: #F3D8D9;
}

.scrollable-div-container .scrollable-div-item:nth-child(2n) .live-exam-box .live-exam-header {
  background: #00A8A8;
}

.scrollable-div-container .scrollable-div-item:nth-child(3n) .live-exam-box .live-exam-header {
  background: #FD8312;
}

.subjective-body-mobile-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.subjective-box {
  height: 245px;
  width: 258px;
  border-radius: 18px;
  border: 2px solid rgb(0 0 0 / 5%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.rectangle-56 {
  border-radius: 12px;
  position: relative;
  overflow: visible;
  grid-column: 1/5;
  margin: 18px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F0EDF2;;
  font-weight: 600;
}

.subjective-box-title {
  color: #071833;
  text-align: left;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  grid-column: 1/4;
  margin-left: 18px;
  front-family: 'Noto Sans Bengali';
  font-weight: 600;
}

.subjective-box-arrow {
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
  grid-column: -1

}

.subjective-arrow {
  overflow: visible;
}

.hero-title {
  font-weight: 625;
  font-size: 23px;
  text-align: left;
}

.sub-title {
  font-size: 16px;
  text-align: left;
}

.playstore-btn-box {
  text-align: justify;
}
.playstore-btn {
  /* padding: 2px 20px !important; */
  border-radius: 12px;
  border: 0;
  color: var(--white);
  font-size: 16px;
  a {
    font-size: 15px;
  }
}

.playstore-img {
  width: 20px;
}

.subjective-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.ellipse-container-wrapper {
  position: relative;
}

.ellipse-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grand-parent-ellipe {
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid lightgray;
}

.cards:hover {
  /*box-shadow: 0 4px 10px rgba(0,0,0,0.16), 0 4px 10px rgba(0,0,0,0.23);*/
  border: 1px solid #0095FF;
}

.microsite-box-x h2 {
  font-size: 15px !important;
  color: white !important;
  font-weight: 700 !important;
  margin-bottom: 5px !important;
  border: 1px solid #0095FF !important;
  background: #0095FF !important;
  padding: 12px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.microsite-box-x {
  background-color: #fff !important;
  box-shadow: 1px 1px 5px 1px rgb(194 194 194 / 28%);
  border-radius: 10px;
  padding: 0px !important;
  width: 180px !important;
  height: 261px !important;
}

.microsite-box-x h6 {
  background: #fff !important;
  padding: 8px !important;
  text-align: center !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  color: #2a97ef !important;
}

.microsite-box-x span {
  font-size: 17px !important;
  color: #066cbd !important;
  font-weight: 700;
}

.microsite-box-x p {
  font-size: 12px !important;
  text-align: left !important;
  padding: 5px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.parent-ellipe {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid lightgray;
  position: absolute;
  top: 50px;
  left: 50px;
}

.child-ellipe {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid lightgray;
  position: absolute;
  top: 50px;
  left: 50px;
}

.button {
  padding: 2px 20px !important;
  border-radius: 12px;
  border: 0;
  color: var(--white);
}

.bg-blue {
  background-color: var(--blue);
}

.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px 3px;
  position: absolute;
  bottom: 34px;
  right: 58px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.color-blue {
  color: var(--blue) !important;
}

.link {
  font-weight: 500;
}

.section-padding {
  padding: 30px 0;
}



.justify-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  overflow: hidden;
}

@media (min-width: 769px) {
  .container-fluid {
    width: 1250px !important;
  }
}

@media (min-width: 992px) {
  .nav-list {
    padding: 0 7px;
  }

  .nav-list:last-child {
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .nav-list {
    padding: 0 20px;
  }

  .nav-list:last-child {
    padding: 0;
  }

  .arrow {
    bottom: 24px;
    right: 13px;
  }
}

@media (max-width: 992px) {
  .signup-btn-width-responsive {
    width: 120px;
  }

  .search-input-form {
    width: 70%;
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .ellipse-container {
    display: none;
  }

  .personal-button-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
    height: fit-content;
    grid-gap: 10px;
  }

  .subjective-content {

    grid-template-columns:  1fr;
  }

  .group {
    display: none;
  }

  .subjective-body-mobile {
    display: block;
  }

  .subjective-body {
    display: none;
  }

  .subjective-test-container {
    min-height: 20vh;
  }

  details summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    width: 100%;
    cursor: pointer;
    color: #000000;
    text-align: left;
    font: 600 14px/20px "Noto Sans Bengali", sans-serif;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
  }
}

@media (max-width: 768px) {
  .subjective-list {
    display: none;
  }

  .subjective-list-mobile {
    display: grid;
    grid-gap: 30px;
  }
}

@media (min-width: 768px) {
  .hero-title {
    font-size: 36px;
  }
  .sub-title {
    font-size: 20px;
  }
  .hero-section-container {
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .exam-section-title {
    font-size: 28px;
    margin-left: 10px;
  }
  .exam-section-header{
    display: flex;
  }
  .routine-title {
    visibility: hidden;
  }

  .archived-section-container {
    display: flex;
  }

  .program-section-container {
    min-height: 35vh;
    justify-content: center;
    display: block;
  }

}


</style>
  