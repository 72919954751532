<template>
  <div v-if="isHome" class="pl-2 mb-3 test-grid" style="width: 100%">
    <div v-if="item!=undefined">
      <div :class="{
        'live-exam-box': isHome,
        'live-exam-box-2': isSingleCourse,
        'live-exam-box-3': !isSingleCourse && !isHome
      }"  >
        <div v-if="item.exam_type">
          <div v-if="isHome">
            <div class="live-exam-header" @click="onCourseClicked(item)" :style="{ background: `${item.exam_type.color ? item.exam_type.color : '#0095FF' }`, cursor: 'pointer' }">
              <h1 class="live-exam-title ">{{ item.exam_type.title }} </h1>
              <h1 class="live-exam-arrow right " @click="onCourseClicked(item)" style="cursor: pointer"></h1>
            </div>
          </div>
          <div v-else>
            <div class="live-exam-header-2" @click="onCourseClicked(item)" style="cursor: pointer">
              <h1 class="live-exam-title-2">{{ item.exam_type.title }} </h1>
            </div>
          </div>
        </div>
        <div v-if="isHome" class="live-exam-body" @click="onExamClicked" style="cursor: pointer">
          <p class="body-title justify-text" :title="item.title">
            {{ item.title }}</p>
          <p class="body-content">
            প্রশ্ন {{ item.total }} টি - {{ item.time }} মিনিট
          </p>
        </div>
        <div v-else class="live-exam-body">
          <p class="body-title justify-text" :title="item.title">
            {{ item.title }}</p>
          <p class="body-content">
            প্রশ্ন {{ item.total }} টি - {{ item.time }} মিনিট <span
              :class=" 'text-' + getStatus(item)"> .{{ getStatus(item) }}</span>
          </p>
          <div v-if="isResult || item.result  " class="body-content-result">
            <table class="table table-sm myTable">
              <tbody>
              <tr>
                <td>মার্কসঃ {{item.result.mark}}/{{ item.total }}</td>
                <td>পজিশনঃ {{ item.result.position}}/{{ item.participants }} {{ item.result.late==1? "[Late]":""}}</td>
              </tr>
              <tr>
                <td>পাসঃ {{ item.passed }}/{{ item.participants }}</td>
                <td>কাটমার্কঃ {{ item.cut_mark }}</td>
                <td>সর্বোচ্চঃ {{ item.best }}</td>
              </tr>
              <tr v-if="isRunning">
                <td colspan="3">* এখন পর্যন্ত</td>
              </tr>
              </tbody>
            </table>
<!--            <span> মার্কস: {{item.result.mark}}/{{ item.total }}</span> <span> পজিশন: {{ item.result.position}} {{ item.result.late==1? "[Late]":""}}</span>-->
<!--            <span> পাস: {{ item.passed }}/{{ item.participants }}</span> <span>   কাটমার্ক: {{ item.cut_mark }} </span> <span> সর্বোচ্চ: {{ item.best }}</span>-->
          </div>
        </div>

        <div v-if="!hideBtn" class="live-exam-footer-2">
          <button v-if="!isResult" :disabled='status == "Upcoming"' class="btn exam-start-button"
                  @click="onStartExamClicked">পরীক্ষা দিন
          </button>
          <button v-if="isResult" class="btn exam-start-button" @click="onAnswerListClicked">উত্তর পত্র</button>
          <button class="btn exam-syllabus-btn" @click="onSyllabusClicked">সিলেবাস</button>
          <button class="btn exam-menu-btn" :disabled="isMenuDisabled" @click="onMenuClicked($event)">
            <svg class="exam-menu-btn-svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 fill="none">
              <path
                  d="M7.75 12C7.75 12.9665 6.9665 13.75 6 13.75C5.0335 13.75 4.25 12.9665 4.25 12C4.25 11.0335 5.0335 10.25 6 10.25C6.9665 10.25 7.75 11.0335 7.75 12ZM13.75 12C13.75 12.9665 12.9665 13.75 12 13.75C11.0335 13.75 10.25 12.9665 10.25 12C10.25 11.0335 11.0335 10.25 12 10.25C12.9665 10.25 13.75 11.0335 13.75 12ZM18 13.75C18.9665 13.75 19.75 12.9665 19.75 12C19.75 11.0335 18.9665 10.25 18 10.25C17.0335 10.25 16.25 11.0335 16.25 12C16.25 12.9665 17.0335 13.75 18 13.75Z"
                  fill="#2A2B2B"/>
            </svg>
          </button>
        </div>
        <div v-else class="live-exam-footer-1"  @click="onExamClicked" style="cursor: pointer" >
          <p class="footer-left-content" :class=" 'text-' + getStatus(item)">{{ getStatus(item) }}</p>
          <p class="footer-right-content">
            {{ item.participants }} already participated
          </p>
        </div>
      </div>


      <ResultSectionView v-if="isResultShowable && status != 'Running'" :exam="item">

      </ResultSectionView>


    </div>
  </div>
  <div v-else class="col-lg-4 col-md-4 col-sm-4 pl-2 mb-3 test-grid">
    <div v-if="item!=undefined">
      <div :class="{
        'live-exam-box': isHome,
        'live-exam-box-2 ': isSingleCourse,
        'live-exam-box-3': !isSingleCourse && !isHome
      }">
        <div v-if="item.exam_type">
          <div v-if="isHome">
            <div class="live-exam-header" @click="onCourseClicked(item)"  :style="{ background: `${item.exam_type.color ? item.exam_type.color : '#0095FF' }`, cursor: 'pointer' }">
              <h1 class="live-exam-title ">{{ item.exam_type.title }} </h1>
              <h1 class="live-exam-arrow right " @click="onCourseClicked(item)" style="cursor: pointer"></h1>
            </div>
          </div>
          <div v-else>
            <div class="live-exam-header-2 hover-underline" @click="onCourseClicked(item)" style="cursor: pointer">
              <h1 class="live-exam-title-2">{{ item.exam_type.title }} </h1>
            </div>
          </div>
        </div>
        <div v-if="isHome" class="live-exam-body" @click="onExamClicked" style="cursor: pointer">
          <p class="body-title justify-text" :title="item.title">
            {{ item.title }}</p>
          <p class="body-content">
            প্রশ্ন {{ item.total }} টি - {{ item.time }} মিনিট
          </p>
        </div>
        <div v-else class="live-exam-body">
          <p class="body-title justify-text" :title="item.title">
            {{ item.title }}</p>
          <p class="body-content">
            প্রশ্ন {{ item.total }} টি - {{ item.time }} মিনিট <span
              :class=" 'text-' + getStatus(item)"> .{{ getStatus(item) }}</span>
          </p>
          <div v-if="isResult || item.result " class="body-content-result">
            <div class="result-content">
              <table class="table table-sm myTable">
                <tbody>
                <tr>
                  <td>মার্কসঃ {{item.result.mark}}/{{ item.total }}</td>
                  <td colspan="2">পজিশনঃ {{ item.result.position}}/{{ item.participants }} {{ item.result.late==1? "[Late]":""}}</td>
                </tr>
                <tr>
                  <td>পাসঃ {{ item.passed }}/{{ item.participants }}</td>
                  <td>কাটমার্কঃ {{ item.cut_mark }}</td>
                  <td>সর্বোচ্চঃ {{ item.best }}</td>
                </tr>
                <tr v-if="isRunning">
                  <td colspan="3">* এখন পর্যন্ত</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

        <div v-if="!hideBtn" class="live-exam-footer-2">
          <button v-if="!isResult" :disabled='status == "Upcoming"' class="btn exam-start-button"
                  @click="onStartExamClicked">পরীক্ষা দিন
          </button>
          <button v-if="isResult" class="btn exam-start-button" @click="onAnswerListClicked">উত্তর পত্র</button>
          <button class="btn exam-syllabus-btn" @click="onSyllabusClicked">সিলেবাস</button>
          <button class="btn exam-menu-btn" :disabled="isMenuDisabled" @click="onMenuClicked($event)">
            <svg class="exam-menu-btn-svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 fill="none">
              <path
                  d="M7.75 12C7.75 12.9665 6.9665 13.75 6 13.75C5.0335 13.75 4.25 12.9665 4.25 12C4.25 11.0335 5.0335 10.25 6 10.25C6.9665 10.25 7.75 11.0335 7.75 12ZM13.75 12C13.75 12.9665 12.9665 13.75 12 13.75C11.0335 13.75 10.25 12.9665 10.25 12C10.25 11.0335 11.0335 10.25 12 10.25C12.9665 10.25 13.75 11.0335 13.75 12ZM18 13.75C18.9665 13.75 19.75 12.9665 19.75 12C19.75 11.0335 18.9665 10.25 18 10.25C17.0335 10.25 16.25 11.0335 16.25 12C16.25 12.9665 17.0335 13.75 18 13.75Z"
                  fill="#2A2B2B"/>
            </svg>
          </button>
        </div>
        <div v-else class="live-exam-footer-1"  @click="onExamClicked" style="cursor: pointer" >
          <p class="footer-left-content" :class=" 'text-' + getStatus(item)">{{ getStatus(item) }}</p>
          <p class="footer-right-content">
            {{ item.participants }} already participated
          </p>
        </div>
      </div>


      <ResultSectionView v-if="isResultShowable && status != 'Running'" :exam="item">

      </ResultSectionView>


    </div>
  </div>
</template>

<script>
// import Swal from 'sweetalert2/dist/sweetalert2';
import {StaticTags, URLs} from "@/static";
import {useRouter} from "vue-router";
//import store from '@/store/index.js';
import {setExamInfo, setExamParams} from "@/config/utils";
import ResultSectionView from "./ExamDetailsView/ResultSectionView.vue";
import {onMounted, ref} from "vue";
import store from "@/store";
import {englishToBengaliNumber} from "@/config/utils";

export default {
  components: {
    ResultSectionView,
  },
  props: [
    'item', 'status', 'isResult', 'isLive', 'hideBtn', 'isMenuDisabled', 'isSingleCourse',
    'showMenuModal', 'showSyllabusModal', 'showStartExamDialog', 'syllabusModal', 'startExamDialog', 'isHome'
  ],
  setup(props, {emit}) {
    const isRunning = ref(false)
    const router = useRouter();
    const isResultShowable = ref(false)
    const coordinates = ref({x: 0, y: 0})
    onMounted(() => {
      if (props.item.result != undefined || props.item.result != null) {
        isResultShowable.value = false
      }
    })

    const onMenuClicked = (event) => {
      const rect = event.target.getBoundingClientRect();
      let x = rect.left + window.pageXOffset;
      let y = rect.top + window.pageYOffset;
      const modalWidth = 200;
      const modalHeight = 150;
      if (x + modalWidth > document.documentElement.clientWidth) {
        x = x - modalWidth;
      }

      if (y + modalHeight > document.documentElement.clientHeight) {
        y = y - modalHeight;
      }
      coordinates.value = {
        x: x,
        y: y
      };
      emit("showMenuModal", props.item, coordinates.value)
    }
    const getStatus = (exam) => {
      let isEnded = exam.ended
      let isParticipated = (exam.result != null || exam.result != undefined)
      let isPass = false
      if (isParticipated) {
        isPass = exam.result.mark >= exam.cut_mark
      }
      if (isEnded == StaticTags.ID_EXAM_RUNNING) {
        isRunning.value = true
        if (isParticipated) {
          return "Participated";
        } else {
          return "Running";
        }
      } else if (isEnded == StaticTags.ID_EXAM_FINISHED) {
        isRunning.value = false
        return "Finished";
      } else if (isEnded == StaticTags.ID_EXAM_UPCOMING) {
        return "Upcoming";
      } else {
        if (isParticipated) {
          isRunning.value = false
          if (isPass) {
            return "Passed";
          } else {
            return "Failed";
          }
        } else {
          return "Archived";
        }
      }
    }

    const onAnswerListClicked = () => {
      //store.dispatch("setExamFlowType", StaticTags.TYPE_COURSE_EXAM_RESULT)
      setExamParams({
        examId: props.item.id
      })
      setExamInfo({
        id: props.item.id,
        title: props.item.title,
        examFlowType: StaticTags.TYPE_COURSE_EXAM_RESULT,
        isRunningExam: isRunning.value
      })
      router.push({name: URLs.ROUTE_EXAM_NAME})
    }

    const onSyllabusClicked = () => {
      emit("showSyllabusModal", props.item)
    }

    const onStartExamClicked = () => {
      emit("showStartExamDialog", props.item)
    }

    const onExamClicked = () => {
      emit("startExamDialog", props.item)
    }

    const onCourseClicked = (item) => {
      store.dispatch("setTitleBar", item.exam_type.title)
      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_LIVE_COURSE)
      router.push({
        name: URLs.ROUTE_COURSE_EXAM_LIST_NAME,
        params: {
          exam_type: item.exam_type.id,
        },
      })
    }

    return {
      onMenuClicked,
      onSyllabusClicked,
      onAnswerListClicked,
      onStartExamClicked,
      onExamClicked,
      isResultShowable,
      onCourseClicked,
      getStatus,
      englishToBengaliNumber,
      isRunning,
    }
  }
}
</script>

<style>

.myTable {
  border: none !important;
}
.myTable th, .myTable td {
  border: none !important;
}
.table {
  margin-bottom: 0px !important;
}

.body-content-result {
  position: relative;
  font-family: 'Noto Sans';
  font-style: normal;
  font-size: 15px;
  line-height: 30px;
  color: #999999;
  border-radius: 12px;
  background: #FFFBFA !important;
}

.result-content {
  padding: 12px;
}

.hover-underline {
  position: relative;
}

.hover-underline::after {
  content: '';
  position: absolute;
  width: 100%;  /* Hidden initially */
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: rgba(211, 216, 220, 0.80);
  transition: width 0.3s ease; /* Smooth transition */
}
.exam-start-button {
  width: 145px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px !important;
  background: #0095FF;
  color: white;
}

.exam-syllabus-btn {
  width: 110px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px !important;
  background: #F0F5F9;
}

.exam-menu-btn {
  width: 48px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px !important;
  background: #F0F5F9;
}

.live-exam-box {
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  background: #FFFFFF;
  text-align: start;
  padding-bottom: 5px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.live-exam-box-2 {
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  background: #FFFFFF;
  text-align: start;
  padding-bottom: 5px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.live-exam-box-3 {
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  background: #FFFFFF;
  text-align: start;
  padding-bottom: 5px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.live-exam-title {
  position: absolute;
  color: #FFFFFF;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5em;
  text-align: justify;
}

.live-exam-title-2 {
  position: absolute;
  color: #999;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5em;
  text-align: justify;
}

.live-exam-result {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #999999;
}
.live-exam-header {
  position: relative;
  width: 100%;
  height: 64px;
  border-radius: 11px 11px 0px 0px;
  padding: 15px;
}

.live-exam-header-2 {
  position: relative;
  width: 100%;
  height: 64px;
  border-radius: 11px 11px 0px 0px;
  padding: 15px;
}

.live-exam-arrow {
  border: solid rgb(231, 226, 226);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  position: absolute;
  right: 20px;
  top: 26px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

}

.live-exam-body {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px 15px 8px 15px;
}

.live-exam-body-2 {
  position: relative;
  display: grid;
  grid-template-columns: 1.5fr;
  grid-template-rows: 42px 30px;
  margin-bottom: 10px;
}

.live-exam-footer-1 {
  position: relative;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr;
  grid-gap: 20px;
  padding: 15px;
}

.live-exam-footer-2 {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 15px;
}

.body-title {
  position: relative;
  font-family: 'Noto Sans Bengali';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #071833;
}

.body-content {
  position: relative;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
}

.footer-left-content {
  position: relative;
  font-family: 'Noto Sans Bengali';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #FA5534;
  text-align: left;

}

.footer-right-content {
  position: relative;
  font-family: 'Noto Sans Bengali';
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  color: #999999;
  text-align: right;
}


.btn-type {
  margin-right: 10px;
}

.text-Running {
  color: #00A8A8;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.date-title {
  background-color: rgb(45, 76, 248);
}

.text-Upcoming {
  color: #0095FF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.text-Participated {
  color: orange;
}
.text-Finished {
  color: orange;
}

text-Passed {
  color: green;
}

.text-Failed {
  color: #FA3434;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.text-Archived {
  color: orange;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

}

.card {
  width: 100%;
  /* max-width: 500px; */
}

@media (min-width: 768px) {
  .live-exam-title {
    position: absolute;
    color: #FFFFFF;
    font-family: Noto Sans Bengali;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 2.5em;
    text-align: justify;
  }
  .live-exam-title-2 {
    position: absolute;
    color: #999;
    font-family: Noto Sans Bengali;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 2.5em;
    text-align: justify;
  }
}

@media (max-width: 768px) {
  .exam-start-button {
    width: fit-content;
  }
  .exam-syllabus-btn {
    width: fit-content;
  }
}
</style>
  