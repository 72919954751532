<template>
  <div>
    <!--    <div class=" pl-0 pr-0">-->
    <!--      <Header />-->
    <!--    </div>-->
    <div class=" pl-0 pr-0" style="margin: 30px">
      <!--      <a class="login-top-header " href="/" style="width: 88px; height: 51px;">-->
      <!--        <img :src="require('@/assets/logo.png')" alt="" width="50" />-->
      <!--      </a>-->
    </div>
    <div class="container-fluid">
      <div class="container">
        <div class="login-page-layout">
          <div class="login-page-layout-insider">
            <div class="login-page-logo">
              <a class="login-top-header " href="/" style="width: 88px; height: 51px;">
                <img :src="require('@/assets/logo.png')" alt="" width="88" />
              </a>
            </div>
            <div class="login-page-header">
              <h2 class="login-page-header-title">
                লগইন{{ "\xa0" }}/রেজিস্ট্রেশন
              </h2>
            </div>
            <div class="login-page-content">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 ">

                  <form @submit.prevent="onSubmitClicked" style="text-align: left">
                    <div v-if="isNewUser && otpVerified" class="form-group">
                      <label class="label-text" for="name">নাম</label>
                      <input type="text" class="form-control custom-form" required placeholder="নাম" v-model="userName" />
                    </div>
                    <div v-if="!otpSent && !isRegistered" class="form-group">
                      <label class="label-text" for="mobile">মোবাইল</label>
                      <input type="text" class="form-control custom-form" required placeholder="01XXXXXXXX" v-model="userId" />
                    </div>
                    <div v-if="(isRegistered && !isNewPassword) || (isNewUser && otpVerified)" class="form-group">
                      <label class="label-text" for="password">পাসওয়ার্ড </label>
                      <input type="password" class="form-control custom-form" required name="password" id="password" v-model="password"
                             aria-describedby="emailHelp" placeholder="আপনার পাসওয়ার্ড লিখুন" />
                    </div>

                    <div v-if="(isRegistered && (isNewPassword && otpVerified))" class="form-group">
                      <label class="label-text" for="newPassword">নতুন পাসওয়ার্ড </label>
                      <input type="password" class="form-control custom-form" required name="newPassword" id="newPassword"
                             v-model="newPassword" aria-describedby="emailHelp" placeholder="নতুন পাসওয়ার্ড লিখুন" />
                    </div>

                    <div v-if="(!isNewPassword && isRegistered) || (isNewUser && otpVerified)">
                      <p class="forgot-password" @click="onForgotPasswordClicked">Forgot Password</p>
                    </div>

                    <!-- <div v-if="otpSent && !otpVerified" style="display: flex; margin-top:10px; flex-direction: row;">
                      <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="4"
                        :should-auto-focus="true" :is-input-num="true" :conditionalClass="['one', 'two', 'three', 'four']"
                        :placeholder="['*', '*', '*', '*']" @on-change="handleOnChange" @on-complete="handleOnComplete" />
                    </div> -->

                    <div v-if="otpSent && !otpVerified" class="form-group">
                      <label class="label-text" for="otpInput">ওটিপি</label>
                      <input type="text" class="form-control custom-form" required name="otp" id="otpInput" v-model="otpInput"
                             placeholder="* * * *" />
                    </div>

                    <div v-if="otpSent && !otpVerified" style="display: flex; flex-direction: row;">
                      <div>
                        <p style="font-size: 10px;">Didn't receive your OTP? {{ (counter > 0) ? counter : "" }}</p>
                      </div>
                      <div class="otp-resend" v-if="otpSent && counter == 0" @click="sendOtp">Resend</div>
                    </div>
                    <div class="text-center mt-3">
                      <button class="btn btn-primary base-color-button custom-submit">সাবমিট</button>
                    </div>
                  </form>

                </div>
                <!-- <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                  <div class="login-choice">
                    <span>One Click - Sign In/Up</span>
                  </div>
                  <SocialLogin />
                </div> -->
              </div>
              <div class="page-divider"></div>
            </div>
            <div class="login-page-footer">
              <p class="footer-text">


                সাবমিটের মাধ্যমে, আপনি আমাদের<a class="terms-conditions" target="_blank" href=" https://bcsprostuti.com/terms-condition.html">
                টার্মস এন্ড কন্ডিশন
              </a> এবং<a class="privacy-policy" target="_blank" href=" https://bcsprostuti.com/privacy-policy.html">
                প্রাইভেসি পলিসিতে
              </a> সম্মত হন।
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import httpClient from "@/config/httpClient";
// import Footer from "../components/FooterView.vue";
import store from '@/store/index.js';
import { useRouter } from "vue-router";
import { defineComponent, ref } from "vue";
import { StaticTags } from "@/static";
// import Header from "@/components/NewHeaderView.vue";
// import Header from "@/components/HeaderView.vue";
import { processBDNumbers } from "@/config/utils";
import Swal from 'sweetalert2/dist/sweetalert2';
import { removeItem } from "@/config/utils";
import axios from "axios";

export default defineComponent({

  components: {
    // Header,
    // Footer,
  },
  setup() {
    const userId = ref(null)
    const password = ref(null)
    const userName = ref()

    const router = useRouter()

    const isRegistered = ref(false)
    const isNewUser = ref(false)
    const otpInput = ref(null)
    const otpSent = ref(false)
    const otpVerified = ref(false)
    const isNewPassword = ref(false)
    const newPassword = ref(null)
    const tempToken = ref(null)
    const counter = ref(60)
    let timerId;
    const startTimer = () => {
      timerId = setInterval(() => {
        if (counter.value === 0) {

          clearInterval(timerId);

          return;
        } else {
          counter.value--;
        }
      }, 1000);
    }

    const onSubmitClicked = () => {
      var loginParams = null
      if (isNewPassword.value && !otpSent.value) {
        // if forgot password and otp not send yet. then call service for otp
        if (validateUserId()) {
          requestForResetPassword()
        }
      }
      else if (isNewPassword.value && !otpVerified.value) {
        verifyOTP()
      }
      else if (isNewPassword.value && otpVerified.value) {
        if (validateNewPassword()) {
          changePassword()
        }
      }

      else if (!otpSent.value && !isRegistered.value) {
        if (validateUserId()) {
          loginParams = {
            userid: userId.value,
            website: true,
          }
          checkUserRegisterationStatus(loginParams);
        }
      }
      else if (isRegistered.value) {
        if (validatePassword()) {
          loginParams = {
            userid: userId.value,
            password: password.value,
            website: true,
          }
          getToken(loginParams)
        }
      }
      else if (otpSent.value && !otpVerified.value) {
        verifyOTP()
      }
      else if (otpSent.value && otpVerified) {
        if (validateUserId() && validatePassword() && validateName()) {
          registerUser();
        }
      }
    }

    const onForgotPasswordClicked = () => {

      requestForResetPassword()
    }

    const changePassword = async () => {
      var data = JSON.stringify({
        new: newPassword.value,
        website: true,

      });
      var config = {
        method: "put",
        url: process.env.VUE_APP_API_BASE_URL + "/reset-password",
        headers: {
          "Content-Type": "application/json",
          "api-token": tempToken.value
        },
        data: data,
      };

      await axios(config)
          .then((response) => {
            if (response.data.success) {
              // reset all flags and value
              userId.value = ''
              password.value = ''
              newPassword.value = ''
              isRegistered.value = false
              isNewUser.value = false
              otpInput.value = ''
              otpSent.value = false
              otpVerified.value = false
              isNewPassword.value = false
              newPassword.value = ''
              tempToken.value = ''

              Swal.fire({
                text: response.data.message,
                title: "Success",
                icon: StaticTags.ALERT_TYPE_SUCCESS
              })

            } else {

              Swal.fire({
                text: response.data.message,
                title: "Failed",
                icon: StaticTags.ALERT_TYPE_ERROR
              })
            }
          })
          .catch((error) => {
            Swal.fire({
              text: error.response.message,
              title: "Failed",
              icon: StaticTags.ALERT_TYPE_ERROR
            })
          })
    }

    const requestForResetPassword = async () => {
      const paramUserId = {
        userid: userId.value,
        website: true,

      }
      await httpClient
          //.post(baseUrl + "api2/login/", loginParams)
          .post(process.env.VUE_APP_API_BASE_URL + "/reset-password-request", paramUserId)
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                tempToken.value = response.token
                isNewPassword.value = true
                isRegistered.value = true
                sendOtp()
              }
              else {
                Swal.fire({
                  text: response.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }
            }

          })
    }

    const sendOtp = async () => {
      counter.value = 60
      if (isNewUser.value) {
        var loginParams = {
          userid: userId.value,
          website: true,
        }
        checkUserRegisterationStatus(loginParams)
      }
      else if (isNewPassword.value) {
        var data = JSON.stringify({
          mobile: processBDNumbers(userId.value),
          website: true,

        });

        var config = {
          method: "post",
          url: process.env.VUE_APP_API_BASE_URL + "/otp-sent",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        await httpClient(config)
            .then((response) => {
              if (response.success) {
                otpSent.value = true
                startTimer()
              } else {
                Swal.fire({
                  text: response.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }
            })
      }
    }

    const checkUserRegisterationStatus = async (loginParams) => {
      await httpClient
          .post(process.env.VUE_APP_API_BASE_URL + "/request-register", loginParams)
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                isRegistered.value = response.registered
                if (!isRegistered.value) {
                  otpSent.value = true
                  isNewUser.value = true
                  startTimer()
                }
              } else {
                // write code for otp
                Swal.fire({
                  text: response.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }
            }

          })
    }

    const getToken = async (loginParams) => {
      await httpClient
          //.post(baseUrl + "api2/login/", loginParams)
          .post(process.env.VUE_APP_API_BASE_URL + "/login", loginParams)
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                store.commit("setAcessToken", response.api_token);
                if (store.state.packId != undefined && store.state.packId != "null" && store.state.packId != null) {
                  buyPackage(store.state.packId)
                } else {
                  router.push("/home");
                }
              } else {
                Swal.fire({
                  text: response.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }
            }

          })
    }

    const verifyOTP = async () => {

      var data = JSON.stringify({
        otp: otpInput.value,
        mobile: userId.value,
        website: true,

      });
      var config = {
        method: "post",
        url: process.env.VUE_APP_API_BASE_URL + "/otp-verify",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      await httpClient(config)
          .then((response) => {
            if (response.success) {
              otpVerified.value = true
            } else {

              Swal.fire({
                text: response.message,
                title: "Failed",
                icon: StaticTags.ALERT_TYPE_ERROR
              })
            }
          })
          .catch((error) => {
            Swal.fire({
              text: error.response.message,
              title: "Failed",
              icon: StaticTags.ALERT_TYPE_ERROR
            })
          })

    }


    const registerUser = async () => {

      var data = JSON.stringify({
        name: userName.value,
        userid: processBDNumbers(userId.value),
        mobile: processBDNumbers(userId.value),
        password: password.value,
        website: true,
      });

      var config = {
        method: "post",
        url: process.env.VUE_APP_API_BASE_URL + "/register",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      await httpClient(config)
          .then((response) => {
            if (response.success) {
              store.commit("setAcessToken", response.api_token);
              if (store.state.packId != undefined && store.state.packId != "null" && store.state.packId != null) {
                buyPackage(store.state.packId)
              } else {
                router.push("/home");
              }
              // router.push("/home");
            } else {
            Swal.fire({
              text: response.message,
              title: "Failed",
              icon: StaticTags.ALERT_TYPE_ERROR
            })
          }
        })

    }

    const buyPackage = async (id) => {
      var data = JSON.stringify({
        website: true,
      });

      var config = {
        method: "post",
        url: process.env.VUE_APP_API_BASE_URL + "/memberships/" + id,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await httpClient(config)
          .then((response) => {
            if (response.success) {
              store.commit("setPackId", null);
              removeItem("packId")
              window.location.href = response.url;

            } else {
              Swal.fire({
                text: response.message,
                title: "Failed",
                icon: StaticTags.ALERT_TYPE_ERROR,
                showConfirmButton: true,
                confirmButtonText: "Okay"
              }).then((result) => {
                if (result.isConfirmed) {
                  // do nothing
                }
              })
            }
          })

    }


    const validateName = () => {
      if (userName.value != null && userName.value.length > 0) {
        return true
      } else {
        Swal.fire({
          text: "Use a valid name",
          title: "Invalid",
          icon: StaticTags.ALERT_TYPE_ERROR
        })
        return false
      }
    }

    const validatePassword = () => {
      if (isRegistered.value && password.value != null) {
        return true
      }
      if (password.value != null && password.value.length > 5) {
        return true
      } else {
        Swal.fire({
          text: "Use atleast 6 charecters",
          title: "Invalid",
          icon: StaticTags.ALERT_TYPE_ERROR
        })
        return false
      }
    }
    const validateNewPassword = () => {
      if (isRegistered.value && newPassword.value != null) {
        return true
      }
      if (newPassword.value != null && newPassword.value.length > 5) {
        return true
      } else {
        Swal.fire({
          text: "Use atleast 6 charecters",
          title: "Invalid",
          icon: StaticTags.ALERT_TYPE_ERROR
        })
        return false
      }
    }

    const validateUserId = () => {

      const mobileNumberPattern = /^(?:(?:\+|00)88|01)?\d{11}$/;
      if (mobileNumberPattern.test(userId.value)) {
        var mobile = userId.value
        if (mobile.slice(0, 1) == '+') {
          mobile = mobile.substring(3)
          userId.value = mobile
        }
        return true
      } else {

        Swal.fire({
          text: "Provide valid information",
          title: "Invalid",
          icon: StaticTags.ALERT_TYPE_ERROR
        })
        return false
      }

    }

    return {
      userId,
      password,
      userName,
      onSubmitClicked,
      sendOtp,
      isRegistered,
      isNewUser,
      otpInput,
      verifyOTP,
      registerUser,
      otpVerified,
      counter,
      otpSent,
      onForgotPasswordClicked,
      isNewPassword,
      newPassword,
    }
  }


});
</script>
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background: #0069ff;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab a {
  background-color: inherit;
  float: right;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  text-decoration: none;
  border: 1px solid;
}

/* Change background color of buttons on hover */
.tab a:hover {
  background-color: #ddd;
}

.login-top-header {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.login-top-header img {
  width: 102px !important;
}

.login-page-layout {
  height: 729px;
  flex-shrink: 0;
  border-radius: 32px;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-layout-insider {
  width: 80%;
  height: 729px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}


.login-page-header {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //gap: 24px;
}

.login-page-header-title {
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 100% */
}

.login-page-content {
  height: 344px;
  flex-shrink: 0;
  display: grid;
  grid-template-rows: 1fr auto;
}

.login-page-footer {

}

.page-divider {
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #D3D8DC;
}

.custom-form {
  height: 55px;
  border-radius: 16px;
  border: 2px solid #EDEDED;
  background: #FFF;
  flex-shrink: 0;
}

.label-text {
  font-size: 22px;
}

.custom-submit {
  height: 55px;
  flex-shrink: 0;
  border-radius: 16px;
}

/* Create an active/current tablink class */
.tab a.active {
  background-color: #ccc;
}

.forgot-password {
  text-align: right;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.otp-resend {
  font-size: 12px;
  margin-left: 7px;
  text-decoration: underline;
  cursor: pointer;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.loginsuccess-container {
  padding: 20px;
  margin: 0 auto;
  width: 80%;
  box-shadow: beige;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  word-break: break-all;
}

.main-container {
  margin-top: 10%;
}

.box-container {
  padding: 20px;
  margin: 0 auto;
  width: 400px;
  box-shadow: beige;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}

.heading {
  text-align: center;
  font-weight: 300;
  color: #444;
  margin: 0 auto 45px;
  font-size: 35px;
  line-height: 38px;
  text-transform: none;
  letter-spacing: 0;
}

.form-fields,
.form-fields button {
  width: 100%;
  margin: 5px 0;
  line-height: 28px;
  border-radius: 5px;
}

.form-fields input {
  width: 100%;
  line-height: 40px;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  background: #fff;
  padding: 0 5px;
  font-size: 14px;
}

.signIn {
  padding: 10px 32px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  background: #15cd72;
  text-align: center;
  cursor: pointer;
  height: auto;
  -webkit-appearance: none;
}

.createaccount {
  padding: 15px;
  background-color: #0069ff;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  text-align: center;
  border-radius: 5px;
}

.center {
  text-align: center;
}

.login-choice span {
  color: #5b6987;
  display: -ms-grid;
  display: grid;
  font-size: 16px;
  width: 100%;
  line-height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-grid-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-gap: 19px;
}

.login-choice span:after,
.login-choice span:before {
  content: "";
  border-top: 1px solid #e5e8ed;
}

.signup-buttons {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.facebook-signup,
.google-signup {
  color: #031b4e;
  background: #f2f8ff;
  border: 1px solid rgba(0, 105, 255, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  margin-top: 0;
  width: 47.5%;
  padding: 15px;
  text-align: center;
  position: inherit;
}

.signup-buttons a {
  vertical-align: middle;
  text-decoration: none;
}

.signup-buttons svg {
  left: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footer,
.footer a {
  text-align: center;
  color: #fff;
}
</style>