<template>
  <nav v-if="isNotification" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
    <div class="breadcrumb-two">
      <div v-if="userDetails" class="custom-content">
        <h2 class="breadcrumb-two-item">{{userDetails.warning_message}}</h2>
        <a href="/packages">আপগ্রেড করুন</a>
      </div>
      <div v-else>
        <h2 class="breadcrumb-item">{{notification}}</h2>
      </div>
    </div>
  </nav>
  <nav v-if="title" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#" @click="goToHome" style="cursor: pointer">হোম</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{title}}</li>
    </ol>
  </nav>
</template>
<script>
import router from "@/router";

export default {
  props: ['title', 'userDetails', 'isNotification', 'notification'],
  setup() {
    const goToHome = () => {
      router.push('/home')
    }
    return {
      goToHome
    }
  }
}

</script>
<style>
.breadcrumb {
  background: rgba(255, 223, 193, 0.60) !important;
  --bs-breadcrumb-divider: '>' !important;
}
.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider) !important;
}

.breadcrumb-two {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 223, 193, 0.60) !important;
  flex-wrap: wrap;
  padding: 1.25rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
}

.custom-content {
  display: flex;
  gap: 10px;
}

.breadcrumb-two-item {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
}

</style>