/**
 * this class all static ids or types, changing here will have effect all over the project
 */


export const StaticTags = {
    IS_LOGGED_IN: "is_logged_in",
    IS_EXAM_RUNNING: 'is_exam_running',
    EXAM_INFORMATION: 'exam_information',
    QUESION_INFORMATION: 'question_information',

    CATEGORY_LIST_WITH_DETAILS: 'category_list_with_details',
    CATEGORY_LIST_SAVED_TIME: 'category_list_saved_time',

    USER_DETAILS: "user_details",

    //topic for cat, sub cat, chap
    TOPIC_FOR_CATEGORY: 1,
    TOPIC_FOR_SUBCATEGORY: 2,
    TOPIC_FOR_CHAPTERS: 3,
    TOPIC_FOR_OVER_ALL: 4,

    // exam on type
    EXAM_TYPE_CATEGORY: 1,
    EXAM_TYPE_SUB_CATEGORY: 2,
    EXAM_TYPE_CHAPTERS: 3,

    EXAM_TYPE_LIVE: 4,
    EXAM_TYPE_EXERCISE: 5,
    EXAM_TYPE_FAVOURITE: 6,
    EXAM_TYPE_OVER_ALL: 7,

    QUESTION_LIMIT_OF_PAGE: 50,

    ONETIME_REFRESH_FLAG: false,


    // flow type list
    TYPE_EXAM: 1,
    TYPE_EXERCISE: 2,
    TYPE_FAVOURITE: 3,
    TYPE_QUIZ: 4,
    TYPE_RESULT: 5,
    TYPE_COURSE_EXAM: 6,
    TYPE_LIVE_EXAM: 7,
    TYPE_COURSE_EXAM_RESULT: 8,
    TYPE_COURSE_EXAM_EXERCISE: 9,
    TYPE_SOLUTION: 10,
    TYPE_EXAM_RESULT: 11,
    TYPE_OVER_ALL_EXAM: 12,

    // flow type course visit list
    TYPE_NORMAL_COURSE: 6,
    TYPE_LIVE_COURSE: 7,

    // max number of question of different level
    MAX_QUESTION_CATEGORY: 100,
    MAX_QUESTION_SUBCATEGORY: 50,
    MAX_QUESTION_CHAPTERS: 30,
    MAX_QUESTION_PAGE: 20,

    // need this ids for result type list

    TYPE_LIVE_EXAM_RESULT: 1,
    TYPE_OTHER_EXAM_RESULT: 2,


    // store id for dispatching and getters
    DISPATCH_ID_TOKEN: "setAcessToken",
    DISPATCH_ID_FLOW_TYPE: "setFlowType",
    DISPATCH_ID_EXAM_PARAMS: 'ExamParams',
    DISPATCH_ID_EXAM_FLOW_TYPE: 'setExamFlowType',
    DISPATCH_ID_EXAM_INFO: 'ExamRunningState/setExamInformation',
    DISPATCH_ID_PAGE_ITEMS: 'setPageItemList',

    GET_EXAM_PARAMS: "ExamParams/getExamParams",
    GET_ACCESS_TOKEN: "getToken",
    GET_CATEGORY_LIST: "categoryList",
    GET_PAGE_ITEM_LIST: "pageItemList",
    GET_EXAM_INFO: "ExamRunningState/getExamInformation",


    // id for no id :(
    NO_ID: 0,


    // sweat alert dialog type list
    ALERT_TYPE_SUCCESS: "success",
    ALERT_TYPE_ERROR: "error",
    ALERT_TYPE_WARNING: "warning",
    ALERT_TYPE_INFO: "info",
    ALERT_TYPE_QUESTION: "question",


    // qustion menu options and actions
    QUESTION_REVIEW_TITLE: "Mark for Review",
    QUESTION_REMOVE_TITLE: "Remove",
    QUESTION_REPORT_TITLE: "Report Error",
    QUESTION_SEARCH_TITLE: "Search in Google",

    ACTION_REVIEW: 1,
    ACTION_REMOVE: 2,
    ACTION_REPORT: 3,
    ACTION_SEARCH: 4,


    ID_EXAM_RUNNING: 1,
    ID_EXAM_FINISHED: 2,
    ID_EXAM_UPCOMING: 0,

}

export const URLs = {
    // route url list name tag
    ROUTE_HOME_NAME: "Home",
    ROUTE_HOME_URL: "/home",

    ROUTE_WELCOME_NAME: "Welcome",
    ROUTE_WELCOME_URL: "/",

    ROUTE_LOGIN_NAME: "Login",
    ROUTE_LOGIN_URL: "/login",

    ROUTE_SIGNUP_NAME: "SignUp",
    ROUTE_SIGNUP_URL: "/signup",

    ROUTE_PACKAGE_DETAILS_NAME: "PackageDetails",
    ROUTE_PACKAGE_DETAILS_URL: "/packages/:id",

    ROUTE_PACKAGES_NAME: "Packages",
    ROUTE_PACKAGES_URL: "/packages",

    ROUTE_QUESTION_BANK_NAME: "QuestionBank",
    ROUTE_QUESTION_BANK_URL: "/questionbanks",

    ROUTE_QUESTION_BANK_DETAILS_NAME: "QuestionBankDetails",
    ROUTE_QUESTION_BANK_DETAILS_URL: "/questionbanks/:id/details",

    ROUTE_CATEGORIES_NAME: "Categories",
    ROUTE_CATEGORIES_URL: "/categories",

    ROUTE_LECTURE_NOTES_NAME: "LectureNotes",
    ROUTE_LECTURE_NOTES_URL: "/lesson-types",

    ROUTE_LECTURE_NOTE_NAME: "LectureNoteDetails",
    ROUTE_LECTURE_NOTE_URL: "/lesson-types/:id",

    ROUTE_SUB_CATEGORIES_NAME: "SubCategories",
    ROUTE_SUB_CATEGORIES_URL: "/categories/:cid/subcategories",

    ROUTE_CHAPTER_PAGE_NAME: "Chapters",
    ROUTE_CHAPTER_PAGE_URL: "/categories/:cid/subcategories/:sid/chapters",

    ROUTE_PAGE_NAME: "Pages",
    ROUTE_PAGE_URL: "/categories/:cid/subcategories/:sid/chapters/:chid/pages/",


    // category flow for exercise
    ROUTE_EXERCISE_CATEGORIES_NAME: "ExerciseCategories",
    ROUTE_EXERCISE_CATEGORIES_URL: "/exercise/categories",

    ROUTE_EXERCISE_SUB_CATEGORIES_NAME: "ExerciseSubCategories",
    ROUTE_EXERCISE_SUB_CATEGORIES_URL: "/exercise/categories/:cid/subcategories",

    ROUTE_EXERCISE_CHAPTER_PAGE_NAME: "ExerciseChapters",
    ROUTE_EXERCISE_CHAPTER_PAGE_URL: "/exercise/categories/:cid/subcategories/:sid/chapters",

    ROUTE_EXERCISE_PAGE_NAME: "ExercisePages",
    ROUTE_EXERCISE_PAGE_URL: "/exercise/categories/:cid/subcategories/:sid/chapters/:chid/pages/",

    //Blogs
    ROUTE_BLOGS_NAME: "Blogs",
    ROUTE_BLOGS_URL: "/blogs",


    ROUTE_BLOG_DETAILS_NAME: "BlogDetails",
    ROUTE_BLOG_DETAILS_URL: "/blogs/:title",


    //category flow for exam
    ROUTE_EXAM_CATEGORIES_NAME: "Categories",
    ROUTE_EXAM_CATEGORIES_URL: "/exam/categories",

    ROUTE_EXAM_SUB_CATEGORIES_NAME: "SubCategories",
    ROUTE_EXAM_SUB_CATEGORIES_URL: "exam/categories/:cid/subcategories",

    ROUTE_EXAM_CHAPTER_PAGE_NAME: "Chapters",
    ROUTE_EXAM_CHAPTER_PAGE_URL: "exam/categories/:cid/subcategories/:sid/chapters",

    ROUTE_EXAM_PAGE_NAME: "Pages",
    ROUTE_EXAM_PAGE_URL: "/exam/categories/:cid/subcategories/:sid/chapters/:chid/pages/",

    //category flow for favourite
    ROUTE_FAVOURITE_CATEGORIES_NAME: "Categories",
    ROUTE_FAVOURITE_CATEGORIES_URL: "/favourite/categories",

    ROUTE_FAVOURITE_SUB_CATEGORIES_NAME: "SubCategories",
    ROUTE_FAVOURITE_SUB_CATEGORIES_URL: "/favourite/categories/:cid/subcategories",

    ROUTE_FAVOURITE_CHAPTER_PAGE_NAME: "Chapters",
    ROUTE_FAVOURITE_CHAPTER_PAGE_URL: "/favourite/categories/:cid/subcategories/:sid/chapters",

    ROUTE_FAVOURITE_PAGE_NAME: "Pages",
    ROUTE_FAVOURITE_PAGE_URL: "/favourite/categories/:cid/subcategories/:sid/chapters/:chid/pages/",

    // category flow for quiz
    ROUTE_QUIZ_CATEGORIES_NAME: "Categories",
    ROUTE_QUIZ_CATEGORIES_URL: "/quiz/categories",

    ROUTE_QUIZ_SUB_CATEGORIES_NAME: "SubCategories",
    ROUTE_QUIZ_SUB_CATEGORIES_URL: "/quiz/categories/:cid/subcategories",

    ROUTE_QUIZ_CHAPTER_PAGE_NAME: "Chapters",
    ROUTE_QUIZ_CHAPTER_PAGE_URL: "/quiz/categories/:cid/subcategories/:sid/chapters",

    ROUTE_QUIZ_PAGE_NAME: "Pages",
    ROUTE_QUIZ_PAGE_URL: "/quiz/categories/:cid/subcategories/:sid/chapters/:chid/pages/",


    ROUTE_EXAM_NAME: "Exam",
    ROUTE_EXAM_URL: "/exam/",

    ROUTE_LIVE_EXAM_NAME: "Live Exams",
    ROUTE_LIVE_EXAM_URL: "/live-exams/",

    ROUTE_ABOUT_US_NAME: "About Us",
    ROUTE_ABOUT_US_URL: "/about-us/",


    ROUTE_RESULT_NAME: "Results",
    ROUTE_RESULT_URL: "/results/",

    ROUTE_RESULT_QUESTION_NAME: "ResultsQuestions",
    ROUTE_RESULT_QUESTION_URL: "/results/questions",

    ROUTE_FAVOURITE_QUESTION_NAME: "FavouriteQuestions",
    ROUTE_FAVOURITE_QUESTION_URL: "/favourite/questions",

    ROUTE_COURSE_TYPES_ALL_NAME: "AllCourseTypes",
    ROUTE_COURSE_TYPES_ALL_URL: "/courses/:id/courselist",

    ROUTE_COURSE_EXAM_LIST_NAME: "CourseExamList",
    ROUTE_COURSE_EXAM_LIST_URL: "/courses/:exam_type/examlist",

    ROUTE_LIVE_COURSE_TYPES_ALL_NAME: "AllCourseTypesLive",
    ROUTE_LIVE_COURSE_TYPES_ALL_URL: "/live/courses/",

    ROUTE_LIVE_COURSE_EXAM_LIST_NAME: "CourseExamListLive",
    ROUTE_LIVE_COURSE_EXAM_LIST_URL: "/live/courses/examlist",

    ROUTE_COURSE_DETAILS_NAME: "CourseDetails",
    ROUTE_COURSE_DETAILS_URL: "/course-details/:title",

    ROUTE_COURSE_LIST_NAME: "CourseList",
    ROUTE_COURSE_LIST_URL: "/course-list",

    ROUTE_EXAM_RANK_LIST_NAME: "RankList",
    ROUTE_EXAM_RANK_LIST_URL: "/exam/:id/ranklist",

    ROUTE_PURCHASE_HISTORY_NAME: "PurchaseHistory",
    ROUTE_PURCHASE_HISTORY_URL: "/purchase-history",

    ROUTE_SEARCH_PAGE_NAME: "SearchResultPage",
    ROUTE_SEARCH_PAGE_URL: "/search/:query",

    ROUTE_PROFILE_PAGE_NAME: "ProfilePage",
    ROUTE_PROFILE_PAGE_URL: "/profile",

    ROUTE_FAQ_PAGE_NAME: "FaqPage",
    ROUTE_FAQ_PAGE_URL: "/faq",

    ROUTE_EXAM_APP_LINK_NAME: "AppLink",
    ROUTE_EXAM_APP_LINK_URL: "/app",

    ROUTE_OOPS_PAGE_NAME: "Oops",
    ROUTE_OOPS_PAGE_URL: "/oops/",

}